import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { FilterButton } from "../";

const AdvancedFilterButton = ({ options, filters, setFilters, testId }) => {
  const [moreOptions, setMoreOptions] = useState([]); // Keep track of which "more" options are selected
  const [selectorOptions, setSelectorOptions] = useState([]);

  useEffect(() => {
    let selected = [];
    Object.keys(filters).forEach((key) => {
      if (filters[key]?.length > 0) {
        let option = options.find(
          (obj) =>
            obj.value === key ||
            obj.objKey === key ||
            obj.options.find((option) => option.value === key),
        );

        let innerOption = option?.options.find(
          (option) => option.value === key,
        );

        if (option) {
          selected.push(innerOption || option);
        }
      }
    });

    setSelectorOptions(selected);
  }, [JSON.stringify(options), JSON.stringify(filters)]);

  // Renders whatever selector options were selected (the final iteration)
  const renderSelectorOptionsChildren = () =>
    selectorOptions.map((selectorOption, i) => (
      <FilterButton
        isMulti
        alwaysClear
        formatLabel={false}
        key={i}
        text={selectorOption.label}
        options={selectorOption.options}
        value={filters[selectorOption.value] || []}
        setValue={(e) => {
          if (e.length > 0) {
            setFilters({ ...filters, [selectorOption.value]: e });
          } else {
            setFilters({ ...filters, [selectorOption.value]: null });
            setSelectorOptions(
              selectorOptions.filter(
                (option) => option.value !== selectorOption.value,
              ),
            );
          }
        }}
        testId={selectorOption.testId}
        style={{ marginRight: 10 }}
      />
    ));

  // Renders the first iteration of "more" options
  const renderSelectorOptions = () =>
    options
      .filter((option) =>
        moreOptions.some((moreOption) => moreOption.label === option.label),
      )
      .map((option, i) => (
        <FilterButton
          isSelector
          key={i}
          options={option.options.filter(
            (_option) =>
              !filters[_option.value] &&
              !selectorOptions.some(
                (_selectorOption) => _selectorOption.value === _option.value,
              ),
          )}
          text={option.label}
          setValue={(e) => {
            if (e) {
              setSelectorOptions([...selectorOptions, e]);
            }

            setMoreOptions(
              moreOptions.filter(
                (moreOption) => moreOption.value !== option.value,
              ),
            );
          }}
          testId={option.testId}
          style={{ marginRight: 10 }}
        />
      ));

  return (
    <>
      {renderSelectorOptionsChildren()}
      {renderSelectorOptions()}
      <FilterButton
        isMore
        options={options.map((option) => option)}
        value={[...moreOptions, ...selectorOptions]}
        setValue={(e) => {
          if (e.options[0]?.options) {
            setMoreOptions([...moreOptions, e]);
          } else {
            setSelectorOptions([...selectorOptions, e]);
          }
        }}
        style={{ marginRight: 10 }}
        testId={testId}
      />
    </>
  );
};

// This is a helper method that can be used when filtering an array of objects
AdvancedFilterButton.filterHelper = (filter, options, obj) => {
  let found = true;

  // Traverses a dot notation string to get an object deeper down the tree
  const getObj = (key) => {
    let splitKey = key.split(".");
    let derivedObj = { ...obj };
    for (let i = 0; i < splitKey.length; i++) {
      if (derivedObj) {
        derivedObj = derivedObj[splitKey[i]];
      } else {
        return null;
      }
    }

    return derivedObj;
  };

  // Checks to see if the incoming object is in each option filter
  const checkOptions = (option) => {
    if (option.objKey && !!filter[option.value]) {
      let item = getObj(option.objKey);
      found = found && item && filter[option.value].includes(item);
    }
  };

  // Loop through each option
  options.forEach((option) => {
    // If there is is no objKey and it contains options we need to loop through that checking each option
    if (!option.objKey && option.options) {
      option.options.forEach((obj) => {
        checkOptions(obj);
      });
    } else {
      checkOptions(option);
    }
  });

  return found;
};

AdvancedFilterButton.propTypes = {
  /** 
     const options = [
        // This is a advanced filter that can be drilled down, in this example the more button would show "Label Here" as an
        // option and once selected it would show a button "Label Here" that can be clicked and will have the option
        // "Option 1 Label" once that is clicked it will show the final "Option 1 Label" with the filter options of
        // "array", "of", and "strings"
        {
        label: "Label Here",
        value: "label_here",
        options: [
            {
            label: "Option 1 Label",
            value: "option_1",
            objKey: "options.option_1", // This is a dot notation of where the filterHelper can look in the incoming object to get value for filter
            options: [
                "array",
                "of",
                "strings"
            ],
            },
            // ... add as many as you want  
        ],
        testId: "data-testid here"
        },
        // This is a single advance filter that will show "Label 2 Here" in the more dropdown and once clicked will create
        // a filter button called "Label 2 Here" with options "array", "of", and "strings"
        {
        label: "Label 2 Here",
        value: "label_2_here",
        objKey: "options.labels.labels_2",
        options: [
                "array",
                "of",
                "strings"
            ),
        ],
        },
    ];
  */
  options: PropTypes.array,
  /**  Simple object can be empty */
  filters: PropTypes.object,
  /** Function to set filters object */
  setFilters: PropTypes.func,
  /** data-testid attribute */
  testId: PropTypes.string,
};

export default AdvancedFilterButton;
