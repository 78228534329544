import type { CSSProperties } from "react";
import React, { useState } from "react";

import moment from "moment";
import DatePicker from "react-datepicker";
import styled, { css, type CSSObject } from "styled-components";
import "react-datepicker/dist/react-datepicker.css";

import { colorTheme } from "@utils";

import { DatePickerWrapper } from "./helpers";
import gridSpacing from "../../utils/gridSpacing";

const FormGroup = styled.div<{ $error?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${gridSpacing[4]}px;

  input {
    border-color: ${({ $error }) =>
      $error ? `${colorTheme("danger")} !important` : colorTheme("neutralL4")};
  }
`;

const DatePickerContainer = styled(DatePickerWrapper)<{
  $inputStyle?: CSSProperties;
}>`
  .react-datepicker {
    padding: 8px;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.2);
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__navigation {
    padding: 14px;
  }

  ${({ $inputStyle }) =>
    $inputStyle &&
    css`
      .date-picker-class {
        ${css($inputStyle as CSSObject)}
      }
    `}
`;

const HelperText = styled.div`
  width: 100%;
  margin-left: 5px;
  font-size: 14px;
`;

const Label = styled.label`
  margin-bottom: 0;
`;

const RequiredSpan = styled.span`
  color: ${colorTheme("danger")};
  font-size: 14px;
  margin-left: 1px;
`;

interface SingleDatePickerProps {
  value: string | null;
  onChange: (value: string | null) => void;
  onBlur?: (value: string | null) => void;
  label?: string;
  error?: boolean | string;
  helperText?: string;
  helperTextStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  required?: boolean;
  requiredStyle?: React.CSSProperties;
  dateTime?: boolean;
  fixedPosition?: boolean;
  testId?: string;
}

function SingleDatePicker({
  value,
  onChange,
  onBlur,
  label,
  error,
  helperText,
  helperTextStyle = {},
  style,
  labelStyle = {},
  inputStyle = {},
  required,
  requiredStyle = {},
  dateTime,
  fixedPosition = false,
  testId = "single-date-picker",
}: SingleDatePickerProps) {
  const [currentValue, setCurrentValue] = useState<Date | null>(null);

  let selectedValue = null;

  if (value && value !== "Invalid date") {
    if (dateTime) {
      selectedValue = moment(value, "YYYY-MM-DDThh:mm").toDate();
    } else {
      selectedValue = moment(value, "YYYY-MM-DDThh:mm", "YYYY-MM-DD").toDate();
    }
  }

  const transformDate = (e: Date | null) => {
    if (e && !isNaN(e.getTime())) {
      if (dateTime) {
        return moment(e).startOf("day").toJSON();
      } else {
        return moment(e).format("YYYY-MM-DD");
      }
    } else {
      return null;
    }
  };

  return (
    <FormGroup style={style} $error={!!error}>
      <Label
        style={{
          color: error ? colorTheme("danger") : colorTheme("black"),
          ...labelStyle,
        }}
      >
        {label}
        {required && <RequiredSpan style={requiredStyle}>*</RequiredSpan>}
      </Label>
      <div>
        <DatePickerContainer data-testid={testId} $inputStyle={inputStyle}>
          <DatePicker
            fixedHeight
            popperProps={{
              strategy: fixedPosition ? "fixed" : "absolute",
            }}
            showPopperArrow={false}
            selected={selectedValue}
            placeholderText="Select date"
            onBlur={() => onBlur && onBlur(transformDate(currentValue))}
            onSelect={(date) => onBlur && onBlur(transformDate(date))}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "") {
                onChange(transformDate(currentValue));
              }
            }}
            onChange={(date) => {
              setCurrentValue(date);
              onChange(transformDate(date));
            }}
            className="date-picker-class"
          />
          {error ? (
            <HelperText
              style={{ color: colorTheme("danger"), ...helperTextStyle }}
            >
              {typeof error === "string" ? error : ""}
            </HelperText>
          ) : helperText ? (
            <HelperText style={helperTextStyle}>{helperText}</HelperText>
          ) : null}
        </DatePickerContainer>
      </div>
    </FormGroup>
  );
}

export default SingleDatePicker;
