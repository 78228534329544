import INSIGHTS_URLS from "./urls";

export default [
  {
    appTitle: true,
    header: "Insights Studio",
    icon: "fa-kit fa-hdp-app-insights-studio",
    baseRoute: INSIGHTS_URLS.baseUrl,
  },
  {
    header: "Cohort",
    children: [
      {
        name: "Groups",
        route: INSIGHTS_URLS.COHORT_GROUPS,
        permissions: ["cohort.view_group"],
      },
      {
        name: "Simple Rules",
        route: INSIGHTS_URLS.COHORT_SIMPLE_RULES,
        permissions: ["cohort.view_simplecohort"],
      },
      {
        name: "Complex Rules",
        route: INSIGHTS_URLS.COHORT_COMPLEX_RULES,
        permissions: ["cohort.view_complexcohort"],
      },
    ],
  },
  {
    header: "Insights",
    children: [
      {
        name: "Console",
        route: INSIGHTS_URLS.INSIGHTS_CONSOLE,
        permissions: ["insights.view_console"],
      },
      // {
      //   name: "Dataset",
      //   route: INSIGHTS_URLS.INSIGHTS_DATASET,
      //   permissions: ["quicksight.view_quicksightdataset"],
      // },
    ],
  },
];
