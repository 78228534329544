import React, { useState } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import { colorTheme } from "@utils";

import { DropDown, SearchText } from "../";
import { capitalizeFirstLetters } from "../utilities";

const ButtonStyle = styled.div`
  color: ${colorTheme("neutral")};
  display: flex;
  background: ${colorTheme("transparent")};
  border-color: ${colorTheme("transparent")};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 5px;
  margin: 0;
  outline: 0;
  padding: 0;
  background-image: none;
  white-space: nowrap;
  height: 40px;
  max-height: 40px;

  &:hover {
    cursor: ${(props) =>
      props.disabled === "disabled" ? "not-allowed" : "pointer"};
    background: ${colorTheme("neutralL4")};
  }

  &:focus {
    outline: 0;
  }
`;

const Icon = styled.i`
  font-size: 10px;
  margin-left: 10px;
  font-weight: bold;
`;

const CheckIcon = styled.i`
  color: ${(props) => props.color};
  font-size: 16px;
  margin-right: 10px;
`;

const Container = styled.div`
  height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
`;

const FilterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
`;

const MultiValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ddd;
  padding-left: 5px;
  margin-right: 3px;
  border-radius: 3px;
  height: 26px;
`;

const SingleValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ddd;
  padding: 0 5px;
  margin-right: 3px;
  border-radius: 3px;
  height: 26px;
`;

const ValueIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
  margin-left: 3px;
  min-height: 100%;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;

  &:hover {
    background: #f0beaf;
  }
`;

const ValueIcon = styled.div`
  font-size: 8px;
  font-weight: bold;
  margin: 0 5px;
`;

const ClearAllIcon = styled.div`
  margin-left: 5px;
  font-size: 14px;
  color: ${colorTheme("neutralL2")};

  &:hover {
    color: ${colorTheme("neutral")};
  }
`;

const FilterText = styled.b`
  font-size: 14px;
  padding-right: 5px;
`;

const FilterButton = ({
  text,
  disabled,
  style,
  options,
  value,
  setValue,
  isMulti,
  isMore,
  isSelector,
  hideText,
  visible = true,
  alwaysClear,
  formatLabel = true,
  testId,
}) => {
  if (!visible) {
    return null;
  }

  const [searchFilter, setSearchFilter] = useState("");

  const renderSingleButton = () => {
    if (!value) {
      return (
        <FilterButtonContainer>
          {text}
          <Icon className="fa-solid fa-chevron-down" />
        </FilterButtonContainer>
      );
    }

    return (
      <FilterButtonContainer>
        {!hideText && (
          <FilterText>
            {text} {value && ":"}
          </FilterText>
        )}
        <SingleValue>
          {formatLabel
            ? capitalizeFirstLetters(value.replaceAll("_", " "))
            : value}
        </SingleValue>
        <ClearAllIcon
          className="fa-solid fa-times-circle"
          data-testid="single-clear-all-icon"
          data-disabletoggle="true"
          onClick={() => setValue("")}
        />
        <Icon className="fa-solid fa-chevron-down" />
      </FilterButtonContainer>
    );
  };

  const renderMultiButton = () => {
    if (value?.length === 0) {
      return (
        <FilterButtonContainer>
          {text}
          {alwaysClear && (
            <ClearAllIcon
              className="fa-solid fa-times-circle"
              data-testid="multi-clear-all-icon"
              data-disabletoggle="true"
              onClick={() => setValue([])}
            />
          )}
          <Icon className="fa-solid fa-chevron-down" />
        </FilterButtonContainer>
      );
    }

    return (
      <FilterButtonContainer>
        {!hideText && (
          <FilterText>
            {text} {value && ":"}
          </FilterText>
        )}
        {value?.map((_value, i) => (
          <MultiValue key={i}>
            {formatLabel
              ? capitalizeFirstLetters(_value.replaceAll("_", " "))
              : _value?.label || _value}
            <ValueIconContainer
              onClick={() => {
                setValue(value.filter((e) => e !== _value));
              }}
              data-disabletoggle="true"
            >
              <ValueIcon
                className="fa-solid fa-times"
                data-testid="multi-clear-item-icon"
                data-disabletoggle="true"
              />
            </ValueIconContainer>
          </MultiValue>
        ))}
        <ClearAllIcon
          className="fa-solid fa-times-circle"
          data-testid="multi-clear-all-icon"
          data-disabletoggle="true"
          onClick={() => setValue([])}
        />
        <Icon className="fa-solid fa-chevron-down" />
      </FilterButtonContainer>
    );
  };

  const renderMoreButton = () => (
    <FilterButtonContainer>
      More
      <Icon className="fa-solid fa-plus" />
    </FilterButtonContainer>
  );

  const renderSelectorButton = () => (
    <FilterButtonContainer>
      {text}
      <ClearAllIcon
        className="fa-solid fa-times-circle"
        data-testid="selector-clear-all-icon"
        data-disabletoggle="true"
        onClick={() => setValue("")}
      />
      <Icon className="fa-solid fa-chevron-down" />
    </FilterButtonContainer>
  );

  const MainButton = () => {
    if (isMulti) {
      return renderMultiButton();
    } else if (isMore) {
      return renderMoreButton();
    } else if (isSelector) {
      return renderSelectorButton();
    }

    return renderSingleButton();
  };

  const renderOptions = options
    .filter((option) => {
      if (!option) return false;

      if (option.label) {
        return option.label.toLowerCase().includes(searchFilter.toLowerCase());
      }

      return option.toLowerCase().includes(searchFilter.toLowerCase());
    })
    .map((option, i) => {
      let optionLabel = option?.label || option;
      const optionValue = option;

      if (formatLabel) {
        optionLabel = capitalizeFirstLetters(optionLabel.replaceAll("_", " "));
      }

      const selected =
        value === optionValue ||
        (Array.isArray(value) &&
          value.some((v) => v === optionValue || v === optionValue?.value)) ||
        (!!optionValue.value &&
          value?.some((_value) => optionValue?.value === _value.value));

      return (
        <DropDown.Item
          key={i}
          onClick={() => {
            if (isMulti) {
              if (selected) {
                setValue(value.filter((_value) => _value !== optionValue));
              } else {
                setValue([...value, optionValue]);
              }
            } else {
              if (selected) {
                if (isMore) {
                  return;
                }
                setValue("");
              } else {
                setValue(optionValue);
              }
            }
          }}
        >
          {selected ? (
            <CheckIcon
              className="fa-solid fa-check-square"
              color={colorTheme("primary")}
            />
          ) : (
            <CheckIcon className="fa-solid fa-square" color="#d3d3d3" />
          )}
          {optionLabel}
        </DropDown.Item>
      );
    });

  return (
    <Container>
      <DropDown closeOnSelect={!isMulti}>
        <DropDown.Toggle>
          <ButtonStyle disabled={disabled} style={style} data-testid={testId}>
            <MainButton />
          </ButtonStyle>
        </DropDown.Toggle>
        <DropDown.Menu>
          {(renderOptions.length > 10 || searchFilter.length > 0) && (
            <SearchText
              style={{
                width: "100%",
                minHeight: 40,
                boxSizing: "border-box",
                border: "none",
                borderBottom: "1px solid #d9d9d9",
                borderRadius: 0,
              }}
              value={searchFilter}
              onChange={(e) => setSearchFilter(e.target.value)}
            />
          )}
          {renderOptions.length > 0 ? (
            <div style={{ maxHeight: 300, overflow: "auto" }}>
              {renderOptions}
            </div>
          ) : (
            <div style={{ padding: 5, textAlign: "center" }}>No Results</div>
          )}
        </DropDown.Menu>
      </DropDown>
    </Container>
  );
};

FilterButton.propTypes = {
  /** Text to be displayed inside button */
  text: PropTypes.string,
  /** FilterButton is disabled or not */
  disabled: PropTypes.bool,
  /** Style object */
  style: PropTypes.object,
  /** Array of options that can be selected */
  options: PropTypes.array,
  /** The current value of the filter */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /** Function to set the value */
  setValue: PropTypes.func,
  /** If the filter can contain multiple values */
  isMulti: PropTypes.bool,
  /** To hide the text in front of the filter value */
  hideText: PropTypes.bool,
  /** Shows only more button */
  isMore: PropTypes.bool,
  /**  Button that calls setValue only */
  isSelector: PropTypes.bool,
  /**  Is the button visible */
  visible: PropTypes.bool,
  /**  Always show the clear button */
  alwaysClear: PropTypes.bool,
  /**  Format label to capital and replace underscores */
  formatLabel: PropTypes.bool,
  /** data-testid attribute */
  testId: PropTypes.string,
};

export default FilterButton;
