import React from "react";

import { colorTheme } from "@utils";

import Item from "./Item";
import type { ListInsightsDashboard } from "~/src/types";
import { LEAP_INSIGHTS_URLS } from "~/src/urls";

type DashboardItemProps = {
  dashboard: ListInsightsDashboard;
  groupPath: string;
  subjectPath: string;
  selectedObject: string;
  setSelectedObject: (value: string) => void;
};

const DashboardItem = ({
  dashboard,
  groupPath,
  subjectPath,
  selectedObject,
  setSelectedObject,
}: DashboardItemProps) => (
  <Item
    icon="fa-solid fa-table-layout"
    style={{
      color:
        dashboard.type === "custom"
          ? colorTheme("neutralL1")
          : colorTheme("info"),
    }}
    text={dashboard.name}
    path={`${LEAP_INSIGHTS_URLS.baseUrl}/${subjectPath}/${groupPath}/${dashboard.identifier}`}
    paddingLeft={80}
    selectedObject={selectedObject}
    setSelectedObject={setSelectedObject}
  />
);

export default DashboardItem;
