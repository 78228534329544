import React, { useEffect, useState } from "react";

import { colorTheme } from "@utils";

import SelectAll from "./SelectAll";
import Option from "../Option";
import type { OptionProp } from "../Select";

interface GroupOptionsProps {
  options?: OptionProp[];
  radio?: boolean;
  checkbox?: boolean;
  value?: string | string[];
  type?: "basic" | "popout" | "accordion" | "breadcrumbs";
  selectOption: (option: OptionProp) => void;
  selectedValuesAtTop?: boolean;
  showSelectAll?: boolean;
  selectMultiple: (options: OptionProp[]) => void;
  deselectMultiple: (options: OptionProp[]) => void;
}

const GroupOptions = (props: GroupOptionsProps) => {
  const [selectedOptions, setSelectedOptions] = useState<OptionProp[]>([]);

  useEffect(() => {
    if (props.selectedValuesAtTop) {
      setSelectedOptions(
        props.options?.filter((option) =>
          Array.isArray(props.value)
            ? props.value?.some((v) => v === option.value)
            : props.value === option.value,
        ) ?? [],
      );
    }
  }, []);

  const renderOptions = (options?: OptionProp[]) => {
    if (!options) return null;

    return options.map((option, index) => (
      <Option
        key={index}
        {...option}
        radio={props.radio}
        checkbox={props.checkbox}
        onClick={() => {
          props.selectOption(option);
        }}
        isSelected={
          Array.isArray(props.value)
            ? props.value?.some((v) => v === option.value)
            : props.value === option.value
        }
      />
    ));
  };

  const filteredOptions =
    props.options?.filter(
      (o) =>
        !selectedOptions.some(
          (selectedOption) => selectedOption.value === o.value,
        ),
    ) ?? [];

  return (
    <>
      <SelectAll
        visible={props.showSelectAll}
        selectAll={() => props.selectMultiple(props.options ?? [])}
        deselectAll={() => props.deselectMultiple(props.options ?? [])}
        type={props.type}
      />
      {selectedOptions.length > 0 && (
        <div
          style={{
            borderBottom:
              filteredOptions.length > 0
                ? `1px solid ${colorTheme("neutralL5")}`
                : "none",
          }}
          data-testid="selected-options-container"
        >
          {renderOptions(selectedOptions)}
        </div>
      )}
      {renderOptions(filteredOptions)}
    </>
  );
};

export default GroupOptions;
