export { default as capitalizeFirstLetters } from "./capitalizeFirstLetters";

export function numberWithCommas(x) {
  if (!x) return 0;

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getButtonClassName = (icon) => {
  if (
    icon?.includes("fa-regular") ||
    icon?.includes("fa-light") ||
    icon?.includes("fa-duotone") ||
    icon?.includes("fa-thin") ||
    icon?.includes("fa-kit") ||
    icon?.includes("fa-brands") ||
    icon?.includes("fa-sharp")
  ) {
    return icon;
  }

  return `fa-solid ${icon}`;
};
