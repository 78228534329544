import type { ReactNode } from "react";
import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

const SubText = styled.div`
  color: ${colorTheme("neutralL2")};
  font-size: 12px;
`;

const TableData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

interface SubTextCellProps {
  content: ReactNode;
  subText: ReactNode;
}

const SubTextCell = ({ content, subText }: SubTextCellProps) => (
  <TableData>
    {content}
    <SubText>{subText}</SubText>
  </TableData>
);

export default SubTextCell;
