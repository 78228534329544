import moment from "moment";
import styled from "styled-components";

import { colorTheme } from "@utils";

import gridSpacing from "../../utils/gridSpacing";

export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export interface AutoSelectOptionType {
  label: string;
  func: () => [moment.Moment, moment.Moment];
  isSelected: (startDate: moment.Moment, endDate: moment.Moment) => boolean;
}

export const autoSelectOptions: Record<string, AutoSelectOptionType> = {
  today: {
    label: "Today",
    func: () => [moment(), moment()],
    isSelected: (startDate: moment.Moment, endDate: moment.Moment) =>
      startDate.isSame(moment(), "day") && endDate.isSame(moment(), "day"),
  },
  yesterday: {
    label: "Yesterday",
    func: () => [moment().subtract(1, "days"), moment().subtract(1, "days")],
    isSelected: (startDate: moment.Moment, endDate: moment.Moment) =>
      startDate.isSame(moment().subtract(1, "day"), "day") &&
      endDate.isSame(moment().subtract(1, "day"), "day"),
  },
  tomorrow: {
    label: "Tomorrow",
    func: () => [moment().add(1, "days"), moment().add(1, "days")],
    isSelected: (startDate: moment.Moment, endDate: moment.Moment) =>
      startDate.isSame(moment().add(1, "day"), "day") &&
      endDate.isSame(moment().add(1, "day"), "day"),
  },
  last7Days: {
    label: "Last 7 days",
    func: () => [moment().subtract(6, "days"), moment()],
    isSelected: (startDate: moment.Moment, endDate: moment.Moment) =>
      startDate.isSame(moment().subtract(6, "days"), "day") &&
      endDate.isSame(moment(), "day"),
  },
  last30Days: {
    label: "Last 30 days",
    func: () => [moment().subtract(29, "days"), moment()],
    isSelected: (startDate: moment.Moment, endDate: moment.Moment) =>
      startDate.isSame(moment().subtract(29, "days"), "day") &&
      endDate.isSame(moment(), "day"),
  },
  last365Days: {
    label: "Last 365 days",
    func: () => [moment().subtract(364, "days"), moment()],
    isSelected: (startDate: moment.Moment, endDate: moment.Moment) =>
      startDate.isSame(moment().subtract(364, "days"), "day") &&
      endDate.isSame(moment(), "day"),
  },
  lastCalendarYear: {
    label: "Last calendar year",
    func: () => [
      moment().startOf("year").subtract(1, "year"),
      moment().startOf("year").subtract(1, "day"),
    ],
    isSelected: (startDate: moment.Moment, endDate: moment.Moment) =>
      startDate.isSame(moment().startOf("year").subtract(1, "year"), "day") &&
      endDate.isSame(moment().startOf("year").subtract(1, "day"), "day"),
  },
  previousMonth: {
    label: "Previous month",
    func: () => [
      moment().startOf("month").subtract("1", "months"),
      moment().startOf("month").startOf("month").subtract(1, "day"),
    ],
    isSelected: (startDate: moment.Moment, endDate: moment.Moment) =>
      startDate.isSame(
        moment().startOf("month").subtract("1", "months"),
        "day",
      ) && endDate.isSame(moment().startOf("month").subtract(1, "day"), "day"),
  },
  previous3Months: {
    label: "Previous 3 months",
    func: () => [
      moment().startOf("month").subtract("3", "months"),
      moment().startOf("month").subtract(1, "day"),
    ],
    isSelected: (startDate: moment.Moment, endDate: moment.Moment) =>
      startDate.isSame(
        moment().startOf("month").subtract("3", "months"),
        "day",
      ) && endDate.isSame(moment().startOf("month").subtract(1, "day"), "day"),
  },
  previous6Months: {
    label: "Previous 6 months",
    func: () => [
      moment().startOf("month").subtract("6", "months"),
      moment().startOf("month").subtract(1, "day"),
    ],
    isSelected: (startDate: moment.Moment, endDate: moment.Moment) =>
      startDate.isSame(
        moment().startOf("month").subtract("6", "months"),
        "day",
      ) && endDate.isSame(moment().startOf("month").subtract(1, "day"), "day"),
  },
  previous12Months: {
    label: "Previous 12 months",
    func: () => [
      moment().startOf("month").subtract("1", "year"),
      moment().startOf("month").subtract(1, "day"),
    ],
    isSelected: (startDate: moment.Moment, endDate: moment.Moment) =>
      startDate.isSame(
        moment().startOf("month").subtract("1", "year"),
        "day",
      ) && endDate.isSame(moment().startOf("month").subtract(1, "day"), "day"),
  },
  monthToDate: {
    label: "Month to date",
    func: () => [moment().startOf("month"), moment()],
    isSelected: (startDate: moment.Moment, endDate: moment.Moment) =>
      startDate.isSame(moment().startOf("month"), "day") &&
      endDate.isSame(moment(), "day"),
  },
  yearToDate: {
    label: "Year to date",
    func: () => [moment().startOf("year"), moment()],
    isSelected: (startDate: moment.Moment, endDate: moment.Moment) =>
      startDate.isSame(moment().startOf("year"), "day") &&
      endDate.isSame(moment(), "day"),
  },
};

export const DatePickerWrapper = styled.div`
  .react-datepicker__month-container {
    width: 50%;
  }
  .react-datepicker,
  .react-datepicker__header {
    width: 100%;
    border: none;
    background-color: ${colorTheme("white")};
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__day--keyboard-selected {
    border-color: ${colorTheme("white")};
    background-color: ${colorTheme("white")};
    color: ${colorTheme("black")};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__day:focus,
  .react-datepicker__day:active,
  .react-datepicker__day:hover {
    background-color: ${colorTheme("primary")};
    border-color: ${colorTheme("primary")};
    color: ${colorTheme("white")};
    outline: none;
  }

  .react-datepicker__day--in-selecting-range {
    background-color: ${colorTheme("primaryL1")};
    border-color: ${colorTheme("primaryL1")};
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    margin: ${gridSpacing[1]}px;
    border-radius: 1000px;
  }

  .react-datepicker__day-name {
    color: ${colorTheme("neutralL1")};
  }

  .react-datepicker__navigation-icon {
    left: 0;

    ::before {
      border-color: ${colorTheme("neutral")};
      border-width: 2px 2px 0 0;
      height: 8px;
      width: 8px;
      top: 11px;
    }
  }

  .react-datepicker__navigation {
    margin-top: 5px;
    height: 16px;
    width: 16px;
    padding: 6px;
    border-radius: 1000px;

    &:hover {
      background-color: ${colorTheme("neutralL5")};
    }
  }

  .react-datepicker__current-month {
    margin-bottom: ${gridSpacing[2]}px;
    color: ${colorTheme("neutral")};
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--selected {
    border-radius: 1000px;
  }

  .date-picker-class {
    background-color: ${colorTheme("white")};
    cursor: pointer;
    padding: 3px 10px;
    color: ${colorTheme("neutralL1")};
    border: 1px solid ${colorTheme("neutralL4")};
    border-radius: 5px;
    width: calc(100% - 20px);
    display: flex;
    height: 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }
`;

export type AutoSelectsValue = keyof typeof autoSelectOptions;
