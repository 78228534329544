import type { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";

import { SweetAlert, Toast } from "@commonComponents";
import type { CubeApi, DeeplyReadonly, Query } from "@cubejs-client/core";
import cubejs from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import axios from "axios";
import type { NavigateFunction } from "react-router";
import { styled } from "styled-components";

import { useCubeJsApi } from "./CubeJsApiContext";
import type { LHQueryRecordType, LHUseCubeQueryResult } from "./cubeTyping";
import type { TableSortSettings } from "~/src/types";
import { checkPermissions, makeHumanReadable } from "~/src/utils/index";

export type AdvFilterProps = Record<string, string | string[]>;

export type CubePagination = {
  limit: number;
  offset: number;
};

/**
 * Convert a TableSortSettings object to a Django ordering CSV string that can be passed to the API
 * @param sortSettings table sort settings
 */
export const tableSortSettingsToDjangoOrderingCsv = (
  sortSettings: TableSortSettings,
) =>
  sortSettings
    .map((setting) => `${setting.desc ? "-" : ""}${setting.id}`)
    .join(",");

export const getCubeJsApi = (): CubeApi => {
  const oktaTokenStorage = JSON.parse(
    localStorage.getItem("okta-token-storage") ?? "{}",
  );
  const accessToken = oktaTokenStorage?.accessToken?.accessToken ?? "";

  return cubejs(`Bearer ${accessToken}`, {
    apiUrl: `${axios.defaults.baseURL}analytics`,
  });
};

export const useCubeApiQuery = <
  TData,
  TQuery extends DeeplyReadonly<Query | Query[]> = DeeplyReadonly<
    Query | Query[]
  >,
>(
  query: TQuery,
) => {
  const { cubejsApi } = useCubeJsApi();
  const res = useCubeQuery(query, {
    cubeApi: cubejsApi,
  });

  if (res.resultSet) {
    // Change rawData to return all string values since pre-aggs always returns strings but could be turned off.
    // This simplifies the code since we always know values are strings

    // Store the original rawData method
    const originalRawData = res.resultSet.rawData.bind(res.resultSet);

    // Override the rawData method
    res.resultSet.rawData = () => {
      const rawData = originalRawData();
      return rawData.map((obj) => {
        const newObj: typeof obj = JSON.parse(JSON.stringify(obj));
        Object.keys(obj).forEach((key) => {
          newObj[key] = obj[key]?.toString() ?? "";
        });
        return newObj;
      });
    };
  }

  const resWithError = res as LHUseCubeQueryResult<
    TQuery,
    unknown extends TData ? LHQueryRecordType<TQuery> : TData
  >;

  resWithError.noPermissions =
    (res.error as unknown as { status: number | undefined })?.status === 403;

  return resWithError;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CubeQueryFunc = (...args: any[]) => DeeplyReadonly<Query | Query[]>;

// Gets the type of useCubeApiQuery().resultSet.rawData
/*
Example: CubeQueryResultRawDataType<typeof facilityAssignmentsQuery>
 */
export type CubeQueryResultRawDataType<TQueryFunction extends CubeQueryFunc> =
  LHQueryRecordType<ReturnType<TQueryFunction>>[];

// Gets the type of useCubeApiQuery().resultSet.rawData[0]
/*
Example: CubeQueryResultRawDataKeyType<typeof facilityAssignmentsQuery>
 */
export type CubeQueryResultRawDataKeyType<
  TQueryFunction extends CubeQueryFunc,
> = LHQueryRecordType<ReturnType<TQueryFunction>>;

// Gets the type of table cell props for a given query
/*
Example: CubeQueryTableCellProps<typeof facilityAssignmentsQuery>
 */
export type CubeQueryTableCellProps<T extends CubeQueryFunc> = {
  row: {
    original: CubeQueryResultRawDataKeyType<T>;
  };
  value: string;
};

// Gets the type of table columns for a given query
/*
Example: const columns: CubeTableColumns<typeof facilityAssignmentsQuery> = [...]
 */
export type CubeTableColumns<T extends CubeQueryFunc> = {
  Header: string;
  accessor?:
    | ((arg: CubeQueryResultRawDataKeyType<T>) => string | number)
    | keyof CubeQueryResultRawDataKeyType<T>;
  Cell: (props: CubeQueryTableCellProps<T>) => ReactNode;
  width?: number;
  id?: string;
  sticky?: "left" | "right";
  size?: number;
}[];

export type CubeTableCSVColumns<T extends CubeQueryFunc> = {
  csvColumn: string;
  jsColumn:
    | keyof CubeQueryResultRawDataKeyType<T>
    | ((row: CubeQueryResultRawDataKeyType<T>) => string | number | undefined)
    | (() => string | number | undefined);
}[];

export async function cubeFetch<
  QueryType extends DeeplyReadonly<Query | Query[]>,
>(cubeApi: CubeApi, query: QueryType) {
  return await cubeApi.load(query);
}

export const coerceToNumber = (
  value: string | number | boolean | undefined | null,
): number | undefined => {
  if (typeof value === "number") {
    return value;
  }

  if (typeof value === "string") {
    const numberValue = parseFloat(value);
    return isNaN(numberValue) ? undefined : numberValue;
  }

  if (typeof value === "boolean") {
    return value ? 1 : 0;
  }

  return undefined;
};

export const coerceToBool = (
  value: string | boolean | number | undefined | null,
): boolean => {
  if (typeof value === "string") {
    return value.toLowerCase() === "true";
  } else if (typeof value === "boolean") {
    return value;
  } else if (typeof value === "number") {
    return value === 1;
  }
  return false;
};

export const readablePhoneNumber = (
  phoneNumber: string | null | undefined,
): string | null | undefined => {
  if (!phoneNumber) {
    return phoneNumber;
  }

  // Remove all non-digit characters
  const digits = phoneNumber.replace(/\D/g, "");

  switch (digits.length) {
    case 10: // Standard US number (1234567890)
      return digits.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    case 11: // US number with country code (11234567890)
      if (digits.startsWith("1")) {
        return digits.substring(1).replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      }
      return digits;
    case 7: // Local number without area code
      return digits.replace(/(\d{3})(\d{4})/, "$1-$2");
    default:
      return digits;
  }
};

export type ValueOf<T> = T[keyof T];

export const useScrollContainer = (offset: number = 75) => {
  const [scrollContainerTop, setScrollContainerTop] = useState<number>(0);
  const scrollContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setScrollContainerTop(
      (scrollContainer.current ? scrollContainer.current.offsetTop : 0) +
        offset,
    );
  }, [offset]);

  return { scrollContainerTop, scrollContainer };
};

export const ScrollContainer = styled.div<{ $scrollContainerTop: number }>`
  min-height: ${({ $scrollContainerTop }) =>
    `calc(100vh - ${$scrollContainerTop}px)`};
  max-height: ${({ $scrollContainerTop }) =>
    `calc(100vh - ${$scrollContainerTop}px)`};
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const internalPageError = (message: string) => {
  Toast({
    message,
    type: "error",
  });
};

export const internalPageSuccess = (message: string) => {
  Toast({
    message,
    type: "success",
  });
};

export const includesIgnoreCase = (searchTerm: string, target: string) =>
  searchTerm.trim().toLowerCase().includes(target.toLowerCase());

//https://stackoverflow.com/questions/47057649/typescript-string-dot-notation-of-nested-object
type PathsToStringProps<T> = T extends string
  ? []
  : {
      [K in Extract<keyof T, string>]: [K, ...PathsToStringProps<T[K]>];
    }[Extract<keyof T, string>];

type Join<T extends string[], D extends string> = T extends []
  ? never
  : T extends [infer F]
    ? F
    : T extends [infer F, ...infer R]
      ? F extends string
        ? `${F}${D}${Join<Extract<R, string[]>, D>}`
        : never
      : string;

export type TableColumnsV7<TRow> = {
  Header?: string;
  accessor?:
    | keyof TRow
    | ((row: TRow) => string | number)
    // @ts-expect-error complaining
    | Join<PathsToStringProps<TRow>, ".">;
  width?: number;
  overflow?: boolean;
  Cell?: (props: {
    value: string | number | boolean;
    row: {
      original: TRow;
      index: number;
    };
    data: TRow[];
  }) => ReactNode;
  id?: string;
  sticky?: "left" | "right";
  disableResizing?: boolean;
  disableSortBy?: boolean;
}[];

export type CSVColumns<T extends object> = {
  csvColumn: string;
  jsColumn: // @ts-expect-error complaining
  | Join<PathsToStringProps<T>, ".">
    | ((row: T) => string | number | undefined)
    | (() => string | number | undefined);
}[];

export type LHErrorType<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in keyof T]?: T[K] extends any[]
    ? string
    : T[K] extends object | undefined
      ? LHErrorType<T[K]>
      : string;
} & { main?: string };

export const trackPageView = ({
  pageName,
  objectType,
  objectPK,
  objectIdentifier,
  objectVersion,
  l1App,
  l2App,
}: {
  pageName: string;
  objectType: string;
  objectPK?: string;
  objectIdentifier?: string;
  objectVersion?: string;
  l1App?:
    | "Leap Insights"
    | "Insights Studio"
    | "Data Hub"
    | "Provider 360"
    | "Consumer 360";
  l2App?:
    | "Cohort"
    | "Insights"
    | "Monitoring"
    | "Data Model"
    | "Orchestration"
    | "Integration"
    | "Transformation"
    | "Activity Stream"
    | "Attribution"
    | "Provider Directory"
    | "Configuration"
    | "Consumer Profile"
    | "Engagement";
}): void => {
  axios
    .post("/monitoring/page_view", {
      page_name: pageName,
      url: window.location.href,
      object_type: objectType,
      object_pk: objectPK,
      object_identifier: objectIdentifier,
      object_version: objectVersion,
      l1_app: l1App,
      l2_app: l2App,
    })
    .catch((err) => console.error("Could not record page view", err));
};

export const favoriteObject = async ({
  objectType,
  objectId,
  allFavorites,
  remove = false,
}: {
  objectType: string;
  objectId: string;
  allFavorites?:
    | string[]
    | {
        object_id: string;
        object_type: string;
      }[];

  remove?: boolean;
}): Promise<boolean> => {
  if (allFavorites) {
    if (
      allFavorites.some(
        (o) =>
          o === objectId || (typeof o === "object" && o.object_id === objectId),
      )
    ) {
      remove = true;
    }
  }

  if (remove) {
    await axios
      .post("/favorites/favorites/remove", {
        object_type: objectType,
        object_id: objectId,
      })
      .catch((err) => {
        internalPageError("Could not unfavorite object");
        console.error("Could not unfavorite object", err);
      });

    return false;
  } else {
    await axios
      .post("/favorites/favorites", {
        object_type: objectType,
        object_id: objectId,
      })
      .catch((err) => {
        internalPageError("Could not favorite object");
        console.error("Could not favorite object", err);
      });

    return true;
  }
};

export const filterObjectByKeySubstring = (
  obj: Record<string, unknown>,
  keySubstring: string,
): Record<string, unknown> =>
  Object.keys(obj ?? {}).reduce(
    (acc, key) => {
      if (key.includes(keySubstring)) {
        acc[key] = obj[key];
      }
      return acc;
    },
    {} as Record<string, unknown>,
  );

export const clamp = (num: number, min: number, max: number): number =>
  Math.min(Math.max(num, min), max);

// Not meant to be used directly. Helper for displaying inferred types in the IDE
export type Prettify<T> = {
  [K in keyof T]: T[K];
} & object;

export type SelectOptionArray = {
  label: string;
  value: string;
}[];

export const makeHumanReadableTime = (
  time: number | undefined | null,
): string => {
  if (!time) return "";

  const roundedTime = Math.round(time);
  const hours = Math.floor(roundedTime / 3600);
  const minutes = Math.floor((roundedTime % 3600) / 60);
  const seconds = roundedTime % 60;

  return `${hours ? `${hours}h ` : ""}${minutes ? `${minutes}m ` : ""}${seconds ? `${seconds}s` : ""}`;
};

export type DetailSlideoutData = {
  title: string | object | null | undefined;
  content: ReactNode;
}[][];

export const getCRUDPermissions = (app_name: string, model_name: string) => {
  return {
    canView: checkPermissions(`${app_name}.view_${model_name}`),
    canAdd: checkPermissions(`${app_name}.add_${model_name}`),
    canEdit: checkPermissions(`${app_name}.change_${model_name}`),
    canDelete: checkPermissions(`${app_name}.delete_${model_name}`),
  };
};

export const enumToOptions = <T extends object>(
  enumObj: T,
  MakeHumanReadable = false,
) =>
  Object.values(enumObj)
    .map((x) => {
      const label: string = MakeHumanReadable ? makeHumanReadable(x) : x;
      return {
        label: label,
        value: x,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

export type TableData = {
  pageSize: number;
  currentPage: number;
  sort: {
    id: string;
    desc: boolean;
  };
};

export const promptNavigate = (
  navigate: NavigateFunction,
  url: string,
  block: boolean = false,
  message: string = "You have unsaved changes that will be lost if you leave this page",
) => {
  if (block) {
    SweetAlert({
      title: "Are you sure?",
      text: message,
      success: () => navigate(url),
      showCancelButton: true,
      isWarning: true,
    });
  } else {
    navigate(url);
  }
};

export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = title || "Lucerna Health Platform";

    return () => {
      document.title = "Lucerna Health Platform";
    };
  }, [title]);
};
