import React from "react";

import { Collapse } from "react-collapse";

// https://www.npmjs.com/package/react-collapse
interface CollapseComponentProps {
  children: React.ReactNode;
  isOpened: boolean;
}

const CollapseComponent = ({
  children,
  isOpened = false,
}: CollapseComponentProps) => (
  <Collapse isOpened={isOpened}>{children}</Collapse>
);

export default CollapseComponent;
