import React, { useState, useEffect } from "react";

import moment from "moment";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import validator from "validator";

import "react-datepicker/dist/react-datepicker.css";
import { colorTheme, gridSpacing } from "@utils";

import { Text, Button, Popover } from "..";
import type { AutoSelectOptionType, AutoSelectsValue } from "./helpers";
import { DatePickerWrapper, autoSelectOptions, daysOfWeek } from "./helpers";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 476px;
  background-color: ${colorTheme("white")};
  border-radius: 12px;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.2);
`;

const ContentContainer = styled.div`
  display: flex;
  height: 404px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${gridSpacing[6]}px;
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${gridSpacing[6]}px;
  width: 536px;
`;

const AutoSelectContainer = styled.div`
  border-right: 1px solid ${colorTheme("neutralL4")};
  width: 200px;
  padding-top: ${gridSpacing[6]}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${gridSpacing[4]}px ${gridSpacing[5]}px;
  border-top: 1px solid ${colorTheme("neutralL4")};
`;

const AutoSelectOptionsContainer = styled.div<{ $fade: boolean }>`
  height: calc(100% - 24px);
  overflow-y: scroll;
  background: ${({ $fade }) =>
    $fade &&
    "linear-gradient(0deg, #787878 -165%, rgba(188, 188, 188, 0) 15%)"};
`;

const AutoSelectOption = styled.div<{ $selected: boolean }>`
  color: ${colorTheme("neutralL1")};
  padding: ${gridSpacing[2]}px 0;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  background-color: ${({ $selected }) =>
    $selected ? colorTheme("primaryL3") : colorTheme("transparent")};
  padding-left: ${gridSpacing[5]}px;
  &:hover {
    background-color: ${colorTheme("neutralL5")};
  }
`;
interface DatePickerProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  onApply: (startDate: moment.Moment, endDate: moment.Moment) => void;
  autoSelects?: AutoSelectsValue[];
  disableFutureDates?: boolean;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  testId?: string;
  align?: "start" | "end";
  trigger?: React.ReactNode;
}

const DatePickerComponent = ({
  startDate,
  endDate,
  onApply,
  autoSelects,
  disableFutureDates,
  minDate,
  maxDate,
  testId = "date-picker",
  align = "end",
  trigger,
}: DatePickerProps) => {
  const [_startDate, _setStartDate] = useState<Date>(startDate.toDate());
  const [_endDate, _setEndDate] = useState<Date | undefined>(endDate.toDate());
  const [startDateInput, setStartDateInput] = useState<string>("");
  const [endDateInput, setEndDateInput] = useState<string>("");
  const [errors, setErrors] = useState<{
    startDate: boolean;
    endDate: boolean;
  }>({
    startDate: false,
    endDate: false,
  });
  const [autoSelected, setAutoSelected] = useState<string>("");
  const [fade, setFade] = useState<boolean>(true);
  const [validAutoSelects, setValidAutoSelects] = useState<
    AutoSelectOptionType[]
  >([]);

  useEffect(() => {
    if (autoSelects) {
      const validAutoSelects = Array.from(
        new Set(
          autoSelects
            .filter((key) => autoSelectOptions[key] !== undefined)
            .map((key) => autoSelectOptions[key]),
        ),
      );

      setValidAutoSelects(validAutoSelects);
      setFade(validAutoSelects.length > 9);
    }
  }, []);

  useEffect(() => {
    _setStartDate(startDate.toDate());
  }, [startDate]);

  useEffect(() => {
    _setEndDate(endDate.toDate());
  }, [endDate]);

  useEffect(() => {
    setStartDateInput(
      _startDate ? moment(_startDate).format("MM/DD/YYYY") : "",
    );
  }, [_startDate]);

  useEffect(() => {
    setEndDateInput(_endDate ? moment(_endDate).format("MM/DD/YYYY") : "");
  }, [_endDate]);

  const updateDateViaText = (type: string, value: string) => {
    if (type === "start") {
      setStartDateInput(value);
      if (!validator.isDate(value, { format: "MM-DD-YYYY" })) {
        setErrors({ ...errors, startDate: true });
      } else {
        if (errors.startDate) {
          setErrors({ ...errors, startDate: false });
        }
        _setStartDate(moment(value).toDate());
      }
    } else if (type === "end") {
      setEndDateInput(value);
      if (!validator.isDate(value, { format: "MM-DD-YYYY" })) {
        setErrors({ ...errors, endDate: true });
      } else {
        if (errors.endDate) {
          setErrors({ ...errors, endDate: false });
        }

        _setEndDate(moment(value).toDate());
      }
    }
  };

  const renderAutoSelects = () => (
    <AutoSelectOptionsContainer
      $fade={fade}
      onScroll={(e) => {
        const scrollHeight =
          (e.target as HTMLElement).scrollHeight -
          (e.target as HTMLElement).scrollTop;

        if (scrollHeight > 350) {
          setFade(true);
        } else {
          setFade(false);
        }
      }}
    >
      {validAutoSelects.map((option, i) => (
        <AutoSelectOption
          key={i}
          $selected={
            autoSelected
              ? autoSelected === option.label
              : option.isSelected(moment(_startDate), moment(_endDate))
          }
          onClick={() => {
            const [newStartDate, newEndDate] = option.func();
            _setStartDate(newStartDate.startOf("day").toDate());
            _setEndDate(newEndDate.endOf("day").toDate());
            setAutoSelected(option.label);
            setErrors({ startDate: false, endDate: false });
          }}
        >
          {option.label}
        </AutoSelectOption>
      ))}
    </AutoSelectOptionsContainer>
  );

  const close = () => {
    _setStartDate(startDate.toDate());
    _setEndDate(endDate.toDate());
    setAutoSelected("");
    setErrors({ startDate: false, endDate: false });
  };

  return (
    <Popover
      onOpenChange={(open) => {
        if (!open) {
          close();
        }
      }}
    >
      <Popover.Trigger>
        {trigger ? (
          <div data-testid={`${testId}-custom-trigger`}>{trigger}</div>
        ) : (
          <Button
            type="secondary"
            icon="fa-calendar-alt"
            text={`${daysOfWeek[startDate.day()]} ${startDate.format(
              "MMM Do, YYYY",
            )}
              ${
                !endDate.isSame(startDate, "date")
                  ? ` to ${daysOfWeek[endDate.day()]} ${endDate.format(
                      "MMM Do, YYYY",
                    )}`
                  : ""
              }`}
            testId={`${testId}-trigger`}
          />
        )}
      </Popover.Trigger>
      <Popover.Content side="bottom" align={align}>
        <Container data-testid={testId}>
          <ContentContainer>
            {autoSelects && (
              <AutoSelectContainer>
                <span
                  style={{
                    fontSize: 12,
                    color: colorTheme("neutralL2"),
                    marginLeft: gridSpacing[5],
                  }}
                >
                  Auto Select
                </span>
                {renderAutoSelects()}
              </AutoSelectContainer>
            )}

            <DatePickerContainer>
              <TextContainer>
                <Text
                  placeholder="From"
                  value={startDateInput}
                  onChange={(e) => updateDateViaText("start", e.target.value)}
                  error={errors.startDate}
                  style={{ flexGrow: 1, marginBottom: 0 }}
                  testId="start-date-input"
                />
                <i
                  className="fa-regular fa-arrow-right"
                  style={{
                    padding: `0 ${gridSpacing[2]}px`,
                    color: colorTheme("neutralL2"),
                  }}
                />
                <Text
                  placeholder="To"
                  value={endDateInput}
                  onChange={(e) => updateDateViaText("end", e.target.value)}
                  error={errors.endDate}
                  style={{ flexGrow: 1, marginBottom: 0 }}
                  testId="end-date-input"
                />
              </TextContainer>
              <DatePickerWrapper>
                <DatePicker
                  selectsRange
                  inline
                  disabledKeyboardNavigation
                  minDate={minDate ? minDate.toDate() : undefined}
                  maxDate={
                    maxDate
                      ? maxDate.toDate()
                      : disableFutureDates
                        ? moment().toDate()
                        : undefined
                  }
                  selected={_startDate}
                  onChange={(dates) => {
                    setAutoSelected("");
                    const [start, end] = dates;
                    _setStartDate(moment(start).startOf("day").toDate());
                    _setEndDate(
                      end ? moment(end).endOf("day").toDate() : undefined,
                    );
                  }}
                  startDate={_startDate}
                  endDate={_endDate}
                  monthsShown={2}
                />
              </DatePickerWrapper>
            </DatePickerContainer>
          </ContentContainer>

          <ButtonContainer>
            <Popover.Close>
              <Button small mr type="secondary" text="Cancel" onClick={close} />
            </Popover.Close>

            <Popover.Close>
              <Button
                small
                text="Apply"
                onClick={() => {
                  onApply(moment(_startDate), moment(_endDate));
                }}
                disabled={
                  errors.startDate || errors.endDate || !_startDate || !_endDate
                }
              />
            </Popover.Close>
          </ButtonContainer>
        </Container>
      </Popover.Content>
    </Popover>
  );
};

export default DatePickerComponent;
