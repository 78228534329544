import type { ChangeEvent } from "react";
import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import type { MarginInterface } from "../utilities/interfaces";
import { getMargins } from "../utilities/margin";

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchStyle = styled.label`
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const Slider = styled.span<{
  $checked: boolean;
  $disabled: boolean;
}>`
  position: absolute;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ $checked }) =>
    $checked ? colorTheme("primary") : colorTheme("neutralL3")};
  opacity: ${({ $disabled }) => ($disabled ? ".65" : "1")};

  transition: 0.4s;
  border-radius: 18px;

  &:before {
    left: ${({ $checked }) => ($checked ? "20px" : "4px")};
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    bottom: 3px;
    background-color: ${colorTheme("white")};
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const Label = styled.label`
  padding-left: 5px;
`;

interface SwitchProps extends MarginInterface {
  label?: string;
  labelDirection?: "left" | "right";
  labelStyle?: React.CSSProperties;
  checked: boolean;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  testId?: string;
}

function Switch({
  label,
  labelDirection = "right",
  labelStyle = {},
  checked,
  onChange = () => {},
  style,
  disabled = false,
  testId,
  m,
  mx,
  my,
  mt,
  mb,
  mr,
  ml,
}: SwitchProps) {
  style = { ...getMargins({ m, mx, my, mt, mb, mr, ml }), ...style };

  return (
    <SwitchContainer style={style}>
      {label && labelDirection === "left" && (
        <Label style={labelStyle}>{label}</Label>
      )}
      <SwitchStyle>
        <input
          type="checkbox"
          checked={checked}
          onChange={disabled ? () => {} : onChange}
          data-testid={testId}
        />
        <Slider $checked={checked} $disabled={disabled} />
      </SwitchStyle>
      {label && labelDirection === "right" && (
        <Label style={labelStyle}>{label}</Label>
      )}
    </SwitchContainer>
  );
}

export default Switch;
