import React from "react";

export const NoDashboards = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    width="250"
    height="250"
    viewBox="0 0 250 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g filter="url(#filter0_i_3413_5108)">
      <rect
        x="29.1667"
        y="29.1667"
        width="191.667"
        height="191.667"
        rx="95.8333"
        fill="#B1B5B7"
        fillOpacity="0.2"
      />
    </g>
    <g filter="url(#filter1_d_3413_5108)">
      <g clipPath="url(#clip0_3413_5108)">
        <rect
          x="27.3096"
          y="55"
          width="196.083"
          height="135.858"
          rx="5.60237"
          fill="white"
        />
        <rect
          x="39.9998"
          y="83.0002"
          width="99"
          height="55"
          rx="4.20178"
          fill="#F0F1F1"
        />
        <rect
          x="148"
          y="124"
          width="63"
          height="55"
          rx="4.20178"
          fill="#F0F1F1"
        />
        <circle cx="179.7" cy="151.7" r="14.7" fill="#005B93" />
        <path
          // eslint-disable-next-line max-len
          d="M192.383 159.133C193.711 156.867 194.407 154.287 194.4 151.661C194.393 149.034 193.682 146.458 192.342 144.199C191.002 141.94 189.081 140.082 186.779 138.817C184.478 137.552 181.879 136.927 179.254 137.007L179.7 151.7L192.383 159.133Z"
          fill="#23A6F9"
        />
        <rect
          x="51.9998"
          y="124.229"
          width="13.5667"
          height="6.16667"
          rx="2.80119"
          transform="rotate(-90 51.9998 124.229)"
          fill="#005B93"
        />
        <rect
          x="92.6995"
          y="124.229"
          width="20.9667"
          height="6.16667"
          rx="2.80119"
          transform="rotate(-90 92.6995 124.229)"
          fill="#23A6F9"
        />
        <rect
          x="79.1333"
          y="124.229"
          width="11.7167"
          height="6.16667"
          rx="2.80119"
          transform="rotate(-90 79.1333 124.229)"
          fill="#005B93"
        />
        <rect
          x="106.267"
          y="124.229"
          width="14.8"
          height="6.16667"
          rx="2.80119"
          transform="rotate(-90 106.267 124.229)"
          fill="#005B93"
        />
        <rect
          x="65.5669"
          y="124.229"
          width="17.2667"
          height="6.16667"
          rx="2.80119"
          transform="rotate(-90 65.5669 124.229)"
          fill="#23A6F9"
        />
        <rect
          x="119.833"
          y="124.229"
          width="13.5667"
          height="6.16667"
          rx="2.80119"
          transform="rotate(-90 119.833 124.229)"
          fill="#23A6F9"
        />
        <circle cx="37.1139" cy="64.8039" r="2.80119" fill="#005B93" />
        <circle cx="45.5175" cy="64.8036" r="2.80119" fill="#CCCCCC" />
        <circle cx="53.9208" cy="64.8036" r="2.80119" fill="#23A6F9" />
        <line
          x1="27.3101"
          y1="74.2585"
          x2="223.393"
          y2="74.2585"
          stroke="#B2C4D7"
          strokeOpacity="0.3"
          strokeWidth="0.700297"
        />
        <rect
          x="147.761"
          y="83"
          width="27.3116"
          height="32.2136"
          rx="2.80119"
          fill="#F3F3F3"
        />
        <rect
          x="183.072"
          y="83"
          width="27.3116"
          height="32.2136"
          rx="2.80119"
          fill="#F3F3F3"
        />
        <rect x="40" y="146" width="99" height="7" rx="3.5" fill="#DEDEDE" />
        <rect x="40" y="159" width="99" height="7" rx="3.5" fill="#DEDEDE" />
        <rect
          x="39.915"
          y="171.25"
          width="42.0178"
          height="7.00297"
          rx="3.50148"
          fill="#DEDEDE"
        />
      </g>
      <rect
        x="27.6597"
        y="55.3501"
        width="195.383"
        height="135.157"
        rx="5.25223"
        stroke="#B2C4D7"
        strokeWidth="0.700297"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_3413_5108"
        x="29.1667"
        y="29.1667"
        width="191.667"
        height="191.667"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="8.33333" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_3413_5108"
        />
      </filter>
      <filter
        id="filter1_d_3413_5108"
        x="20.3066"
        y="53.5994"
        width="210.089"
        height="149.864"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.60237" />
        <feGaussianBlur stdDeviation="3.50148" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3413_5108"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3413_5108"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_3413_5108">
        <rect
          x="27.3096"
          y="55"
          width="196.083"
          height="135.858"
          rx="5.60237"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
