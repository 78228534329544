import React, { useEffect, useState } from "react";

import {
  Button,
  Collapse,
  FilterButton,
  SearchText,
  StatusPill,
  RadioButton,
  GraphicCallout,
} from "@commonComponents";
// eslint-disable-next-line
import { parseDiff, Diff, Hunk, FileData } from "react-diff-view";
import "react-diff-view/style/index.css";
import { styled } from "styled-components";

import { colorTheme, gridSpacing } from "@utils";

import { iconTypes } from "./utils";

const HeaderContainer = styled.div<{ isOpened?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${gridSpacing[2]}px;
  border: 1px solid ${colorTheme("neutralL4")};
  background-color: ${colorTheme("neutralL6")};
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  border-radius: ${({ isOpened }) => (isOpened ? "3px 3px 0 0" : "3px")};
`;

const ContentContainer = styled.div`
  border: 1px solid ${colorTheme("neutralL4")};
  border-radius: 0 0 3px 3px;
  border-top: none;
`;

const CollapseIcon = styled.div`
  padding: ${gridSpacing[1]}px;
  margin-right: ${gridSpacing[2]}px;
`;

const ToggleTrigger = styled.div`
  cursor: pointer;
  margin-right: ${gridSpacing[2]}px;
`;

const RowCenteredDiv = styled.div`
  display: flex;
  align-items: center;
`;

const EMPTY_HUNKS: string[] = [];

interface DifferenceProps {
  path: string[];
  difference: string;
  objectType?: string;
}
interface DiffViewProps {
  differences: DifferenceProps[];
  testId?: string;
}

interface DiffProps extends FileData {
  _type: "add" | "delete" | "modify";
  path: string[];
  _objectType?: string;
}

interface FilterProps {
  search: string;
  type: string[];
  view: "split" | "unified";
  toggleAll: boolean;
}

const ChangeIconConfig = {
  add: {
    icon: `fa-solid ${iconTypes["add"]}`,
    color: colorTheme("success"),
  },
  delete: {
    icon: `fa-solid ${iconTypes["delete"]}`,
    color: colorTheme("danger"),
  },
  modify: {
    icon: `fa-solid ${iconTypes["modify"]}`,
    color: colorTheme("warning"),
  },
};

const DiffViewer = ({ differences, testId = "diff-viewer" }: DiffViewProps) => {
  const [diffs, setDiffs] = useState<DiffProps[]>([]);
  const [collapsedFiles, setCollapsedFiles] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filters, setFilters] = useState<FilterProps>({
    search: "",
    type: [],
    view: "split",
    toggleAll: false,
  });
  const [intitialLoading, setInitialLoading] = useState<boolean>(true);

  const _diffs = diffs
    .filter((diff) => {
      if (filters.type.length === 0) return true;
      return filters.type.includes(diff._type);
    })
    .filter((diff) => {
      if (filters.search === "") return true;
      return (
        diff.path.some((_path) =>
          _path.toLowerCase().includes(filters.search.toLowerCase().trim()),
        ) ||
        diff.hunks.some((hunk) =>
          hunk.changes.some((change) =>
            change.content
              .toLowerCase()
              .includes(filters.search.toLowerCase().trim()),
          ),
        )
      );
    });

  useEffect(() => {
    const parsedDiffs = differences.map((difference) => {
      const [diff] = parseDiff(difference.difference);
      let type = "modify";
      if (diff.hunks.length === 1) {
        if (diff.hunks[0].newLines === 1 && diff.hunks[0].newStart === 0) {
          type = "delete";
        } else if (
          diff.hunks[0].oldLines === 1 &&
          diff.hunks[0].oldStart === 0
        ) {
          type = "add";
        }
      }

      const _diff = {
        ...diff,
        path: difference.path,
        _objectType: difference.objectType,
        _type: type as "add" | "delete" | "modify",
      };
      return _diff;
    });
    setInitialLoading(false);
    setDiffs(parsedDiffs);
  }, [differences]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <RowCenteredDiv>
          <SearchText
            mr
            testId="search-input"
            fixedWidth
            searchButton
            value={searchValue}
            onChange={(e) => {
              if (e.target.value === "") {
                setFilters({
                  ...filters,
                  search: e.target.value,
                });
              }
              setSearchValue(e.target.value);
            }}
            onEnter={() => {
              setFilters({
                ...filters,
                search: searchValue,
              });
            }}
          />
          <span data-testid={`${testId}-filter-button`}>
            <FilterButton
              hideText
              isMulti
              options={["add", "delete", "modify"]}
              value={filters.type}
              setValue={(e) => {
                setFilters({
                  ...filters,
                  type: e,
                });
              }}
              text="Filter by type"
            />
          </span>
        </RowCenteredDiv>
        <RowCenteredDiv>
          <RadioButton
            checked={filters.view === "split"}
            onChange={() => {
              setFilters({
                ...filters,
                view: "split",
              });
            }}
            toggleTrigger={<ToggleTrigger>Split View</ToggleTrigger>}
          />
          <RadioButton
            checked={filters.view === "unified"}
            onChange={() => {
              setFilters({
                ...filters,
                view: "unified",
              });
            }}
            toggleTrigger={<ToggleTrigger>Unified View</ToggleTrigger>}
          />
          <Button
            ml
            type="secondary"
            testId={`${testId}-collapse-all-button`}
            icon={`fa-${filters.toggleAll ? "maximize" : "minimize"}`}
            onClick={() => {
              setFilters({
                ...filters,
                toggleAll: !filters.toggleAll,
              });
              setCollapsedFiles(
                filters.toggleAll
                  ? []
                  : diffs.map((diff) => diff.path.join(" / ")),
              );
            }}
          />
        </RowCenteredDiv>
      </div>
      {!_diffs.length && !intitialLoading ? (
        <GraphicCallout
          testId={`${testId}-no-results-graphic-callout`}
          empty="search"
        />
      ) : (
        <div style={{ marginTop: gridSpacing[4] }}>
          {_diffs.map((diff, i) => {
            const path = diff.path.join(" / ");
            const isOpened = !collapsedFiles.includes(path);
            const changeConfig = ChangeIconConfig[diff._type];

            return (
              <div
                style={{ marginBottom: gridSpacing[5] }}
                key={i}
                data-testid={`${testId}-panel-${path}`}
              >
                <HeaderContainer
                  data-testid={`${testId}-header-${path}`}
                  isOpened={isOpened}
                  onClick={() =>
                    setCollapsedFiles(
                      isOpened
                        ? [...collapsedFiles, path]
                        : collapsedFiles.filter((file) => file !== path),
                    )
                  }
                >
                  <RowCenteredDiv>
                    <i
                      className={changeConfig?.icon}
                      style={{
                        color: changeConfig?.color,
                        marginRight: gridSpacing[2],
                        fontSize: 12,
                      }}
                    />
                    <span>{path}</span>
                    {diff._objectType && (
                      <StatusPill
                        small
                        testId={`${testId}-object-type-${path}`}
                        ml={2}
                        text={diff._objectType}
                        type="default"
                      />
                    )}
                  </RowCenteredDiv>
                  <CollapseIcon
                    className={`fa-solid fa-chevron-${
                      isOpened ? "down" : "right"
                    }`}
                  />
                </HeaderContainer>
                <Collapse isOpened={isOpened}>
                  <ContentContainer data-testid={`${testId}-content-${path}`}>
                    <Diff
                      gutterType="none"
                      viewType={filters.view}
                      diffType={diff.type}
                      hunks={diff.hunks || EMPTY_HUNKS}
                    >
                      {(hunks) =>
                        hunks.map((hunk) => (
                          <Hunk key={hunk.content} hunk={hunk} />
                        ))
                      }
                    </Diff>
                  </ContentContainer>
                </Collapse>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DiffViewer;
