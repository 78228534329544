import React from "react";

import { StatusPill } from "@commonComponents";

import { gridSpacing, colorTheme } from "@utils";

import { DashboardFilter } from "./helpers";
import Item from "./Item";
import type {
  ListAnalyticDomain,
  ListInsightsDashboard,
  UserFavorites,
} from "~/src/types";
import { LEAP_INSIGHTS_URLS } from "~/src/urls";

type FavoriteItemProps = {
  selectedObject: string;
  setSelectedObject: (value: string) => void;
  name: string;
  favoriteType: string;
  path: string;
};

const FavoriteItem = ({
  favoriteType,
  name,
  selectedObject,
  setSelectedObject,
  path,
}: FavoriteItemProps) => (
  <Item
    style={{
      marginLeft: gridSpacing[8] + 4,
      color:
        favoriteType === "leap_insights_lucerna"
          ? colorTheme("info")
          : colorTheme("neutralL1"),
    }}
    icon={`fa-regular fa-${favoriteType === "leap_insights_subdomain" ? "folder" : "table-layout"}`}
    text={name}
    path={`${LEAP_INSIGHTS_URLS.baseUrl}/${path}`}
    selectedObject={selectedObject}
    setSelectedObject={setSelectedObject}
  />
);

type FavoriteGroupProps = {
  openSubjects: string[];
  toggleCollapsed: () => void;
  selectedObject: string;
  setSelectedObject: (value: string) => void;
  favoritesData: UserFavorites[];
  filteredSubjects: ListAnalyticDomain[];
  filteredDashboards: ListInsightsDashboard[];
  dashboardFilter: string;
  filterValue: string;
};

const FavoriteGroup = ({
  openSubjects,
  toggleCollapsed,
  selectedObject,
  setSelectedObject,
  favoritesData,
  filteredSubjects,
  filteredDashboards,
  dashboardFilter,
  filterValue,
}: FavoriteGroupProps) => {
  const collapsed = !openSubjects.includes("favorites");
  const path = "/leap-insights#favorites";

  const foundDashboardObjects = favoritesData.map((favorite) => {
    let favoriteObject = null;
    if (favorite.object_type === "leap_insights_subdomain") {
      filteredSubjects.forEach((subject) => {
        const foundSubdomain = subject.subdomains.find(
          (subdomain) => subdomain.uuid === favorite.object_id,
        );
        if (foundSubdomain) {
          favoriteObject = {
            ...foundSubdomain,
            object_type: favorite.object_type,
          };
          favoriteObject.path = `${subject.path}/${foundSubdomain.path}`;
        }
      });
    } else {
      const foundDashboard = filteredDashboards.find(
        (dashboard) => dashboard.uuid === favorite.object_id,
      );
      if (foundDashboard) {
        favoriteObject = {
          ...foundDashboard,
          object_type: favorite.object_type,
        };
      }
    }
    return favoriteObject;
  });

  const filteredDashboardObjects = foundDashboardObjects.filter(
    (favorite) =>
      favorite &&
      (!filterValue || favorite.name.toLowerCase().includes(filterValue)) &&
      (dashboardFilter === DashboardFilter.All ||
        dashboardFilter === favorite.managed_by),
  );

  return (
    <div>
      <Item
        icon="fa-solid fa-star"
        text="Favorites"
        path={path}
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        style={{ fontSize: 18, marginLeft: -2, color: colorTheme("warning") }} // -2 is to align the star icon with the other icons
        selectedObject={selectedObject}
        setSelectedObject={setSelectedObject}
      >
        <StatusPill
          small
          type={path === selectedObject ? "primary" : "default"}
          text={filteredDashboardObjects.length.toString()}
          style={{ marginLeft: gridSpacing[2], height: 20 }}
        />
      </Item>
      {!collapsed && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {filteredDashboardObjects.map((favoriteObject) => {
            if (favoriteObject) {
              return (
                <FavoriteItem
                  key={favoriteObject.uuid}
                  selectedObject={selectedObject}
                  setSelectedObject={setSelectedObject}
                  name={favoriteObject.name}
                  favoriteType={favoriteObject.object_type as string}
                  path={favoriteObject.path}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default FavoriteGroup;
