import type { ReactNode } from "react";
import React from "react";

import * as Tooltip from "@radix-ui/react-tooltip";
import styled, { keyframes } from "styled-components";

import { colorTheme } from "@utils";

import gridSpacing from "../../utils/gridSpacing";

interface ToolTipProps {
  children: ReactNode;
  text: string;
  direction?: "top" | "bottom" | "left" | "right";
  visible?: boolean;
  style?: React.CSSProperties;
}

const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const TooltipContent = styled(Tooltip.Content)`
  z-index: 999;
  border-radius: 8px;
  padding: ${gridSpacing[2]}px ${gridSpacing[4]}px;
  font-size: 13px;
  line-height: 1;
  background-color: ${colorTheme("primaryD2")};
  box-shadow:
    hsl(206, 22%, 7% / 35%) 0px 10px 38px -10px,
    hsl(206, 22%, 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
  animation-duration: 200ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  color: white;

  &[data-state="delayed-open"][data-side="top"] {
    animation-name: ${slideDownAndFade};
  }
  &[data-state="delayed-open"][data-side="right"] {
    animation-name: ${slideLeftAndFade};
  }
  &[data-state="delayed-open"][data-side="bottom"] {
    animation-name: ${slideUpAndFade};
  }
  &[data-state="delayed-open"][data-side="left"] {
    animation-name: ${slideRightAndFade};
  }
`;

const TooltipArrow = styled(Tooltip.Arrow)`
  fill: ${colorTheme("primaryD2")};
`;

/**
 * ToolTip component
 * @param children
 * @param text - tooltip text
 * @param direction - top, bottom, left, right
 * @param style
 * @constructor
 */
const ToolTip = ({
  children,
  text,
  direction = "top",
  style = {},
}: ToolTipProps) => {
  if (!text) return <>{children}</>;
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild style={style}>
          <div>{children}</div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <TooltipContent side={direction} sideOffset={5}>
            {text}
            <TooltipArrow />
          </TooltipContent>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default ToolTip;
