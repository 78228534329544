import type { ReactNode } from "react";
import React, { useEffect, useRef } from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import BreadCrumbsGroup from "./BreadCrumbsGroup";
import gridSpacing from "../../../../utils/gridSpacing";
import Option from "../../Option";
import type { OptionProp } from "../../Select";
import SearchText from "~/src/CommonComponents/SearchText/SearchText";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-top: ${gridSpacing[1]}px;
`;

const BreadCrumbsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${gridSpacing[2]}px ${gridSpacing[3]}px;
`;

const BreadCrumbItem = styled.div<{ $activeGroup: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: ${({ $activeGroup }) =>
    $activeGroup ? colorTheme("neutralL1") : colorTheme("neutralL2")};
  font-weight: bold;
  cursor: pointer;

  i {
    font-size: 12px;
    color: colorTheme("neutralL2");
    cursor: default;
    font-weight: bold;
    margin-left: ${gridSpacing[2]}px;
    margin-right: ${gridSpacing[2]}px;
  }

  &:hover {
    color: ${({ $activeGroup }) =>
      $activeGroup ? colorTheme("neutralL1") : colorTheme("info")};
    cursor: ${({ $activeGroup }) => ($activeGroup ? "default" : "pointer")};
  }
`;

export type BreadCrumbsProps = {
  openedGroups: string[];
  setOpenedGroups: (groups: string[]) => void;
  label?: string;
  options?: OptionProp[];
  filteredOptions?: OptionProp[];
  customOptions?: React.ReactNode;
  selectOption: (
    e: { label?: string; value?: string },
    parentPath: string,
  ) => void;
  value?: string | string[];
  groupValue?: string;
  selectMultiple: (e: { label?: string; value?: string }[], id: string) => void;
  deselectMultiple: (
    e: { label?: string; value?: string }[],
    id: string,
  ) => void;
  showSelectAll?: boolean;
  searchValue: string;
  setSearchValue: (e: string) => void;
  noOptionsMessage?: string;
};

const BreadCrumbs = (props: BreadCrumbsProps) => {
  const breadCrumbGroupsContainerRef = useRef<HTMLDivElement>(null);
  const { openedGroups, setOpenedGroups, label } = props;

  // Breadcrumbs only uses the first index of the array and does dot notation to build the path to the current displayed group
  const groups = openedGroups?.[0]?.split(".") || [];

  // UseEffect to scroll to the top of the container anytime a new group is opened
  useEffect(() => {
    if (breadCrumbGroupsContainerRef.current) {
      breadCrumbGroupsContainerRef.current.scrollTop = 0;
    }
  }, [openedGroups]);

  const renderSubOptions = (
    options: OptionProp[],
    currentOptions: OptionProp[],
    parentPath?: string,
  ): ReactNode => {
    // If there is options, render the options
    if (options.length > 0) {
      return options.map((option, i) => {
        if (option.options) {
          return renderSubOptions(
            option.options,
            [...currentOptions, option],
            `${parentPath}.${option.value}`,
          );
        }

        const filteredDisplayOptions = [...currentOptions, option].filter(
          (_option) => !openedGroups.includes(`${_option.value}`),
        );
        return (
          <Option
            checkbox={!option.customOptions}
            data={option}
            key={currentOptions.join(".") + i}
            isSelected={props.value?.includes(`${option.value}`)}
            onClick={() => {
              if (option.customOptions && parentPath) {
                setOpenedGroups([parentPath + "." + option.value]);
              } else {
                props.selectOption(
                  { label: option.label, value: option.value },
                  parentPath || props.openedGroups[0],
                );
              }
            }}
          >
            {filteredDisplayOptions.map((_option, j) => (
              <span key={_option.value}>
                {_option.label}{" "}
                {j < filteredDisplayOptions.length - 1 && (
                  <i
                    className="fa-solid fa-chevron-right"
                    style={{
                      fontWeight: "bolder",
                      fontSize: 12,
                      padding: `${gridSpacing[1]}px ${gridSpacing[2]}px`,
                    }}
                  />
                )}
              </span>
            ))}
          </Option>
        );
      });
    } else {
      // Else render the groups that can be selected OR if there is opened groups, this will render the options and groups that can be selected
      const currentOptionValues = currentOptions.map((o) => o.value).join(".");
      const currentOption = currentOptions[0];
      const currentOpenedGroup = openedGroups[0]?.split(".") || [];

      if (
        currentOption?.customOptions &&
        currentOption.value &&
        currentOpenedGroup.includes(currentOption.value)
      ) {
        return (
          <div style={{ padding: gridSpacing[3] }}>
            {currentOption?.customOptions}
          </div>
        );
      }

      return (
        <Option
          key={currentOptionValues}
          onClick={() => {
            // If the current option does not contain any options, assume it is an option
            if (
              currentOption?.options === undefined &&
              !currentOption?.customOptions
            ) {
              props.selectOption(
                {
                  label: currentOption.label,
                  value: currentOption.value,
                },
                props.openedGroups[0],
              );
            } else {
              // Else assume it is a group (custom options are also considered groups)
              if (openedGroups.length > 0) {
                // This prepends the current group to the opened groups
                const newGroups = openedGroups[0] + "." + currentOption.value;
                setOpenedGroups([newGroups]);
              } else {
                // If there are no opened groups, set the opened groups to the current group
                let newGroups = currentOptionValues;

                // Open sub group if it exists
                if (
                  currentOption.value &&
                  !currentOptionValues.includes(currentOption.value)
                ) {
                  newGroups = +"." + currentOption.value;
                }

                setOpenedGroups([newGroups]);
              }

              props.setSearchValue("");
            }
          }}
          checkbox={
            currentOption?.options === undefined &&
            !currentOption?.customOptions
          }
          isSelected={props.value?.includes(`${currentOption?.value}`)}
        >
          {currentOptions.map((o, i) => (
            <span key={o.value}>
              {o.label}
              {i < currentOptions.length - 1 && (
                <i
                  className="fa-solid fa-chevron-right"
                  style={{
                    fontWeight: "bolder",
                    fontSize: 12,
                    padding: `${gridSpacing[1]}px ${gridSpacing[2]}px`,
                  }}
                />
              )}
            </span>
          ))}
        </Option>
      );
    }
  };

  const renderBody = () => {
    if (props.filteredOptions && props.filteredOptions.length > 0) {
      // If searchValue is present render options based on searchValue
      if (props.searchValue?.length > 0) {
        let searchOptions = props.filteredOptions;
        let parentPath: string | null = null;

        // If there is an opened group, find the options that match the opened group
        if (openedGroups.length > 0) {
          const openedGroupId = props.openedGroups[0];
          const openGroupArray = openedGroupId.split(".");
          const breadCrumbDepth = openGroupArray.length;

          for (let i = 0; i < breadCrumbDepth; i++) {
            const foundOption = searchOptions?.find(
              ({ value, label }) =>
                value === openGroupArray[i] || label === openGroupArray[i],
            );

            if (foundOption?.customOptions) {
              searchOptions = [foundOption];
            } else if (
              foundOption?.options &&
              foundOption?.options.length > 0
            ) {
              searchOptions = foundOption.options;
              parentPath =
                foundOption?.value + "." + foundOption.options[0].value;
            } else {
              searchOptions = [];
            }
          }
        }

        return searchOptions.map((option) =>
          renderSubOptions(
            option.options ?? [],
            [option],
            parentPath || option.value,
          ),
        );
      }

      if (props.filteredOptions.length > 0) {
        return props.filteredOptions.map((option, i) => (
          <BreadCrumbsGroup
            key={`option-${i}`}
            {...props}
            {...option}
            groupValue={option.value}
            value={Array.from(props.value || [])}
          />
        ));
      }
    }

    return <p style={{ padding: gridSpacing[3] }}>{props.noOptionsMessage}</p>;
  };

  return (
    <Container>
      <BreadCrumbsContainer>
        <BreadCrumbItem
          onClick={() => setOpenedGroups([])}
          $activeGroup={groups.length === 0}
        >
          {label}
        </BreadCrumbItem>
        {groups.map((o, i) => {
          const activeGroup = groups.length - 1 === i;

          const findLabel = (options: OptionProp[]) => {
            const option = options.find(
              (option) => option.value === o || option.label === o,
            );

            if (option) {
              return option.label;
            }

            let foundOption = "";
            for (const o of options) {
              if (o.options) {
                const childOption = findLabel(o.options);
                if (childOption) {
                  foundOption = childOption;
                  break;
                }
              }
            }

            if (foundOption) {
              return foundOption;
            }
          };

          const label = findLabel(props.options || []);
          return (
            <BreadCrumbItem key={o + i} $activeGroup={activeGroup}>
              <i className="fa fa-chevron-right" />
              <div
                onClick={() => {
                  const newGroups = groups
                    .splice(0, groups.indexOf(o) + 1)
                    .join(".");

                  setOpenedGroups([newGroups]);
                }}
              >
                {label}
              </div>
            </BreadCrumbItem>
          );
        })}
      </BreadCrumbsContainer>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: gridSpacing[2],
        }}
      >
        <SearchText
          fixedWidth
          value={props.searchValue}
          onChange={(e) => props.setSearchValue(e.target.value)}
          testId="adv-select-search"
          style={{
            width: "100%",
            border: "none",
            borderRadius: 0,
            borderTop: `1px solid ${colorTheme("neutralL4")}`,
            borderBottom: `1px solid ${colorTheme("neutralL4")}`,
          }}
        />
      </div>
      <div
        style={{
          maxHeight: `min(calc(var(--radix-popper-available-height) - 90px), 400px)`,
          overflowY: "auto",
        }}
        ref={breadCrumbGroupsContainerRef}
      >
        {renderBody()}
      </div>
    </Container>
  );
};

export default BreadCrumbs;
