import type { ReactNode, CSSProperties, MouseEvent } from "react";
import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

const Container = styled.p`
  text-decoration: underline;
  font-style: italic;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    color: ${colorTheme("primary")};
  }
`;

type HyperLinkProps = {
  /** The content to be rendered inside the hyperlink */
  children?: ReactNode;
  /** Click handler function */
  onClick?: (event: MouseEvent<HTMLParagraphElement>) => void;
  /** Custom styles to be applied */
  style?: CSSProperties;
  /** data-testid attribute */
  testId?: string;
};

const HyperLink = ({ onClick, children, style, testId }: HyperLinkProps) => (
  <Container onClick={onClick} style={style} data-testid={testId}>
    {children}
  </Container>
);

export default HyperLink;
