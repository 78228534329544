import React from "react";

import { SlideOutPanel, TableLink } from "@commonComponents";
import { connect } from "react-redux";

import { gridSpacing, colorTheme } from "@utils";

import DashboardObject from "./DashboardObject";
import { DashboardType } from "./helpers";
import type {
  ListInsightsDashboard,
  MinimalDocumentationLink,
  UserFavorites,
} from "~/src/types";
import { LEAP_INSIGHTS_URLS } from "~/src/urls";

interface DashboardDetailSlideOutProps {
  selectedDashboard: ListInsightsDashboard | null;
  setSelectedDashboard: (dashboard: ListInsightsDashboard | null) => void;
  documentationUrl: string;
  favorites: UserFavorites[];
  getFavorites: () => void;
  insightsDashboards: ListInsightsDashboard[];
}

const DashboardDetailSlideOut = ({
  selectedDashboard,
  setSelectedDashboard,
  documentationUrl,
  favorites,
  getFavorites,
  insightsDashboards,
}: DashboardDetailSlideOutProps) => {
  const path = `${LEAP_INSIGHTS_URLS.baseUrl}/${selectedDashboard?.path}`;
  const relatedDashboards = insightsDashboards.filter(
    (d) =>
      d.uuid !== selectedDashboard?.uuid &&
      d.subdomain.uuid === selectedDashboard?.subdomain.uuid,
  );

  return (
    <SlideOutPanel
      size={2}
      isOpened={!!selectedDashboard}
      setIsOpened={() => setSelectedDashboard(null)}
      closeCallback={() => setSelectedDashboard(null)}
      title={selectedDashboard?.name}
      headerButtonProps={
        path && selectedDashboard?.dashboard_type === DashboardType.Lucerna
          ? {
              text: "User Guide",
              icon: "fas fa-external-link-alt",
              type: "secondary",
              onClick: () => {
                window.open(`${documentationUrl}${path}`, "_blank");
              },
            }
          : undefined
      }
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ color: colorTheme("neutralL1"), marginTop: gridSpacing[2] }}
        >
          {selectedDashboard?.description ??
            selectedDashboard?.short_description}
        </div>
        {selectedDashboard?.embedded_details?.documentation_links &&
          selectedDashboard?.embedded_details?.documentation_links.length >
            0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: gridSpacing[5],
              }}
            >
              <h3 style={{ marginBottom: gridSpacing[2] }}>Links</h3>
              {selectedDashboard?.embedded_details?.documentation_links.map(
                (link: MinimalDocumentationLink) => (
                  <TableLink
                    newTab
                    key={link.name}
                    url={link.url}
                    style={{ marginBottom: gridSpacing[2] }}
                  >
                    {link.name}
                    <i
                      style={{ marginLeft: gridSpacing[2], fontSize: 14 }}
                      className="fas fa-external-link-alt"
                    />
                  </TableLink>
                ),
              )}
            </div>
          )}
        {relatedDashboards && relatedDashboards.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: gridSpacing[5],
              width: "100%",
            }}
          >
            <h3 style={{ marginBottom: gridSpacing[2] }}>Related Dashboards</h3>
            {relatedDashboards?.map((dashboard) => (
              <div
                key={dashboard.path}
                style={{ marginBottom: gridSpacing[5] }}
              >
                <DashboardObject
                  smallCard
                  dashboard={dashboard}
                  favorites={favorites}
                  getFavorites={getFavorites}
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </SlideOutPanel>
  );
};

const mapStateToProps = (state: {
  main: {
    documentationUrl: string;
  };
}) => {
  return {
    documentationUrl: state.main.documentationUrl,
  };
};

export default connect(mapStateToProps)(DashboardDetailSlideOut);
