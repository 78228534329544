import React, { Fragment } from "react";

import { colorTheme } from "@utils";

import GroupOptions from "./GroupOptions";
import { HoverCard } from "../..";
import gridSpacing from "../../../utils/gridSpacing";
import GroupLabel from "../GroupLabel";
import type { OptionProp } from "../Select";

type GroupProps = {
  label?: string;
  options?: OptionProp[];
  filteredOptions?: OptionProp[];
  openedGroups: string[];
  setOpenedGroups: (e: string[]) => void;
  type?: "basic" | "popout" | "accordion" | "breadcrumbs";
  value?: string | string[];
  selectOption: (e: { label?: string; value?: string }) => void;
  selectMultiple: (e: { label?: string; value?: string }[]) => void;
  deselectMultiple: (e: { label?: string; value?: string }[]) => void;
  checkbox?: boolean;
  radio?: boolean;
  noOptionsMessage?: string;
  selectedValuesAtTop?: boolean;
  showSelectAll?: boolean;
  searchValue: string;
  setSearchValue: (e: string) => void;
};

const Group = (props: GroupProps) => {
  if (!props.filteredOptions || props.filteredOptions.length === 0) {
    return (
      <div style={{ padding: 6, paddingLeft: gridSpacing[3] }}>
        {props.noOptionsMessage || "No options"}
      </div>
    );
  }

  if (props.type === "accordion") {
    return props.filteredOptions?.map((group) => {
      const id = group?.label ?? "";

      const isOpened = props.openedGroups.some(
        (group: string) => group === id || group.split(".")[0].includes(id),
      );

      const valueCount = group.options?.filter((o) =>
        props.value?.includes(`${o.value}`),
      ).length;

      const toggleOpenedGroups = () => {
        let _openedGroups = [];
        if (isOpened) {
          _openedGroups = props.openedGroups.filter((g: string) => g !== id);
        } else {
          _openedGroups = [...props.openedGroups, id];
        }

        props.setOpenedGroups(_openedGroups);
      };

      return (
        <Fragment key={group.value}>
          <GroupLabel
            accordion
            label={group.label}
            options={group.options}
            opened={isOpened}
            valueCount={valueCount}
            onClick={toggleOpenedGroups}
          />

          {isOpened && (
            <GroupOptions
              options={group.options}
              radio={props.radio}
              checkbox={props.checkbox}
              value={props.value}
              selectOption={props.selectOption}
              selectedValuesAtTop={props.selectedValuesAtTop}
              showSelectAll={props.showSelectAll}
              selectMultiple={() => props.selectMultiple(group.options ?? [])}
              deselectMultiple={() =>
                props.deselectMultiple(group.options ?? [])
              }
              type={props.type}
            />
          )}
        </Fragment>
      );
    });
  } else if (props.type === "popout") {
    return props.filteredOptions?.map((group, i) => {
      const valueCount = Array.isArray(props.value)
        ? props.value?.filter((o) =>
            group.options?.some((option) => option.value === o),
          ).length
        : 0;

      return (
        <HoverCard key={i} openDelay={0} closeDelay={0}>
          <HoverCard.Trigger style={{ textDecoration: "none" }}>
            <GroupLabel popup {...group} valueCount={valueCount} />
          </HoverCard.Trigger>
          <HoverCard.Content
            side="right"
            align="start"
            style={{ paddingLeft: gridSpacing[2] }}
          >
            <div
              style={{
                paddingTop: gridSpacing[2],
                paddingLeft: gridSpacing[3],
              }}
            >
              <label
                style={{
                  color: colorTheme("neutralL2"),
                  textTransform: "uppercase",
                  fontSize: 12,
                }}
              >
                {group.label}
              </label>
            </div>
            <div
              data-testid="popout-options"
              style={{
                maxHeight: 175,
                minHeight: 175,
                width: 375,
                overflow: "auto",
              }}
            >
              <GroupOptions
                options={group.options}
                radio={props.radio}
                checkbox={props.checkbox}
                value={props.value}
                selectOption={props.selectOption}
                selectedValuesAtTop={props.selectedValuesAtTop}
                showSelectAll={props.showSelectAll}
                selectMultiple={() => props.selectMultiple(group.options ?? [])}
                deselectMultiple={() =>
                  props.deselectMultiple(group.options ?? [])
                }
              />
            </div>
          </HoverCard.Content>
        </HoverCard>
      );
    });
  }

  return (
    <div
      style={{
        paddingLeft: gridSpacing[3],
        paddingRight: gridSpacing[3],
        maxHeight: `min(calc(var(--radix-popper-available-height) - 39px), 315px)`,
        overflowY: "auto",
      }}
    >
      {props.filteredOptions?.map((group) => {
        const valueCount = group.options?.filter((o) =>
          props.value?.includes(`${o.value}`),
        ).length;

        return (
          <div key={group.value} style={{ paddingTop: 6, paddingBottom: 6 }}>
            <GroupLabel {...group} valueCount={valueCount} />
            <GroupOptions
              options={group.options}
              radio={props.radio}
              checkbox={props.checkbox}
              value={props.value}
              selectOption={props.selectOption}
              showSelectAll={props.showSelectAll}
              selectMultiple={() => props.selectMultiple(group.options ?? [])}
              deselectMultiple={() =>
                props.deselectMultiple(group.options ?? [])
              }
              selectedValuesAtTop={props.selectedValuesAtTop}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Group;
