import React from "react";

export const GeneralError = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    width="250"
    height="250"
    viewBox="0 0 250 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g filter="url(#filter0_i_1852_4898)">
      <rect
        x="30"
        y="30"
        width="190"
        height="190"
        rx="95"
        fill="#B1B5B7"
        fillOpacity="0.2"
      />
    </g>
    <g filter="url(#filter1_d_1852_4898)">
      <g clipPath="url(#clip0_1852_4898)">
        <rect x="20" y="50" width="210" height="150" rx="8" fill="white" />
        <g style={{ mixBlendMode: "luminosity" }}>
          <circle cx="34" cy="64" r="4" fill="#005B93" />
          <circle cx="46" cy="64" r="4" fill="#CCCCCC" />
          <circle cx="58" cy="64" r="4" fill="#23A6F9" />
        </g>
        <line
          x1="20"
          y1="77.5"
          x2="300"
          y2="77.5"
          stroke="#B2C4D7"
          strokeOpacity="0.3"
        />
        <rect x="50" y="156" width="150" height="10" rx="5" fill="#DEDEDE" />
        <rect x="75" y="175" width="100" height="10" rx="5" fill="#DEDEDE" />
        <rect
          x="135.581"
          y="89"
          width="24.4186"
          height="24.4186"
          rx="6.51163"
          fill="#FFEBD2"
        />
        <rect
          x="90"
          y="109.349"
          width="36.6279"
          height="36.6279"
          rx="9.76744"
          fill="#FFEBD2"
        />
        <path
          // eslint-disable-next-line max-len
          d="M147.517 132.523H108.994C105.073 132.523 102.751 128.135 104.956 124.893L124.217 96.5669C126.155 93.7168 130.356 93.7168 132.294 96.5669L151.556 124.893C153.761 128.135 151.438 132.523 147.517 132.523Z"
          fill="#FCAC4A"
        />
        <rect
          x="125.814"
          y="105.279"
          width="4.96512"
          height="15.8884"
          fill="white"
        />
        <rect
          x="125.814"
          y="123.154"
          width="4.96512"
          height="4.96512"
          rx="2.48256"
          fill="white"
        />
      </g>
      <rect
        x="20.5"
        y="50.5"
        width="209"
        height="149"
        rx="7.5"
        stroke="#B2C4D7"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1852_4898"
        x="30"
        y="30"
        width="190"
        height="190"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1852_4898"
        />
      </filter>
      <filter
        id="filter1_d_1852_4898"
        x="10"
        y="48"
        width="230"
        height="170"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1852_4898"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1852_4898"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1852_4898">
        <rect x="20" y="50" width="210" height="150" rx="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
