import axios from "axios";

import { getHelper, startRefreshHelper } from "./actionHelpers";

export const setUser = (user) => {
  return { type: "MAIN_SET_USER", payload: { user } };
};

export const setTestMode = (userServiceKey, userEmail) => {
  return {
    type: "MAIN_SET_TEST_MODE",
    payload: { userServiceKey, userEmail },
  };
};

export const updateUserMeta = (key, value) => (dispatch, getState) => {
  let user = { ...getState().main.user };

  user.metadata = {
    ...user.metadata,
    [key]: value,
  };

  axios
    .put(`auth/user/${user.uuid}/metadata`, user.metadata)
    .then(() => {
      dispatch({
        type: "MAIN_SET_USER",
        payload: { user },
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

export const addRecentPageToUserMetadata = (url) => (dispatch, getState) => {
  const user = { ...getState().main.user };
  let lastVisitedPages = user.metadata.lastVisitedPages || [];

  lastVisitedPages = lastVisitedPages.filter((obj) => obj.path !== url);

  lastVisitedPages.unshift({
    path: url,
    time: Date.now(),
  });

  lastVisitedPages.splice(25);

  updateUserMeta("lastVisitedPages", lastVisitedPages)(dispatch, getState);
};

export const setWindowWidth = (width) => {
  return {
    type: "MAIN_SET_WINDOW_WIDTH",
    payload: { width },
  };
};

export const setThemeAll = (theme) => {
  return {
    type: "MAIN_THEME_SET_ALL",
    payload: { theme },
  };
};

export const setLogo = (logoUrl) => {
  return {
    type: "MAIN_SET_LOGO",
    payload: { logoUrl },
  };
};

export const setLastSearch = (lastSearch) => {
  return {
    type: "MAIN_SET_LAST_SEARCH",
    payload: { lastSearch },
  };
};

export const setImportExport = (payload) => {
  return {
    type: "MAIN_SET_IMPORT_EXPORT",
    payload,
  };
};
export const setExportForTable = (payload) => {
  return {
    type: "MAIN_SET_TABLE_EXPORT",
    payload,
  };
};

export const setAdvancedFilterRefresh = (refresh) => {
  return {
    type: "MAIN_SET_ADVANCED_FILTER_REFRESH",
    payload: { refresh },
  };
};

export const setApiConfig = (apiConfig) => {
  return {
    type: "MAIN_SET_API_CONFIG",
    payload: { apiConfig },
  };
};

export const setDocumentationURL = (documentationUrl) => {
  return {
    type: "MAIN_SET_DOCUMENTATION_URL",
    payload: { documentationUrl },
  };
};

export const confirmationModal = (payload) => {
  return {
    type: "MAIN_SET_CONFIRMATION_MODAL",
    payload,
  };
};

export const closeConfirmationModal = () => {
  return {
    type: "MAIN_CLOSE_CONFIRMATION_MODAL",
  };
};

export const setApplication = (application) => {
  return {
    type: "MAIN_SET_APPLICATION",
    payload: { application },
  };
};

export const setRoutes = (routes) => {
  return {
    type: "MAIN_SET_ROUTES",
    payload: { routes },
  };
};

export const fetchLocks = () => (dispatch) =>
  getHelper("locks", "MAIN_FETCH_LOCKS", dispatch);

export const refreshLocks = () => (dispatch) => {
  startRefreshHelper("MAIN_FETCH_LOCKS", dispatch);
  return axios
    .get("locks")
    .then((res) => {
      dispatch({
        type: "MAIN_FETCH_LOCKS",
        payload: {
          isFetching: false,
          isRefreshing: false,
          error: false,
          result: res.data.result,
        },
      });
    })
    .catch((err) => {
      let error = "";

      if (err.message) {
        error = err.message;
      } else {
        error = JSON.stringify(err);
      }

      dispatch({
        type: "MAIN_FETCH_LOCKS",
        payload: {
          error,
          isFetching: false,
          isRefreshing: false,
          result: [],
        },
      });
    });
};

export const fetchSearchFilters = () => (dispatch) =>
  getHelper("search/filters", "MAIN_FETCH_SEARCH_FILTER", dispatch);

export const setSearchFilters = (searchFilters) => {
  return {
    type: "MAIN_SET_SEARCH_FILTER",
    payload: searchFilters,
  };
};
