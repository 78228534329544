import React, { useContext } from "react";

import { DataViz, Table, TableLink } from "@commonComponents";

import {
  appointmentAnalysisFilterDimensions,
  panelStatsByAppointmentCategoryDrilldownQuery,
  panelStatsByAppointmentCategorySummaryQuery,
  assignedCareTeamPct90Days,
  assignedProviderPct90Days,
  assignedProviderPct30Days,
  assignedProviderPct7Days,
  assignedCareTeamPct7Days,
  assignedCareTeamPct30Days,
} from "./Queries";
import { PortalContext } from "../../Portal/Portal";
import Header from "~/src/CommonComponents/DataVisualization/Common/Header";
import { DASHBOARD_COLOR } from "~/src/CommonComponents/DataVisualization/Common/styles";
import { PROVIDER_360_URLS } from "~/src/urls";
import { parseToFixed } from "~/src/utils/stringUtils";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

const AppointmentAnalysisSummary = () => {
  const { filters, filterOrder } = useContext(PortalContext);

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: appointmentAnalysisFilterDimensions,
    nameMap: {
      enrich_state: "consumer_state",
      enrich_city: "consumer_city",
      enrich_zip: "consumer_zip",
    },
  });

  const {
    resultSet: recentAssignedCareTeamPct90Result,
    error: recentAssignedCareTeamPct90Error,
    isLoading: recentAssignedCareTeamPct90IsLoading,
  } = useCubeApiQuery(assignedCareTeamPct90Days(filters, filterOrder));

  const {
    resultSet: recentAssignedCareTeamPct30Result,
    error: recentAssignedCareTeamPct30Error,
    isLoading: recentAssignedCareTeamPct30IsLoading,
  } = useCubeApiQuery(assignedCareTeamPct30Days(filters, filterOrder));

  const {
    resultSet: recentAssignedCareTeamPct7Result,
    error: recentAssignedCareTeamPct7Error,
    isLoading: recentAssignedCareTeamPct7IsLoading,
  } = useCubeApiQuery(assignedCareTeamPct7Days(filters, filterOrder));

  const {
    resultSet: recentAssignedProviderPct90Result,
    error: recentAssignedProviderPct90Error,
    isLoading: recentAssignedProviderPct90IsLoading,
  } = useCubeApiQuery(assignedProviderPct90Days(filters, filterOrder));

  const {
    resultSet: recentAssignedProviderPct30Result,
    error: recentAssignedProviderPct30Error,
    isLoading: recentAssignedProviderPct30IsLoading,
  } = useCubeApiQuery(assignedProviderPct30Days(filters, filterOrder));

  const {
    resultSet: recentAssignedProviderPct7Result,
    error: recentAssignedProviderPct7Error,
    isLoading: recentAssignedProviderPct7IsLoading,
  } = useCubeApiQuery(assignedProviderPct7Days(filters, filterOrder));

  const {
    resultSet: panelStatsByAppointmentCategoryDrilldownResult,
    error: panelStatsByAppointmentCategoryDrilldownError,
    isLoading: panelStatsByAppointmentCategoryDrilldownIsLoading,
  } = useCubeApiQuery(
    panelStatsByAppointmentCategoryDrilldownQuery(filters, filterOrder),
  );

  const {
    resultSet: panelStatsByAppointmentCategorySummaryResult,
    error: panelStatsByAppointmentCategorySummaryError,
    isLoading: panelStatsByAppointmentCategorySummaryIsLoading,
  } = useCubeApiQuery(
    panelStatsByAppointmentCategorySummaryQuery(filters, filterOrder),
  );

  const getPanelStatsData = () => {
    const rawData =
      panelStatsByAppointmentCategorySummaryResult
        ?.rawData()
        .filter(
          (obj) => obj["assigned_consumer_appointments.attribution_panel_name"],
        ) ?? [];

    return [
      {
        name: "Assigned Visit % by Panel",
        color: DASHBOARD_COLOR,
        data: rawData.map((obj) => {
          const assignedVisitPct = parseFloat(
            parseFloat(
              obj[
                "assigned_consumer_appointments.assigned_provider_appointment_ratio_90_days"
              ],
            ).toFixed(2),
          );
          return {
            y: assignedVisitPct,
            name: obj["assigned_consumer_appointments.attribution_panel_name"],
            drilldown:
              obj["assigned_consumer_appointments.attribution_panel_name"],
            color: DASHBOARD_COLOR,
          };
        }),
      },
    ];
  };

  const getPanelStatsDrilldown = () => {
    const rawData =
      panelStatsByAppointmentCategoryDrilldownResult?.rawData() ?? [];

    const distinctPanelNames = [
      ...new Set(
        rawData.map(
          (item) =>
            item["assigned_consumer_appointments.attribution_panel_name"],
        ),
      ),
    ];
    const distinctVisitTypes = [
      ...new Set(
        rawData.map(
          (item) => item["assigned_consumer_appointments.appointment_category"],
        ),
      ),
    ];

    return distinctPanelNames.map((panelName) => {
      const res = {} as {
        id: string;
        name: string;
        data: [string, number][];
      };
      res.id = panelName;
      res.name = panelName;
      res.data = [];
      distinctVisitTypes.forEach((visitType) => {
        const row = rawData.find(
          (obj) =>
            obj["assigned_consumer_appointments.attribution_panel_name"] ===
              panelName &&
            obj["assigned_consumer_appointments.appointment_category"] ===
              visitType,
        ) ?? {
          "assigned_consumer_appointments.assigned_provider_appointment_ratio_90_days":
            "0.0",
        };
        res.data.push([
          visitType,
          parseFloat(
            parseFloat(
              row[
                "assigned_consumer_appointments.assigned_provider_appointment_ratio_90_days"
              ],
            ).toFixed(2),
          ),
        ]);
      });

      res.data.sort((a, b) => {
        if (isNaN(b[1])) {
          return -1;
        } else if (isNaN(a[1])) {
          return 1;
        }
        return b[1] - a[1];
      });

      return res;
    });
  };

  const getPanelStatsDataAndColumns = () => {
    const columns = [
      {
        Header: "Panel Name",
        id: "panelName",
        accessor: "assigned_consumer_appointments.attribution_panel_name",
        Cell: (props: { row: { original: Record<string, string> } }) => (
          <TableLink
            newTab
            url={`${PROVIDER_360_URLS.ATTRIBUTION_PANELS}/view/${props.row.original["panelId"]}`}
          >
            {props.row.original["panelName"]}
          </TableLink>
        ),
        sticky: "left",
      },
    ];

    const distinctVisitCategory = [
      ...new Set(
        panelStatsByAppointmentCategoryDrilldownResult
          ?.rawData()
          .map(
            (item) =>
              item["assigned_consumer_appointments.appointment_category"],
          ),
      ),
    ];

    distinctVisitCategory.forEach((visitType) => {
      columns.push({
        Header: visitType,
        accessor: visitType,
        id: visitType,
        Cell: (props) => (
          <p>
            {props.row.original[visitType as keyof typeof props.row.original]}%
          </p>
        ),
        sticky: "",
      });
    });

    const data =
      panelStatsByAppointmentCategoryDrilldownResult
        ?.rawData()
        .filter(
          (obj) => obj["assigned_consumer_appointments.attribution_panel_name"],
        ) ?? [];

    const distinctPanelName = data.reduce(
      (acc: { panelName: string; panelId: string }[], item) => {
        const panelName =
          item["assigned_consumer_appointments.attribution_panel_name"];
        const panelId =
          item["assigned_consumer_appointments.attribution_panel_id"];

        if (
          !acc.some(
            (obj: { panelName: string; panelId: string }) =>
              obj.panelName === panelName && obj.panelId === panelId,
          )
        ) {
          acc.push({ panelName, panelId });
        }

        return acc;
      },
      [],
    );

    const flatData = distinctPanelName.map(({ panelName, panelId }) => {
      const res = {} as {
        panelName: string;
        [key: string]: string | number;
      };
      res.panelName = panelName;
      distinctVisitCategory.forEach((visitType) => {
        const row = data.find(
          (obj) =>
            obj["assigned_consumer_appointments.attribution_panel_name"] ===
              panelName &&
            obj["assigned_consumer_appointments.appointment_category"] ===
              visitType,
        ) ?? {
          "assigned_consumer_appointments.assigned_provider_appointment_ratio_90_days":
            "0.0",
        };
        res[visitType] = parseToFixed({
          input:
            row[
              "assigned_consumer_appointments.assigned_provider_appointment_ratio_90_days"
            ],
          decimalPlaces: 2,
          invalidOutput: "0.00",
        });
        res.panelId = panelId;
      });

      return res;
    });

    return { columns, data: flatData };
  };

  return (
    <>
      <DataViz.Row>
        <DataViz.NumberChart
          loading={recentAssignedCareTeamPct90IsLoading}
          error={recentAssignedCareTeamPct90Error && "Something went wrong"}
          toolTip="Percentage of appointments with the assigned care team in the last 90 days"
          title="Appointment with Assigned Care Team"
          subTitle="For the last 90 days"
          numerator={parseFloat(
            parseFloat(
              recentAssignedCareTeamPct90Result?.rawData()[0][
                "assigned_consumer_appointments.assigned_care_team_appointment_ratio_90_days"
              ] ?? "",
            ).toFixed(2),
          )}
          unit="%"
          type="metric"
        />
        <DataViz.NumberChart
          loading={recentAssignedCareTeamPct30IsLoading}
          error={recentAssignedCareTeamPct30Error && "Something went wrong"}
          toolTip="Percentage of appointments with the assigned care team in the last 30 days"
          title="Appointment with Assigned Care Team"
          subTitle="For the last 30 days"
          numerator={parseFloat(
            parseFloat(
              recentAssignedCareTeamPct30Result?.rawData()[0][
                "assigned_consumer_appointments.assigned_care_team_appointment_ratio_30_days"
              ] ?? "",
            ).toFixed(2),
          )}
          unit="%"
          type="metric"
        />

        <DataViz.NumberChart
          loading={recentAssignedCareTeamPct7IsLoading}
          error={recentAssignedCareTeamPct7Error && "Something went wrong"}
          toolTip="Percentage of appointments with the assigned care team in the last 7 days"
          title="Appointment with Assigned Care Team"
          subTitle="For the last 7 days"
          numerator={parseFloat(
            parseFloat(
              recentAssignedCareTeamPct7Result?.rawData()[0][
                "assigned_consumer_appointments.assigned_care_team_appointment_ratio_7_days"
              ] ?? "",
            ).toFixed(2),
          )}
          unit="%"
          type="metric"
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.NumberChart
          loading={recentAssignedProviderPct90IsLoading}
          error={recentAssignedProviderPct90Error && "Something went wrong"}
          toolTip="Percentage of appointments with the assigned provider in the last 90 days"
          title="Appointment with Assigned Provider"
          subTitle="For the last 90 days"
          numerator={parseFloat(
            parseFloat(
              recentAssignedProviderPct90Result?.rawData()[0][
                "assigned_consumer_appointments.assigned_provider_appointment_ratio_90_days"
              ] ?? "",
            ).toFixed(2),
          )}
          unit="%"
          type="metric"
        />
        <DataViz.NumberChart
          loading={recentAssignedProviderPct30IsLoading}
          error={recentAssignedProviderPct30Error && "Something went wrong"}
          toolTip="Percentage of appointments with the assigned provider in the last 30 days"
          title="Appointment with Assigned Provider"
          subTitle="For the last 30 days"
          numerator={parseFloat(
            parseFloat(
              recentAssignedProviderPct30Result?.rawData()[0][
                "assigned_consumer_appointments.assigned_provider_appointment_ratio_30_days"
              ] ?? "",
            ).toFixed(2),
          )}
          unit="%"
          type="metric"
        />
        <DataViz.NumberChart
          loading={recentAssignedProviderPct7IsLoading}
          error={recentAssignedProviderPct7Error && "Something went wrong"}
          toolTip="Percentage of appointments with the assigned provider in the last 7 days"
          title="Appointment with Assigned Provider"
          subTitle="For the last 7 days"
          numerator={parseFloat(
            parseFloat(
              recentAssignedProviderPct7Result?.rawData()[0][
                "assigned_consumer_appointments.assigned_provider_appointment_ratio_7_days"
              ] ?? "",
            ).toFixed(2),
          )}
          unit="%"
          type="metric"
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.BarChart
          title="Assigned Panel Appointment Summary"
          yAxisLabel="Assigned Visit %"
          subtitle="Click on panel to see breakdown by visit category"
          data={getPanelStatsData()}
          drillDown={getPanelStatsDrilldown()}
          loading={
            panelStatsByAppointmentCategorySummaryIsLoading ||
            panelStatsByAppointmentCategoryDrilldownIsLoading
          }
          error={
            (panelStatsByAppointmentCategorySummaryError ||
              panelStatsByAppointmentCategoryDrilldownError) &&
            "Something went wrong"
          }
          highlightTooltipSuffix="%"
          toolTip="Percentage of appointments with assigned provider by panel for each visit category"
        />
      </DataViz.Row>
      <Header
        title="Panel Statistics"
        toolTip="Percentage of appointments with assigned provider by panel for each visit category"
        style={{ marginBottom: 0 }}
      />
      <Table
        isLoading={panelStatsByAppointmentCategoryDrilldownIsLoading}
        {...getPanelStatsDataAndColumns()}
        pageSize={10}
      />
    </>
  );
};

export default AppointmentAnalysisSummary;
