import type { ReactNode, CSSProperties } from "react";
import React from "react";

import styled from "styled-components";

type ContainerProps = {
  $inlineActions: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ $inlineActions }) =>
    $inlineActions ? "row-reverse" : "column"};
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const LeftActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const RightActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
`;

type FiltersProps = {
  children?: ReactNode;
};

function Filters({ children }: FiltersProps) {
  return <LeftActions>{children}</LeftActions>;
}

type ActionsProps = {
  inlineActions?: boolean;
  style?: CSSProperties;
  children?: ReactNode;
};

const Actions = ({ inlineActions = true, style, children }: ActionsProps) => {
  let filters: ReactNode = null;
  let actions: ReactNode | ReactNode[] = [];

  if (Array.isArray(children)) {
    children.forEach((child) => {
      if (React.isValidElement(child) && child.type === Filters) {
        filters = child;
      } else if (child) {
        actions = [...(Array.isArray(actions) ? actions : []), child];
      }
    });
  } else if (React.isValidElement(children) && children.type === Filters) {
    filters = children;
  } else if (children) {
    actions = children;
  }

  return (
    <Container style={style} $inlineActions={inlineActions}>
      <RightActions>{actions}</RightActions>
      {filters}
    </Container>
  );
};

Actions.Filters = Filters;

export default Actions;
