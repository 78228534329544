import { styled } from "styled-components";

import { gridSpacing, colorTheme } from "@utils";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${colorTheme("neutralL4")};
  padding: ${gridSpacing[4]}px;
  width: 100%;
  min-height: 147px;
`;

export const DASHBOARD_COLOR = "#1E90FF";
