import React from "react";

export const NoGroups = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    width="250"
    height="250"
    viewBox="0 0 250 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g filter="url(#filter0_i_3416_5347)">
      <rect
        x="29.1667"
        y="29"
        width="191.667"
        height="191.667"
        rx="95.8333"
        fill="#B1B5B7"
        fillOpacity="0.2"
      />
    </g>
    <g filter="url(#filter1_d_3416_5347)">
      <g clipPath="url(#clip0_3416_5347)">
        <rect
          x="40"
          y="79.8333"
          width="169"
          height="110"
          rx="5.42857"
          fill="white"
        />
        <circle cx="49.4997" cy="89.3334" r="2.71429" fill="#005B93" />
        <circle cx="57.6425" cy="89.3334" r="2.71429" fill="#CCCCCC" />
        <circle cx="65.7853" cy="89.3334" r="2.71429" fill="#23A6F9" />
        <line
          x1="40.0002"
          y1="98.4945"
          x2="230"
          y2="98.4945"
          stroke="#B2C4D7"
          strokeOpacity="0.3"
          strokeWidth="0.678571"
        />
      </g>
      <rect
        x="40.3393"
        y="80.1725"
        width="168.321"
        height="109.321"
        rx="5.08929"
        stroke="#B2C4D7"
        strokeWidth="0.678571"
      />
    </g>
    <g filter="url(#filter2_d_3416_5347)">
      <g clipPath="url(#clip1_3416_5347)">
        <rect
          x="25"
          y="54.8333"
          width="200"
          height="122"
          rx="6.74157"
          fill="white"
        />
        <line
          x1="25"
          y1="80.4119"
          x2="225"
          y2="80.4119"
          stroke="#B2C4D7"
          strokeOpacity="0.3"
          strokeWidth="0.842697"
        />
        <line
          x1="25"
          y1="112.412"
          x2="225"
          y2="112.412"
          stroke="#B2C4D7"
          strokeOpacity="0.3"
          strokeWidth="0.842697"
        />
        <line
          x1="25"
          y1="144.412"
          x2="225"
          y2="144.412"
          stroke="#B2C4D7"
          strokeOpacity="0.3"
          strokeWidth="0.842697"
        />
        <line
          x1="25"
          y1="176.412"
          x2="225"
          y2="176.412"
          stroke="#B2C4D7"
          strokeOpacity="0.3"
          strokeWidth="0.842697"
        />
        <rect x="35" y="64.8333" width="40" height="6" rx="3" fill="#DEDEDE" />
        <rect x="148" y="64.8333" width="28" height="6" rx="3" fill="#DEDEDE" />
        <rect x="148" y="93.8333" width="50" height="6" rx="3" fill="#ACACAC" />
        <rect x="71" y="93.8333" width="24" height="6" rx="3" fill="#ACACAC" />
        <rect x="97" y="93.8333" width="40" height="6" rx="3" fill="#ACACAC" />
        <rect
          x="35"
          y="86.8333"
          width="17.9995"
          height="18"
          rx="8.99975"
          fill="#EBEBEB"
        />
        <path
          // eslint-disable-next-line max-len
          d="M43.9998 104.833C48.9702 104.833 52.9995 100.803 52.9995 95.833C52.9995 90.8626 48.9702 86.8333 43.9998 86.8333C39.0293 86.8333 35 90.8626 35 95.833C35 100.803 39.0293 104.833 43.9998 104.833Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="148" y="125.833" width="32" height="6" rx="3" fill="#ACACAC" />
        <rect x="71" y="125.833" width="16" height="6" rx="3" fill="#ACACAC" />
        <rect x="89" y="125.833" width="32" height="6" rx="3" fill="#ACACAC" />
        <rect
          x="35"
          y="118.833"
          width="17.9995"
          height="18"
          rx="8.99975"
          fill="#EBEBEB"
        />
        <path
          // eslint-disable-next-line max-len
          d="M43.9998 136.833C48.9702 136.833 52.9995 132.803 52.9995 127.833C52.9995 122.863 48.9702 118.833 43.9998 118.833C39.0293 118.833 35 122.863 35 127.833C35 132.803 39.0293 136.833 43.9998 136.833Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="148" y="157.833" width="67" height="6" rx="3" fill="#ACACAC" />
        <rect x="71" y="157.833" width="24" height="6" rx="3" fill="#ACACAC" />
        <rect x="97" y="157.833" width="40" height="6" rx="3" fill="#ACACAC" />
        <rect
          x="35"
          y="150.833"
          width="17.9995"
          height="18"
          rx="8.99975"
          fill="#EBEBEB"
        />
        <path
          // eslint-disable-next-line max-len
          d="M43.9998 168.833C48.9702 168.833 52.9995 164.803 52.9995 159.833C52.9995 154.863 48.9702 150.833 43.9998 150.833C39.0293 150.833 35 154.863 35 159.833C35 164.803 39.0293 168.833 43.9998 168.833Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect
        x="25.4213"
        y="55.2546"
        width="199.157"
        height="121.157"
        rx="6.32022"
        stroke="#B2C4D7"
        strokeWidth="0.842697"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_3416_5347"
        x="29.1667"
        y="29"
        width="191.667"
        height="191.667"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="8.33333" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_3416_5347"
        />
      </filter>
      <filter
        id="filter1_d_3416_5347"
        x="33.2143"
        y="78.4761"
        width="182.571"
        height="123.571"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.42857" />
        <feGaussianBlur stdDeviation="3.39286" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3416_5347"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3416_5347"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_3416_5347"
        x="18.2143"
        y="53.4761"
        width="213.571"
        height="135.571"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.42857" />
        <feGaussianBlur stdDeviation="3.39286" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3416_5347"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3416_5347"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_3416_5347">
        <rect
          x="40"
          y="79.8333"
          width="169"
          height="110"
          rx="5.42857"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_3416_5347">
        <rect
          x="25"
          y="54.8333"
          width="200"
          height="122"
          rx="6.74157"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
