import React, { useState, useRef } from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import type { MarginInterface } from "../utilities/interfaces";
import { getMargins } from "../utilities/margin";

const InputContainer = styled.div<{
  $focused: boolean;
  $hasValue: boolean;
  $fixedWidth: boolean;
  $disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  height: 38px;
  max-height: 38px;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "text")};
  padding-left: 5px;
  background-color: ${({ $disabled }) =>
    $disabled ? colorTheme("neutralL4") : colorTheme("white")};
  border-color: ${({ $focused }) =>
    $focused ? colorTheme("primary") : colorTheme("neutralL3")};
  width: ${({ $focused, $hasValue, $fixedWidth }) =>
    $focused || $hasValue || $fixedWidth ? "200" : "85"}px;
  transition: width 0.35s;

  i {
    font-size: 13px;
    padding-right: 8px;
    color: ${({ $focused }) =>
      $focused ? colorTheme("primary") : colorTheme("neutralL1")};
    font-weight: ${({ $focused }) => ($focused ? "600" : "400")};
  }
`;

const TextInput = styled.input`
  width: 100%;
  outline: 0;
  border: 0;
  font-size: 16px;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  border-left: 1px solid #c5cacf;
  padding: 0 12px;
  background: #fff;
  color: ${colorTheme("neutralL3")};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;

  i {
    color: ${colorTheme("neutralL1")};
    padding: 0;
  }

  &:hover {
    background: ${colorTheme("neutralL5")};
  }

  &:active {
    background: ${colorTheme("neutralL4")};
  }
`;

interface SearchTextProps extends MarginInterface {
  style?: React.CSSProperties;
  onChange: (e: { target: { value: string } }) => void;
  value: string;
  onEnter?: () => void;
  searchButton?: boolean;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  label?: string;
  fixedWidth?: boolean;
  testId?: string;
  placeholder?: string;
  disabled?: boolean;
}

const SearchText = ({
  style,
  onChange,
  value,
  onEnter,
  searchButton,
  onKeyPress,
  label,
  fixedWidth = false,
  testId,
  placeholder,
  disabled,
  m,
  mx,
  my,
  mt,
  mb,
  mr,
  ml,
}: SearchTextProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  // Setup default margins
  if (mr === true) {
    mr = 2;
  }

  if (ml === true) {
    ml = 2;
  }

  style = { ...getMargins({ m, mx, my, mt, mb, mr, ml }), ...style };

  const renderButtonOrIcon = () => {
    if (isFocused && searchButton) {
      return (
        <Button
          onMouseDown={(e) => {
            e.preventDefault();
            onEnter && onEnter();
            onBlur();
            if (inputRef.current) {
              inputRef.current.blur();
            }
          }}
        >
          <i className="far fa-search" />
        </Button>
      );
    }

    if (value?.length > 0) {
      return (
        <i
          className="fa-regular fa-times"
          style={{ cursor: "pointer" }}
          onClick={() => onChange({ target: { value: "" } })}
        />
      );
    } else {
      return <i className="far fa-search" />;
    }
  };

  return (
    <>
      {label && <label>{label}</label>}
      <InputContainer
        style={style}
        $focused={isFocused}
        $hasValue={!!value}
        $fixedWidth={fixedWidth}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        $disabled={disabled}
      >
        <TextInput
          id="search"
          onChange={onChange}
          value={value}
          placeholder={placeholder || "Search"}
          ref={inputRef}
          disabled={disabled}
          onKeyDown={(e) => {
            onKeyPress && onKeyPress(e);
            if ((e.key === "Enter" || e.code === "NumpadEnter") && onEnter) {
              onEnter();
              onBlur();
              if (inputRef.current) {
                inputRef.current.blur();
              }
            }
          }}
          data-testid={testId}
        />
        {renderButtonOrIcon()}
      </InputContainer>
    </>
  );
};

export default SearchText;
