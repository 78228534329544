import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import gridSpacing from "../../utils/gridSpacing";
import type { MarginInterface } from "../utilities/interfaces";
import { getMargins } from "../utilities/margin";

export const Status = styled.div<{ $small: boolean; $clickable: boolean }>`
  padding: 0 10px;
  border-radius: 15px;
  font-size: ${({ $small }) => ($small ? "12px" : "14px")};
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  height: ${({ $small }) => ($small ? "24px" : "27px")};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  white-space: nowrap;

  ${({ $clickable }) =>
    $clickable
      ? `
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  `
      : ""};
`;

const ContentContainer = styled.div<{ $iconPos: "left" | "right" }>`
  display: flex;
  flex-direction: ${({ $iconPos }) =>
    $iconPos === "left" ? "row" : "row-reverse"};
  align-items: center;
  height: 100%;
`;
const Icon = styled.div<{ $iconPos: "left" | "right" }>`
  margin-right: ${({ $iconPos }) =>
    $iconPos === "left" ? gridSpacing[2] : 0}px;
  margin-left: ${({ $iconPos }) =>
    $iconPos === "left" ? 0 : gridSpacing[2]}px;
`;

const colorMapping = {
  default: {
    color: "neutralL1",
    background: "neutralL4",
    border: "transparent",
  },
  primary: {
    color: "info",
    background: "infoL3",
    border: "transparent",
  },
  success: {
    color: "success",
    background: "successL5",
    border: "transparent",
  },
  warning: {
    color: "warning",
    background: "warningL5",
    border: "transparent",
  },
  danger: {
    color: "danger",
    background: "dangerL5",
    border: "transparent",
  },
  discovery: {
    color: "discovery",
    background: "discoveryL4",
    border: "transparent",
  },
  neutral: {
    color: "neutral",
    background: "white",
    border: "neutralL4",
  },
};

interface StatusPillProps extends MarginInterface {
  text: string;
  icon?: string;
  type?: keyof typeof colorMapping;
  style?: React.CSSProperties;
  onClick?: () => void;
  clickable?: boolean; // This implements the styling for a clickable status pill
  /** data-testid attribute */
  testId?: string;
  uppercase?: boolean;
  capitalize?: boolean;
  cleanText?: boolean;
  small?: boolean;
  iconPos?: "left" | "right";
  iconStyle?: object;
}

function StatusPill({
  text,
  icon,
  type = "primary",
  style,
  onClick,
  clickable = false,
  testId,
  uppercase,
  capitalize,
  cleanText,
  small = false,
  m,
  mx,
  my,
  mt,
  mb,
  mr,
  ml,
  iconPos = "left",
  iconStyle,
}: StatusPillProps) {
  style = { ...getMargins({ m, mx, my, mt, mb, mr, ml }), ...style };

  const _colorMapping = colorMapping[type] || colorMapping.primary;

  const getTransformText = () => {
    if (uppercase) {
      return "uppercase";
    } else if (capitalize) {
      return "capitalize";
    }
    return "none";
  };

  const capitalizeFirstLetters = (input: string) => {
    if (input) {
      return input
        .replaceAll("_", " ")
        .toLowerCase()
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    }
    return "";
  };

  return (
    <div>
      <Status
        $clickable={clickable || !!onClick}
        $small={small}
        style={{
          color: colorTheme(_colorMapping.color),
          background: colorTheme(_colorMapping.background),
          border: `1px solid ${colorTheme(_colorMapping.border)}`,
          textTransform: getTransformText(),
          ...style,
        }}
        onClick={onClick}
        data-testid={testId}
      >
        <ContentContainer $iconPos={iconPos}>
          {icon && (
            <Icon
              style={iconStyle}
              $iconPos={iconPos}
              className={`fa-solid ${icon}`}
            />
          )}
          {cleanText ? capitalizeFirstLetters(text) : text}
        </ContentContainer>
      </Status>
    </div>
  );
}

export default StatusPill;
