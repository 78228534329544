import React, { useState } from "react";

import { Collapse, TableActions } from "@commonComponents";
import PropTypes from "prop-types";
import { styled } from "styled-components";

import { colorTheme } from "@utils";
// TODO: This is not being used anywhere

const DataCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid ${colorTheme("neutralL1")};
  background: ${colorTheme("white")};
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DataCardContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;

  &:hover {
    background: ${colorTheme("neutralL4")};
  }
`;

const DataCardObjectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataCardText = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 35px;
  cursor: pointer;
  min-width: 0px;
  width: 0px;

  div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const DataCard = ({ title, children, cardActions }) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <DataCardContainer>
      <DataCardContainerHeader>
        <i
          onClick={() => setIsOpened(!isOpened)}
          className={`fa-solid fa-chevron-${isOpened ? "down" : "right"}`}
          style={{
            marginLeft: 10,
            marginRight: 10,
            flexBasis: 28,
          }}
        />
        <DataCardText onClick={() => setIsOpened(!isOpened)}>
          <div>{title}</div>
        </DataCardText>
        {cardActions && <TableActions direction="left" items={cardActions} />}
      </DataCardContainerHeader>
      <Collapse isOpened={isOpened}>
        <DataCardObjectsWrapper>{children}</DataCardObjectsWrapper>
      </Collapse>
    </DataCardContainer>
  );
};

DataCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  cardActions: PropTypes.array,
};

export default DataCard;
