import { useState, useEffect } from "react";

import axios from "axios";
import { styled } from "styled-components";

import { checkPermissions, colorTheme, gridSpacing } from "@utils";

import type {
  ListAnalyticDomain,
  ListInsightsDashboard,
  SubDomain,
  UserFavorites,
} from "~/src/types";

export const DashboardContainer = styled.div<{ $smallCard?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ $smallCard }) => !$smallCard && "412px"};
  max-width: ${({ $smallCard }) => !$smallCard && "412px"};
  height: ${({ $smallCard }) => ($smallCard ? 136 : 276)}px;
  max-height: ${({ $smallCard }) => ($smallCard ? 136 : 276)}px;
  border: 1px solid ${colorTheme("neutralL5")};
  border-radius: 8px;
  padding: ${gridSpacing[5]}px ${gridSpacing[6]}px ${gridSpacing[5]}px
    ${gridSpacing[5]}px;
  position: relative;
  overflow: hidden;
  margin-left: 2px; // Give space for the shadow to show up
  background-color: ${colorTheme("white")};

  // Must be defined here because we want icons to display if the card is hovered
  &:hover .icons {
    display: flex;
  }

  box-shadow:
    -2px 4px 6px -1px rgba(0, 0, 0, 0.1),
    2px 4px 6px -1px rgba(0, 0, 0, 0.1);

  h4 {
    color: ${colorTheme("neutral")};
  }

  p {
    font-size: 14px;
    color: ${colorTheme("neutralL1")};
  }

  &:hover {
    cursor: pointer;
    background: ${colorTheme("infoL4")};
  }
`;

export const DisabledDashboardContainer = styled(DashboardContainer)`
  background: ${colorTheme("neutralL6")};

  &:hover {
    cursor: not-allowed;
    background: ${colorTheme("neutralL6")};
  }
`;

export const DashboardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${gridSpacing[2]}px;
  height: 28px;
`;

export enum DashboardType {
  Lucerna = "lucerna",
  Customer = "customer",
  Quicksight = "quicksight",
}

export type QuicksightProps = {
  name: string;
  path: string;
  dashboard_type: string;
  uuid: string;
  short_description: string;
  embedded_details: {
    lucerna_managed: boolean;
    dashboard_uuid: string | null;
  };
  managed_by: string;
};

export type DocumentLinkProps = {
  name: string;
  url: string;
  summary: string;
  uuid: string;
};

export type DashboardDataProps = {
  analytic_domain: string | null;
  subdomain: string | null;
  name: string;
  short_description: string;
  identifier: string;
  description: string;
  dashboard_type: string;
  embedded_details: {
    dashboard_uuid: string | null;
    original_name: string;
    contains_phi: boolean;
    information: string;
    shared_folder_permissions: string[];
    labels: string[];
    support_contact: {
      name: string;
      title: string;
      phone: string;
      email: string;
    };
    documentation_links: DocumentLinkProps[];
    lucerna_managed: boolean;
  };
};

export const checkGroupPermission = (subjectPath: string, groupPath: string) =>
  checkPermissions(`quicksight.view_${subjectPath}/${groupPath}`);

export const Description = styled.div`
  margin-left: 26px; // Line up with header text
  color: ${colorTheme("neutralL1")};
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export const Icon = styled.i`
  color: ${colorTheme("neutralL2")};

  &:hover {
    cursor: pointer;
    color: ${colorTheme("neutralL1")};
  }
`;

export type InsightsDashboardsProps = {
  loading: boolean;
  error: boolean;
  data: ListInsightsDashboard[] | null;
};

export type SubjectsProps = {
  loading: boolean;
  error: boolean;
  data: ListAnalyticDomain[] | null;
};

export type FavoritesProps = {
  loading: boolean;
  error: boolean;
  data: UserFavorites[];
};

export type QuicksightDashboardProps = {
  loading: boolean;
  error: boolean;
  data: QuicksightProps[];
};

export type GroupProps = {
  path: string;
  created: string;
} & SubDomain;

export const useFavorites = (objectTypes: string[]) => {
  const [favorites, setFavorites] = useState<FavoritesProps>({
    loading: true,
    error: false,
    data: [],
  });

  const getFavorites = () => {
    const params = new URLSearchParams();
    objectTypes.forEach((objectType) => {
      params.append("object_type", objectType);
    });
    params.append("page_size", "0");
    params.append("ordering", "created");

    axios
      .get(`/favorites/favorites?${params.toString()}`)
      .then((res) => {
        setFavorites({
          loading: false,
          error: false,
          data: res.data.result.results.sort(
            (a: { created: string }, b: { created: string }) =>
              new Date(b.created).getTime() - new Date(a.created).getTime(),
          ),
        });
      })
      .catch((err) => {
        console.error(err);
        setFavorites({
          loading: false,
          error: true,
          data: [],
        });
      });
  };

  useEffect(() => {
    getFavorites();
  }, []);

  return { ...favorites, getFavorites };
};

export enum GroupBy {
  ungroup = "ungroup",
  analyticDomain = "analyticDomain",
  subdomain = "subdomain",
}
