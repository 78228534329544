import React, { useState } from "react";

import { updateUserMeta } from "@actions";
import { GraphicCallout, Loading, Table } from "@commonComponents";
import { connect } from "react-redux";

import { gridSpacing } from "@utils";

import GroupedObjects from "./GroupedObjects";
import Header from "./Header";
import type { FilterProps } from "./helpers";
import { ContentWrapper, ObjectsContainer } from "./helpers";
import DashboardObject from "../DashboardObject";
import { GroupBy } from "../helpers";
import { dashboardColumns } from "../tableColumns";
import type {
  ListAnalyticDomain,
  ListInsightsDashboard,
  UserFavorites,
} from "~/src/types";
import { includesIgnoreCase } from "~/src/utils/tsUtils";

interface AllPublishedDashboardsTabProps {
  filters: FilterProps;
  setFilters: (filters: FilterProps) => void;
  tableView: boolean;
  setTableView: (value: boolean) => void;
  insightsDashboards: ListInsightsDashboard[];
  insightsDashboardsLoading: boolean;
  updateUserMeta: (key: string, value: object) => void;
  publishedDashboardsSubjectsClosed: string[];
  publishedDashboardsSubdomainsClosed: string[];
  favoritesData: UserFavorites[];
  getFavorites: () => void;
  subjects: ListAnalyticDomain[];
  setSelectedDashboard: (dashboard: ListInsightsDashboard) => void;
  leapInsightsGroupBy: string;
  customerName: string;
}

const AllPublishedDashboardsTab = ({
  filters,
  setFilters,
  tableView,
  setTableView,
  insightsDashboards,
  insightsDashboardsLoading,
  updateUserMeta,
  publishedDashboardsSubjectsClosed,
  publishedDashboardsSubdomainsClosed,
  favoritesData,
  getFavorites,
  subjects,
  setSelectedDashboard,
  leapInsightsGroupBy,
  customerName,
}: AllPublishedDashboardsTabProps) => {
  const [subjectsClosed, setSubjectsClosed] = useState<string[]>(
    publishedDashboardsSubjectsClosed ?? [],
  );
  const [subdomainsClosed, setSubDomainsClosed] = useState<string[]>(
    publishedDashboardsSubdomainsClosed ?? [],
  );
  const [groupBy, setGroupBy] = useState(
    leapInsightsGroupBy || GroupBy.ungroup,
  );

  let allDashboards: ListInsightsDashboard[] = [];

  subjects.forEach((subject) => {
    subject.subdomains.forEach((subdomain) => {
      const subdomainDashboards = insightsDashboards.filter(
        (d) => d.subdomain.uuid === subdomain.uuid,
      );

      if (subdomainDashboards) {
        allDashboards.push(...subdomainDashboards);
      }
    });
  });

  const filteredSubjects = subjects.filter((s) => {
    const hasTypeFilter =
      filters.type.length === 0 ||
      insightsDashboards.some(
        (d) =>
          d.type === filters.type &&
          d.subdomain.analytic_domain.uuid === s.uuid,
      );

    const hasManagedByFilter =
      filters.managed_by.length === 0 ||
      insightsDashboards.some(
        (d) =>
          d.managed_by === filters.managed_by &&
          d.subdomain.analytic_domain.uuid === s.uuid,
      );

    const hasSearchFilter =
      !filters.search ||
      insightsDashboards.some(
        (d) =>
          includesIgnoreCase(d.name, filters.search) &&
          d.subdomain.analytic_domain.uuid === s.uuid,
      );

    const hasAnalyticDomainFilter =
      !filters.analytic_domain || s.name === filters.analytic_domain;

    const hasSubdomainFilter =
      filters.subdomain.length === 0 ||
      s.subdomains.some((sub) => filters.subdomain.includes(sub.name));

    return (
      hasTypeFilter &&
      hasSearchFilter &&
      hasAnalyticDomainFilter &&
      hasSubdomainFilter &&
      hasManagedByFilter
    );
  });

  allDashboards = allDashboards.filter(
    ({ name, subdomain, managed_by, type }) =>
      includesIgnoreCase(name, filters.search) &&
      (!filters.managed_by || filters.managed_by === managed_by) &&
      (!filters.type || filters.type === type) &&
      (!filters.analytic_domain ||
        subdomain.analytic_domain.name === filters.analytic_domain) &&
      (filters.subdomain?.length === 0 ||
        (subdomain.name && filters.subdomain?.includes(subdomain.name))),
  );

  const renderDashboards = (
    dashboards: ListInsightsDashboard[],
    hasPermission: boolean = true,
  ) => {
    if (!hasPermission) {
      return (
        <ObjectsContainer style={{ marginLeft: gridSpacing[3] }}>
          <p
            style={{
              marginTop: gridSpacing[3],
              marginLeft: gridSpacing[5],
            }}
          >
            You do not have permission to view this subdomain
          </p>
        </ObjectsContainer>
      );
    }

    if (dashboards.length === 0) {
      return (
        <ObjectsContainer>
          <p
            style={{
              marginTop: gridSpacing[3],
              fontWeight: 400,
            }}
          >
            No dashboards available
          </p>
        </ObjectsContainer>
      );
    }

    if (tableView) {
      return (
        <Table
          columns={dashboardColumns(
            setSelectedDashboard,
            favoritesData,
            getFavorites,
            customerName,
          )}
          data={dashboards}
        />
      );
    }

    return (
      <ObjectsContainer>
        {dashboards.map((_dashboard: ListInsightsDashboard) => (
          <DashboardObject
            key={_dashboard.identifier}
            dashboard={_dashboard}
            setSelectedDashboard={setSelectedDashboard}
            favorites={favoritesData}
            getFavorites={getFavorites}
          />
        ))}
      </ObjectsContainer>
    );
  };

  const handleCollapseAll = () => {
    if (groupBy === GroupBy.analyticDomain) {
      const _subjects = subjects.map((subject) => subject.uuid);
      setSubjectsClosed(_subjects);
      updateUserMeta("publishedDashboardsSubjectClosed", _subjects);
    } else {
      const _subdomains = subjects.flatMap((subject) =>
        subject.subdomains.map((subdomain) => subdomain.uuid),
      );
      setSubDomainsClosed(_subdomains);
      updateUserMeta("publishedDashboardsSubdomainsClosed", _subdomains);
    }
  };

  const handleExpandAll = () => {
    if (groupBy === GroupBy.analyticDomain) {
      setSubjectsClosed([]);
      updateUserMeta("publishedDashboardsSubjectsClosed", []);
    } else {
      setSubDomainsClosed([]);
      updateUserMeta("publishedDashboardsSubdomainsClosed", []);
    }
  };

  const renderGroupedObjects = () => {
    if (groupBy === GroupBy.ungroup) {
      return renderDashboards(allDashboards, true);
    } else if (groupBy === GroupBy.analyticDomain) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {filteredSubjects.map((subject) => {
            const isCollapsed = subjectsClosed.includes(subject.uuid);
            const toggleGroup = () => {
              if (isCollapsed) {
                const _subjects = subjectsClosed.filter(
                  (g) => g !== subject.uuid,
                );
                setSubjectsClosed(_subjects);
                updateUserMeta("publishedDashboardsSubjectsClosed", _subjects);
              } else {
                const _subjects = [...subjectsClosed, subject.uuid];
                setSubjectsClosed(_subjects);
                updateUserMeta("publishedDashboardsSubjectsClosed", _subjects);
              }
            };

            const subjectDashboards = allDashboards.filter(
              (d) => d.subdomain.analytic_domain.uuid === subject.uuid,
            );

            return (
              <GroupedObjects
                key={subject.uuid}
                isCollapsed={isCollapsed}
                toggleGroup={toggleGroup}
                icon={subject.icon}
                title={subject.name}
                childrenCount={subjectDashboards.length}
                description={subject.short_description}
                content={renderDashboards(subjectDashboards)}
              />
            );
          })}
        </div>
      );
    } else {
      const subdomains = subjects.flatMap((subject) =>
        subject.subdomains.map((subdomain) => {
          return {
            ...subdomain,
            analytic_domain: subject,
          };
        }),
      );

      const filteredSubdomains = subdomains.filter((subdomain) => {
        const hasTypeFilter =
          filters.type.length === 0 ||
          allDashboards.some(
            (d) =>
              d.type === filters.type && d.subdomain.uuid === subdomain.uuid,
          );

        const hasManagedByFilter =
          filters.managed_by.length === 0 ||
          allDashboards.some(
            (d) =>
              d.managed_by === filters.managed_by &&
              d.subdomain.uuid === subdomain.uuid,
          );

        const hasSearchFilter =
          !filters.search ||
          insightsDashboards.some(
            (d) =>
              includesIgnoreCase(d.name, filters.search) &&
              d.subdomain.uuid === subdomain.uuid,
          );

        const hasAnalyticDomainFilter =
          !filters.analytic_domain ||
          subdomain.analytic_domain.name === filters.analytic_domain;

        const hasSubdomainFilter =
          filters.subdomain.length === 0 ||
          filters.subdomain.includes(subdomain.name);

        return (
          hasSearchFilter &&
          hasAnalyticDomainFilter &&
          hasSubdomainFilter &&
          hasTypeFilter &&
          hasManagedByFilter
        );
      });

      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {filteredSubdomains.map((subdomain) => {
            const isCollapsed = subdomainsClosed.includes(subdomain.uuid);
            const toggleGroup = () => {
              if (isCollapsed) {
                const _subdomains = subdomainsClosed.filter(
                  (g) => g !== subdomain.uuid,
                );
                setSubDomainsClosed(_subdomains);
                updateUserMeta(
                  "publishedDashboardsSubdomainsClosed",
                  _subdomains,
                );
              } else {
                const _subdomains = [...subdomainsClosed, subdomain.uuid];
                setSubDomainsClosed(_subdomains);
                updateUserMeta(
                  "publishedDashboardsSubdomainsClosed",
                  _subdomains,
                );
              }
            };

            const subdomainDashboards = allDashboards.filter(
              (d) => d.subdomain.uuid === subdomain.uuid,
            );

            return (
              <GroupedObjects
                key={subdomain.uuid}
                isCollapsed={isCollapsed}
                toggleGroup={toggleGroup}
                icon={subdomain.analytic_domain.icon}
                title={`${subdomain.analytic_domain.name} / ${subdomain.name}`}
                childrenCount={subdomainDashboards.length}
                description={subdomain.short_description}
                content={renderDashboards(subdomainDashboards)}
              />
            );
          })}
        </div>
      );
    }
  };

  if (insightsDashboardsLoading) {
    return <Loading center />;
  }

  return (
    <>
      <Header
        filters={filters}
        setFilters={setFilters}
        tableView={tableView}
        setTableView={setTableView}
        subjects={subjects}
        tab="all-published-dashboards"
        handleExpandAll={handleExpandAll}
        handleCollapseAll={handleCollapseAll}
        groupBy={groupBy}
        setGroupBy={setGroupBy}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: gridSpacing[4],
          marginLeft: -gridSpacing[4], // to align with the content wrapper
        }}
      />
      {allDashboards.length === 0 ? (
        <GraphicCallout empty="search" />
      ) : (
        // 345px is the header and filters
        <ContentWrapper style={{ height: "calc(100vh - 345px" }}>
          {renderGroupedObjects()}
        </ContentWrapper>
      )}
    </>
  );
};

const mapStateToProps = (state: {
  main: {
    user: {
      metadata: {
        publishedDashboardsSubjectsClosed: string[];
        publishedDashboardsSubdomainsClosed: string[];
        leapInsightsGroupBy: string;
      };
    };
  };
  SystemManagement: {
    management: {
      general: {
        result: {
          name: string;
        };
      };
    };
  };
}) => {
  return {
    publishedDashboardsSubjectsClosed:
      state.main.user.metadata?.publishedDashboardsSubjectsClosed,
    publishedDashboardsSubdomainsClosed:
      state.main.user.metadata?.publishedDashboardsSubdomainsClosed,
    leapInsightsGroupBy: state.main.user.metadata?.leapInsightsGroupBy,
    customerName:
      state.SystemManagement?.management?.general?.result?.name || "Customer",
  };
};

export default connect(mapStateToProps, {
  updateUserMeta,
})(AllPublishedDashboardsTab);
