import type { CSSProperties, ElementType, MouseEvent, ReactNode } from "react";
import React, { Children, isValidElement, useEffect } from "react";

import FocusTrap from "focus-trap-react";
import ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components";

import { colorTheme } from "@utils";

import Loading from "../src/CommonComponents/Loading/Loading";

const FadeIn = keyframes`
0% {opacity:0;}
100% {opacity:1;}
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  z-index: 107;
  width: 100vw;
  height: 100%;
  background: #000;
  opacity: 0.5;
`;

const ModalContainer = styled.div`
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  z-index: 108;
  animation: ${FadeIn} ease 0.75s;
`;

const InnerModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - 60px);
  pointer-events: auto;
  background-color: ${colorTheme("white")};
  background-clip: padding-box;
  border: 1px solid ${colorTheme("neutral")};
  border-radius: 0.3rem;
  outline: 0;
  padding: 15px;
  margin: 1.75rem auto;
  overflow: visible; // Overflow must be visible so select boxes are not cut off

  @media (min-width: 576px) {
    max-width: 500px;
  }

  @media (min-width: 875px) {
    max-width: 800px;
  }
`;

const ChildrenContainer = styled.div``;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 300;
  line-height: 32px;
  padding-bottom: 10px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 15px;
`;

const CloseIcon = styled.div`
  font-size: 18px;
  padding-right: 5px;

  &:hover {
    cursor: pointer;
    color: ${colorTheme("neutralL1")};
  }
`;

const ModalWrapperDiv = styled.div``;

interface FooterProps {
  children: ReactNode;
  style?: CSSProperties;
}
const Footer = ({ children, style }: FooterProps) => (
  <FooterContainer style={style}>{children}</FooterContainer>
);

interface ModalProps {
  children: ReactNode;
  title?: string;
  style?: CSSProperties;
  toggle: () => void;
  isOpened: boolean;
  closeShortcuts?: boolean;
  focusTrap?: boolean;
  isLoading?: boolean;
  loadingText?: string;
}

const Modal = ({
  children,
  title,
  style,
  toggle,
  isOpened,
  closeShortcuts = false,
  focusTrap = true,
  isLoading,
  loadingText = "",
}: ModalProps) => {
  if (!isOpened) return null;
  const ModalWrapper = (focusTrap ? FocusTrap : ModalWrapperDiv) as ElementType;

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.keyCode === 27 && closeShortcuts) toggle();
    };

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const nonFilterChildren = Children.toArray(children).filter(
    (child) => isValidElement(child) && child?.type !== Footer,
  );

  const footer = Children.toArray(children).find(
    (child) => isValidElement(child) && child?.type === Footer,
  );

  const _Modal = (
    <>
      <ModalBackground id="modalBackground" />
      <ModalWrapper>
        <ModalContainer
          id="modalContainer"
          onClick={(e: MouseEvent<HTMLDivElement>) => {
            if (
              (e.target as HTMLDivElement).id === "modalContainer" &&
              closeShortcuts
            )
              toggle();
          }}
        >
          <InnerModal style={style}>
            {title && (
              <ModalTitle>
                {title}
                {toggle && (
                  <CloseIcon className="fa-regular fa-times" onClick={toggle} />
                )}
              </ModalTitle>
            )}
            {isLoading ? (
              <>
                {loadingText && <p>{loadingText}</p>}
                <Loading center />
              </>
            ) : (
              <ChildrenContainer>{nonFilterChildren}</ChildrenContainer>
            )}
            {footer && footer}
          </InnerModal>
        </ModalContainer>
      </ModalWrapper>
    </>
  );

  return ReactDOM.createPortal(_Modal, document.querySelector("body")!);
};

Modal.Footer = Footer;

export default Modal;
