import React, { useContext } from "react";

import { DataViz, Table } from "@commonComponents";
import moment from "moment";

import { numberWithCommas } from "@utils";

import {
  uniqueNumberOfUsersQuery,
  uniqueNumberOfUsersLastThirtyQuery,
  averageViewsPerUserQuery,
  uniqueNumberOfGroupsQuery,
  uniqueNumberOfGroupsLastThirtyQuery,
  usersTrendQuery,
  viewsPerUserGroupQuery,
} from "./Queries";
import { PortalContext } from "../../Portal/Portal";
import {
  roundToTwoDecimals,
  userFilterDimensions,
  groupFilterDimensions,
  viewActivityFilterDimensions,
} from "../Common/userAnalyticsCommon";
import Header from "~/src/CommonComponents/DataVisualization/Common/Header";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

const UserGroupAnalysis = () => {
  const { filters, filterOrder } = useContext(PortalContext);
  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: [
      ...viewActivityFilterDimensions,
      ...userFilterDimensions,
      ...groupFilterDimensions,
    ],
  });
  const {
    resultSet: uniqueNumberOfUsersResult,
    error: uniqueNumberOfUsersError,
    isLoading: uniqueNumberOfUsersLoading,
  } = useCubeApiQuery(uniqueNumberOfUsersQuery(filters, filterOrder));

  const {
    resultSet: uniqueNumberOfUsersLastThirtyResult,
    error: uniqueNumberOfUsersLastThirtyError,
    isLoading: uniqueNumberOfUsersLastThirtyLoading,
  } = useCubeApiQuery(uniqueNumberOfUsersLastThirtyQuery(filters, filterOrder));

  const {
    resultSet: averageViewsPerUserResult,
    error: averageViewsPerUserError,
    isLoading: averageViewsPerUserLoading,
  } = useCubeApiQuery(averageViewsPerUserQuery(filters, filterOrder));

  const {
    resultSet: uniqueNumberOfGroupsResult,
    error: uniqueNumberOfGroupsError,
    isLoading: uniqueNumberOfGroupsLoading,
  } = useCubeApiQuery(uniqueNumberOfGroupsQuery(filters, filterOrder));

  const {
    resultSet: uniqueNumberOfGroupsLastThirtyResult,
    error: uniqueNumberOfGroupsLastThirtyError,
    isLoading: uniqueNumberOfGroupsLastThirtyLoading,
  } = useCubeApiQuery(
    uniqueNumberOfGroupsLastThirtyQuery(filters, filterOrder),
  );

  const {
    resultSet: usersTrendResult,
    error: usersTrendError,
    isLoading: usersTrendLoading,
  } = useCubeApiQuery(usersTrendQuery(filters, filterOrder));

  const {
    resultSet: viewsPerUserGroupResult,
    isLoading: viewsPerUserGroupLoading,
  } = useCubeApiQuery(viewsPerUserGroupQuery(filters, filterOrder));

  const uniqueNumberOfUsersResultData = uniqueNumberOfUsersResult?.rawData()[0];
  const uniqueNumberOfUsersLastThirtyResultData =
    uniqueNumberOfUsersLastThirtyResult?.rawData()[0];

  const uniqueNumberOfGroupsResultData =
    uniqueNumberOfGroupsResult?.rawData()[0];
  const uniqueNumberOfGroupsLastThirtyResultData =
    uniqueNumberOfGroupsLastThirtyResult?.rawData()[0];

  const uniqueNumberOfUsersTrendPercentage = roundToTwoDecimals(
    (parseFloat(uniqueNumberOfUsersResultData?.["user.total_users"] ?? "") /
      parseFloat(
        uniqueNumberOfUsersLastThirtyResultData?.["user.total_users"] ?? "1",
      )) *
      100,
  );

  const averageNumberOfUsersData = roundToTwoDecimals(
    (
      (averageViewsPerUserResult?.tablePivot() ?? []) as {
        "insights_dashboard_views.total_insights_views": string;
        "user.total_users": string;
      }[]
    )
      .map(
        (entry) =>
          parseInt(entry["insights_dashboard_views.total_insights_views"]) /
          parseInt(entry["user.total_users"]),
      )
      .reduce((acc, val) => acc + val, 0) / 30,
  );
  const uniqueNumberOfGroupsTrendPercentage = roundToTwoDecimals(
    (parseFloat(uniqueNumberOfGroupsResultData?.["group.total_groups"] ?? "") /
      parseFloat(
        uniqueNumberOfGroupsLastThirtyResultData?.["group.total_groups"] ?? "1",
      )) *
      100,
  );

  const getUserLineTrend = () => {
    const rawData = (usersTrendResult?.tablePivot() ?? []) as {
      "insights_dashboard_views.created.month": string;
      "user.total_users": number | string;
    }[];

    const yearmo = [
      ...new Set(
        rawData
          .map((obj) =>
            moment(obj["insights_dashboard_views.created.month"], "YYYY-MM"),
          )
          .sort((a, b) => a.valueOf() - b.valueOf())
          .map((yearmo) => yearmo.format("MM/YYYY")),
      ),
    ];

    const parsedData = rawData.map((obj) => {
      const [year, month] =
        obj["insights_dashboard_views.created.month"].split("-");
      return {
        year: parseInt(year),
        month: parseInt(month) - 1, // Adjust for zero-based indexing of months
        totalUsers: parseInt(obj["user.total_users"].toString()),
      };
    });

    const groupedData = yearmo.map((ym) => {
      const [month, year] = ym.split("/");
      const filteredData = parsedData.filter(
        (data) =>
          data.year === parseInt(year) && data.month === parseInt(month) - 1,
      );
      return filteredData.length ? filteredData[0].totalUsers : null;
    });

    const returnData = [
      {
        name: "Total Users",
        data: groupedData,
      },
    ];

    return {
      data: returnData,
      options: yearmo,
    };
  };

  const getDataForUserGroupTable = () => {
    const rawData = viewsPerUserGroupResult?.tablePivot() ?? [];

    const data = rawData.map((obj) => {
      const res = {} as {
        groupName: string;
        totalViews: number;
        totalDashboards: number;
      };
      res["groupName"] = obj["group.group_name"]! as string;
      res["totalViews"] = parseInt(
        obj["insights_dashboard_views.total_insights_views"] as string,
      );
      res["totalDashboards"] = parseInt(
        obj["insights_dashboard_views.total_pages"] as string,
      );
      return res;
    });

    const columns = [
      {
        Header: "Group Name",
        accessor: "groupName",
        Cell: (props: { value: string }) => <p>{props.value}</p>,
      },
      {
        Header: "Dashboards Viewed",
        accessor: "totalDashboards",
        Cell: (props: { value: number }) => <p>{props.value}</p>,
      },
      {
        Header: "View Count",
        accessor: "totalViews",
        Cell: (props: { value: number }) => (
          <p>{numberWithCommas(props.value)}</p>
        ),
      },
    ];

    return { data, columns };
  };

  return (
    <>
      <DataViz.Row>
        <DataViz.NumberChart
          loading={
            uniqueNumberOfUsersLoading || uniqueNumberOfUsersLastThirtyLoading
          }
          error={
            (uniqueNumberOfUsersError || uniqueNumberOfUsersLastThirtyError) &&
            "Something went wrong"
          }
          type="metric"
          toolTip="Total number of unique users who viewed one or more dashboard in the last 30 days"
          unit="users"
          title="Unique Number of Users (30 Days)"
          numerator={parseFloat(
            uniqueNumberOfUsersResultData?.["user.total_users"] ?? "",
          )}
          trendDirection={
            uniqueNumberOfUsersTrendPercentage > 0 ? "up" : "down"
          }
          trendPercentage={uniqueNumberOfUsersTrendPercentage}
        />
        <DataViz.NumberChart
          loading={averageViewsPerUserLoading}
          error={averageViewsPerUserError && "Something went wrong"}
          type="metric"
          toolTip="Average number of views per user per day for the last 30 days "
          unit="views"
          title="Average Views per User (30 Days)"
          numerator={averageNumberOfUsersData}
        />
        <DataViz.NumberChart
          loading={
            uniqueNumberOfGroupsLoading || uniqueNumberOfGroupsLastThirtyLoading
          }
          error={
            (uniqueNumberOfGroupsError ||
              uniqueNumberOfGroupsLastThirtyError) &&
            "Something went wrong"
          }
          type="metric"
          toolTip="Total number of unique user groups who viewed one or more dashboards in the last 30 days"
          unit="groups"
          title="Unique Number of User Groups (30 Days)"
          numerator={parseFloat(
            uniqueNumberOfGroupsResultData?.["group.total_groups"] ?? "",
          )}
          trendDirection={
            uniqueNumberOfGroupsTrendPercentage > 0 ? "up" : "down"
          }
          trendPercentage={uniqueNumberOfGroupsTrendPercentage}
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.LineChart
          title="Users Trend"
          toolTip="Analysis of monthly user who viewed one or more dashboards "
          loading={usersTrendLoading}
          error={usersTrendError && "Something went wrong"}
          yAxisLabel="Total Users"
          {...getUserLineTrend()}
        />
      </DataViz.Row>
      <Header
        title="Views per User Group"
        toolTip="Number of views per user group in the last 12 months"
        style={{ marginBottom: 0 }}
      />
      <Table
        isLoading={viewsPerUserGroupLoading}
        {...getDataForUserGroupTable()}
        pageSize={10}
        defaultSorted={[
          {
            id: "totalViews",
            desc: true,
          },
        ]}
      />
    </>
  );
};

export default UserGroupAnalysis;
