import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import gridSpacing from "../../utils/gridSpacing";

interface RadioButtonProps {
  checked: boolean;
  onChange: () => void;
  size?: number;
  toggleTrigger?: React.ReactNode;
  testId?: string;
  disabled?: boolean;
}

const RadioButtonContainer = styled.label<{
  $checked: boolean;
  $size: number;
  $disabled: boolean;
}>`
  display: inline-block;
  position: relative;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border: 2px solid
    ${({ $disabled }) =>
      $disabled ? colorTheme("neutralL3") : colorTheme("neutralL2")};
  border-radius: 50%;
  margin: 5px;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};

  ${({ $checked }) =>
    $checked &&
    `
    border-color: ${colorTheme("primary")};
  `}
`;

const RadioInput = styled.input<{
  disabled: boolean;
}>`
  position: absolute;
  opacity: 0;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

const Circle = styled.div<{
  $checked: boolean;
  $size: number;
  $disabled: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(${({ $size }) => $size}px / 2);
  height: calc(${({ $size }) => $size}px / 2);
  background-color: ${({ $disabled }) =>
    $disabled ? colorTheme("neutralL3") : colorTheme("primary")};
  border-radius: 50%;
  opacity: ${({ $checked, $disabled }) => ($checked || $disabled ? "1" : "0")};
  transition: opacity 0.2s ease-in-out;
`;

/**
 * RadioButton component that provides a customizable circular radio button with optional toggle trigger.
 *
 * @component
 * @param {boolean} props.checked - Determines whether the radio button is checked.
 * @param {Function} props.onChange - Callback function to be executed when the radio button is toggled.
 * @param {number} [props.size=14] - Size of the radio button. Default is 14.
 * @param {React.ReactNode} [props.toggleTrigger] - Component or text that serves as the toggle trigger.
 * @param {string} props.testId - Test id for the component.
 * @returns {JSX.Element} Rendered RadioButton component.
 */
const RadioButton = ({
  checked,
  onChange,
  size = 14,
  toggleTrigger,
  testId,
  disabled = false,
}: RadioButtonProps) => {
  const MainRadioButton = ({ style }: { style?: object }) => (
    <RadioButtonContainer
      $size={size}
      $checked={checked}
      style={style}
      data-testid={!toggleTrigger && testId}
      $disabled={disabled}
    >
      <RadioInput
        type="radio"
        checked={checked}
        onChange={() => !disabled && onChange()}
        disabled={disabled}
      />
      <Circle
        $size={size}
        $checked={checked}
        $disabled={disabled}
        data-testid="circle"
      />
    </RadioButtonContainer>
  );

  if (toggleTrigger) {
    return (
      <div
        onClick={() => !disabled && onChange()}
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        data-testid={testId}
      >
        <MainRadioButton style={{ marginRight: gridSpacing[2] }} />
        {toggleTrigger}
      </div>
    );
  }

  return <MainRadioButton />;
};

export default RadioButton;
