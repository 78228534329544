import type { ReactNode } from "react";
import React, { useEffect, useState } from "react";

import * as Popover from "@radix-ui/react-popover";
import { createPortal } from "react-dom";
import styled from "styled-components";

//CLOSE
interface CloseProps {
  children?: ReactNode;
}

const Close = ({ children }: CloseProps) => (
  <Popover.Close>{children}</Popover.Close>
);

// TRIGGER
interface TriggerProps {
  children?: ReactNode;
  asChild?: boolean;
}

const Trigger = ({ children, asChild = false }: TriggerProps) => (
  <Popover.Trigger asChild={asChild}>{children}</Popover.Trigger>
);

// CONTENT
interface ContentProps {
  children?: ReactNode;
  includeArrow?: false;
  arrowStyle?: object;
  style?: object;
  sideOffset?: number;
  align?: "start" | "center" | "end";
  side?: "top" | "right" | "bottom" | "left";
  alignOffset?: number;
  arrowPadding?: number;
}

const StyledContent = styled(Popover.Content)`
  outline: none !important;
`;

const Content = ({
  children,
  includeArrow = false,
  arrowStyle,
  style,
  sideOffset,
  align,
  side,
  alignOffset,
  arrowPadding,
}: ContentProps) => (
  <Popover.Portal>
    <StyledContent
      sideOffset={sideOffset}
      align={align}
      side={side}
      alignOffset={alignOffset}
      arrowPadding={arrowPadding}
      style={{ zIndex: 120 }}
    >
      <div style={style}>{children}</div>
      {includeArrow && (
        <Popover.Arrow style={{ width: 20, height: 10, ...arrowStyle }} />
      )}
    </StyledContent>
  </Popover.Portal>
);
// ROOT
interface RootProps {
  children?: ReactNode;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
}

const Root = ({ children, onOpenChange, open = undefined }: RootProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (open !== undefined) {
      setIsOpen(open);
    }
  }, [open]);

  return (
    <>
      {/* Make the background unclickable by inserting a div that spans the screen behind the popover */}
      {isOpen &&
        createPortal(
          <div
            style={{
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              position: "absolute",
              zIndex: 119,
            }}
          />,
          document.body,
        )}

      <Popover.Root
        open={isOpen}
        onOpenChange={(open) => {
          onOpenChange && onOpenChange(open);
          setIsOpen(!isOpen);
        }}
      >
        {children}
      </Popover.Root>
    </>
  );
};

Root.Trigger = Trigger;
Root.Content = Content;
Root.Close = Close;

export default Root;
