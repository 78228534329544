import React, { isValidElement } from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

const CardContainer = styled.div<{
  $margin?: number;
  $marginLeft?: number;
  $marginRight?: number;
  $marginTop?: number;
  $marginBottom?: number;
}>`
  margin: ${({ $margin }) => ($margin ? `${$margin}px` : 0)};
  margin-left: ${({ $marginLeft }) => ($marginLeft ? `${$marginLeft}px` : 0)};
  margin-right: ${({ $marginRight }) =>
    $marginRight ? `${$marginRight}px` : 0};
  margin-top: ${({ $marginTop }) => ($marginTop ? `${$marginTop}px` : 0)};
  margin-bottom: ${({ $marginBottom }) =>
    $marginBottom ? `${$marginBottom}px` : "15px"};
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  position: relative;
  border-radius: 10px;
`;

const CardBodyContainer = styled.div`
  padding: 15px;
`;

const CardFooterContainer = styled.div`
  padding: 15px;
`;

const CardHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #eee;
    cursor: pointer;
  }
`;

const CardHeaderClickableContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 15px;
`;

const CardTitleContainer = styled.div`
  font-size: 22px;
  font-weight: 300;
  line-height: 32px;
  display: flex;
  align-items: center;
`;

const ChevronIcon = styled.i`
  margin-right: 16px;

  &:hover {
    cursor: pointer;
    color: ${colorTheme("neutralL4")};
  }
`;

interface CardMenuProps {
  children: React.ReactNode;
}

const CardMenu = ({ children }: CardMenuProps) => <div>{children}</div>;

interface CardHeaderProps {
  // children: ReactNodeWithType | ReactNodeWithType[] | null;
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: () => void;
  isOpened?: boolean;
  isCollapsable?: boolean;
}

const CardHeader = ({
  children,
  style,
  onClick,
  isOpened,
  isCollapsable = true,
}: CardHeaderProps) => {
  const childrenArray = React.Children.toArray(children);
  const menu = childrenArray.find(
    (child) => isValidElement(child) && child.type === CardMenu,
  );
  const childrenWithoutMenu = childrenArray.filter(
    (child) => isValidElement(child) && child.type !== CardMenu,
  );

  return (
    <CardHeaderContainer style={style}>
      <CardHeaderClickableContainer onClick={onClick}>
        {isCollapsable && (
          <ChevronIcon
            className={`fa-solid ${
              isOpened ? "fa-chevron-down" : "fa-chevron-right"
            }`}
          />
        )}
        {childrenWithoutMenu}
      </CardHeaderClickableContainer>
      <div style={{ marginRight: 8 }}>{menu}</div>
    </CardHeaderContainer>
  );
};

interface CardBodyProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}
const CardBody = ({ children, style }: CardBodyProps) => (
  <CardBodyContainer style={style}>{children}</CardBodyContainer>
);

interface CardFooterProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const CardFooter = ({ children, style }: CardFooterProps) => (
  <CardFooterContainer style={style}>{children}</CardFooterContainer>
);

interface CardTitleProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const CardTitle = ({ children, style }: CardTitleProps) => (
  <CardTitleContainer style={style}>{children}</CardTitleContainer>
);

interface CardProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  margin?: number;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  marginBottom?: number;
}

const Card = ({
  children,
  style,
  margin,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
}: CardProps) => (
  <CardContainer
    style={style}
    $margin={margin}
    $marginLeft={marginLeft}
    $marginRight={marginRight}
    $marginTop={marginTop}
    $marginBottom={marginBottom}
  >
    {children}
  </CardContainer>
);

Card.Menu = CardMenu;
Card.Title = CardTitle;
Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;

export default Card;
