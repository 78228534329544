import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import gridSpacing from "../../utils/gridSpacing";
import type { MarginInterface } from "../utilities/interfaces";
import { getMargins } from "../utilities/margin";

const FormGroup = styled.div<{ $error: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${gridSpacing[4]}px;

  label {
    color: ${({ $error }) =>
      $error ? colorTheme("danger") : colorTheme("neutral")};
  }

  textarea {
    border-color: ${({ $error }) =>
      $error ? colorTheme("danger") : colorTheme("neutralL4")};
  }
`;

const TextAreaStyle = styled.textarea<{
  disabled: boolean;
}>`
  color: ${colorTheme("neutralL1")};
  display: block;
  padding: 3px 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  background-color: ${({ disabled }) =>
    disabled ? colorTheme("neutralL4") : colorTheme("white")};
  background-clip: padding-box;
  border: 1px solid ${colorTheme("neutralL3")};
  border-radius: 5px;
  margin: 0;
  font-family: inherit;
  min-height: 30px;
  resize: vertical;

  &:active,
  &:focus {
    outline-color: ${colorTheme("primary")};
  }
`;

const ErrorMessage = styled.div`
  color: ${colorTheme("danger")};
  width: 100%;
  margin-left: 5px;
  font-size: 14px;
`;

export const Label = styled.label`
  margin-bottom: 0;
`;

const RequiredSpan = styled.span`
  color: ${colorTheme("danger")};
  font-size: 14px;
  margin-left: 1px;
`;

interface TextAreaProps extends MarginInterface {
  label?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  name?: string;
  error?: string;
  rows: number;
  cols?: number;
  form?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  helperStyle?: React.CSSProperties;
  requiredStyle?: React.CSSProperties;
  textareaStyle?: React.CSSProperties;
  required?: boolean;
  readOnly?: boolean;
  /** data-testid attribute */
  testId?: string;
}

const TextArea = (props: TextAreaProps) => {
  const style = {
    ...getMargins({
      m: props.m,
      mx: props.mx,
      my: props.my,
      mt: props.mt,
      mb: props.mb,
      mr: props.mr,
      ml: props.ml,
    }),
    ...props.style,
  };

  return (
    <FormGroup style={style} $error={!!props.error}>
      <Label
        style={{
          color: props.error ? colorTheme("danger") : colorTheme("black"),
          ...props.labelStyle,
        }}
      >
        {props.label}
        {props.required && (
          <RequiredSpan style={props.requiredStyle}>*</RequiredSpan>
        )}
      </Label>
      <TextAreaStyle
        className={`form-control ${props.error ? "is-invalid" : ""}`}
        onChange={props.onChange}
        readOnly={props.readOnly}
        value={props.value}
        defaultValue={props.value ? undefined : props.defaultValue}
        placeholder={props.placeholder}
        disabled={!!props.disabled}
        name={props.name}
        rows={props.rows}
        cols={props.cols || 3}
        onBlur={props.onBlur}
        onKeyPress={props.onKeyPress}
        style={props.textareaStyle}
        data-testid={props.testId}
      />

      {props.error && (
        <ErrorMessage
          style={{ color: colorTheme("danger"), ...props.helperStyle }}
        >
          {props.error}
        </ErrorMessage>
      )}
    </FormGroup>
  );
};

export default TextArea;
