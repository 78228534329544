import type { ReactNode } from "react";
import React from "react";

import { HoverCard, StatusPill } from "@commonComponents";
import { styled } from "styled-components";

import { colorTheme, gridSpacing } from "@utils";

const Content = styled.div`
  width: 500px;
`;

const Header = styled.div`
  padding: ${gridSpacing[3]}px ${gridSpacing[4]}px;
  display: flex;
  justify-content: space-between;
  background-color: ${colorTheme("primaryL4")};
  border-radius: ${gridSpacing[1]}px ${gridSpacing[1]}px 0 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${gridSpacing[3]}px;
`;

const SubTitle = styled.div`
  font-size: 12px;
  color: ${colorTheme("neutralL2")};
  margin-top: ${gridSpacing[1]}px;
  font-weight: 700;
`;

const Title = styled.div`
  font-weight: 700;
  color: ${colorTheme("primary")};
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${gridSpacing[4]}px;
`;

const DetailRow = styled.div`
  display: flex;
  margin: ${gridSpacing[2]}px 0;
`;

const DetailTitle = styled.div`
  font-size: 14;
  color: ${colorTheme("neutralL1")};
  width: 154px;
`;

const DetailContent = styled.div`
  font-size: 14;

  max-width: 310px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

interface DetailHoverCardProps {
  title: string;
  subTitle: string;
  details: {
    title: string;
    content: ReactNode;
  }[];
  trigger: ReactNode;
  statusPillText?: string;
  statusPillType?:
    | "default"
    | "primary"
    | "success"
    | "warning"
    | "danger"
    | "discovery";
  side?: "top" | "right" | "bottom" | "left";
  align?: "start" | "center" | "end";
  includeArrow?: boolean;
  alignOffset?: number;
  sideOffset?: number;
  triggerAsChild?: boolean;
  testId?: string;
}

const DetailHoverCard = ({
  title,
  subTitle,
  statusPillText,
  statusPillType,
  details,
  trigger,
  side = "bottom",
  align = "start",
  includeArrow = false,
  alignOffset,
  sideOffset,
  triggerAsChild = false,
  testId = "detail-hover-card",
}: DetailHoverCardProps) => (
  <HoverCard>
    <HoverCard.Trigger asChild={triggerAsChild}>{trigger}</HoverCard.Trigger>
    <HoverCard.Content
      align={align}
      side={side}
      includeArrow={includeArrow}
      alignOffset={alignOffset}
      sideOffset={sideOffset}
    >
      <Content>
        <Header>
          <TitleContainer data-testid={`${testId}-title-container`}>
            <Title>{title}</Title>
            <SubTitle> {subTitle}</SubTitle>
          </TitleContainer>
          {statusPillText && (
            <StatusContainer>
              <StatusPill
                style={{
                  backgroundColor: colorTheme("primary"),
                  color: colorTheme("white"),
                }}
                text={statusPillText}
                type={statusPillType}
                testId={`${testId}-status-pill`}
              />
            </StatusContainer>
          )}
        </Header>

        <Details data-testid={`${testId}-details`}>
          {details.map((detail, i) => (
            <DetailRow
              data-testid={`${testId}-detail-row-${detail.title}`}
              key={i}
            >
              <DetailTitle>{detail.title}</DetailTitle>
              <DetailContent>{detail.content || "-"}</DetailContent>
            </DetailRow>
          ))}
        </Details>
      </Content>
    </HoverCard.Content>
  </HoverCard>
);

export default DetailHoverCard;
