import React, { useEffect, useState } from "react";

import Select, {
  type GroupBase,
  type ControlProps,
  type OptionProps,
  components,
} from "react-select";
import CreatableSelect from "react-select/creatable";

import { colorTheme } from "@utils";

import GroupLabel from "./GroupLabel";
import Option from "./Option";
import type { OptionProp, SelectProps, ValueProps } from "./Select";
import gridSpacing from "../../utils/gridSpacing";

const SelectComponent = <TValue extends ValueProps>({
  controlStyle = {},
  valueContainerStyle = {},
  indicatorsContainerStyle = {},
  indicatorSeparatorStyle = {},
  inputStyle = {},
  singleValueStyle = {},
  optionStyle = {},
  placeholderStyle = {},
  label,
  autoFocus = false,
  disabled = false,
  creatable = false,
  emptyOption = false,
  loading = false,
  showAvatars = false,
  checkbox = false,
  radio = false,
  noOptionsMessage = "No options",
  onBlur,
  error,
  value,
  options,
  onChange = () => null,
  placeholder,
  id,
  testId,
  multiValues,
}: Omit<SelectProps<TValue>, "value"> & {
  value:
    | { label: string; value: string }
    | { label: string; value: string }[]
    | null;
}) => {
  const [menuPortalTarget, setMenuPortalTarget] =
    useState<HTMLBodyElement | null>(null);
  const [selectPlaceholder, setSelectPlaceholder] = useState(placeholder);

  useEffect(() => {
    if (loading) {
      setSelectPlaceholder("Loading...");
    } else if (placeholder) {
      setSelectPlaceholder(placeholder);
    } else {
      if (!label) {
        setSelectPlaceholder(`Select...`);
      } else if (creatable) {
        setSelectPlaceholder(
          `Select ${label.toLowerCase()} and press enter to create`,
        );
      } else {
        setSelectPlaceholder(`Select ${label.toLowerCase()}`);
      }
    }
  }, [placeholder, loading]);

  useEffect(() => {
    // This is specific for command center
    if (
      !document.getElementById("modalBackground") &&
      !document.getElementById("internalPageBackground")
    ) {
      setMenuPortalTarget(document.querySelector("body"));
    }
  }, []);

  const CustomControl = ({
    children,
    ...props
  }: ControlProps<
    false | ({ label: string; value: string } & OptionProp),
    boolean,
    GroupBase<{ label: string; value: string }> & {
      label: string;
      options: OptionProp[];
    }
  >) => {
    const values = props?.getValue();
    let icon = null;

    if (values && values[0]) {
      const value = values[0];
      if (value.imgSrc) {
        icon = (
          <img
            src={value.imgSrc}
            alt={value.label}
            style={{
              width: 28,
              height: 28,
              marginLeft: gridSpacing[1],
              borderRadius: 3,
            }}
          />
        );
      } else if (value.faIcon) {
        icon = (
          <i
            className={`fa ${value.faIcon}`}
            style={{
              fontSize: 16,
              color: colorTheme("neutralL3"),
              marginLeft: gridSpacing[2],
            }}
          />
        );
      }
    }

    return (
      <components.Control {...props}>
        {icon}
        {children}
      </components.Control>
    );
  };

  const CustomOption = (
    props: OptionProps<
      false | { label: string; value: string },
      boolean,
      GroupBase<{ label: string; value: string }> & {
        label: string;
        options: OptionProp[];
      }
    > & {
      style: React.CSSProperties;
    },
  ) => (
    // @ts-ignore Can't figure out how to pass props correctly
    <Option
      {...props}
      radio={radio}
      checkbox={checkbox}
      showAvatar={showAvatars}
    />
  );

  const FormatGroupLabel = (
    groupLabelProps: GroupBase<{ label: string; value: string }> & {
      label: string;
      options: OptionProp[];
    },
  ) => (
    <GroupLabel
      {...groupLabelProps}
      valueCount={
        Array.isArray(value)
          ? groupLabelProps.options.filter((o: OptionProp) =>
              value?.some((v) => v.value === o.value),
            ).length
          : 0
      }
    />
  );

  const ReactSelectComponent = creatable ? CreatableSelect : Select;

  return (
    <ReactSelectComponent
      menuPlacement="auto"
      placeholder={selectPlaceholder}
      options={
        (emptyOption
          ? [{ value: "", label: "-" }, ...(options ?? [])]
          : (options ?? [])) as { label: string; value: string }[]
      }
      formatGroupLabel={FormatGroupLabel}
      components={{
        Option: (props) => <CustomOption {...props} style={optionStyle} />,
        Control: multiValues ? components.Control : CustomControl,
        IndicatorSeparator: () => null,
        LoadingIndicator: () => (
          <i
            data-testid="loading-indicator"
            className="fa fa-circle-notch fa-spin"
            style={{ marginRight: 12, color: colorTheme("neutralL3") }}
          />
        ),
        ClearIndicator: ({ innerProps }) => (
          <i
            {...innerProps}
            className="fa-solid fa-times"
            style={{ color: colorTheme("neutralL3"), cursor: "pointer" }}
          />
        ),
      }}
      isMulti={multiValues}
      isLoading={loading}
      value={!loading && value}
      onChange={(e) => {
        if (Array.isArray(e)) {
          onChange(e.map((obj) => obj.value) as TValue);
        } else if (e && "value" in e) {
          onChange(`${e.value}` as TValue);
        } else {
          onChange("" as TValue);
        }
      }}
      isDisabled={disabled || loading}
      theme={(theme) => {
        return {
          ...theme,
          colors: {
            ...(colorTheme(null) as unknown as Record<string, string>),
            ...theme.colors,
            primary: colorTheme("primary"),
            primary75: colorTheme("primaryL1"),
            primary50: colorTheme("primaryL2"),
            primary25: colorTheme("primaryL3"),
            danger: colorTheme("danger"),
            danger_light: colorTheme("dangerL5"),
          },
        };
      }}
      styles={{
        control: (base, state) => {
          return {
            ...base,
            borderColor: error ? colorTheme("danger") : colorTheme("neutralL4"),
            boxShadow: state.menuIsOpen
              ? `0 0 0 1px ${colorTheme("primaryL1")}`
              : "none",
            ...controlStyle,
          };
        },
        valueContainer: (base) => {
          return {
            ...base,
            ...valueContainerStyle,
          };
        },
        indicatorsContainer: (provided) => {
          return {
            ...provided,
            ...indicatorsContainerStyle,
          };
        },
        indicatorSeparator: (provided) => {
          return {
            ...provided,
            ...indicatorSeparatorStyle,
          };
        },
        input: (provided) => {
          return {
            ...provided,
            ...inputStyle,
          };
        },
        singleValue: (provided) => {
          return {
            ...provided,
            ...singleValueStyle,
          };
        },
        menuPortal: (provided) => {
          return { ...provided, zIndex: 99999 };
        },
        menu: (provided) => {
          return { ...provided, zIndex: 9999 };
        },
        placeholder: (provided) => {
          return {
            ...provided,
            ...placeholderStyle,
          };
        },
      }}
      closeMenuOnSelect={!multiValues}
      hideSelectedOptions={!(multiValues && checkbox)}
      onBlur={onBlur}
      noOptionsMessage={() => noOptionsMessage}
      autoFocus={autoFocus}
      menuPortalTarget={menuPortalTarget}
      inputId={testId || id || `${label?.replaceAll(" ", "-")}-select`}
      instanceId={testId || id || `${label?.replaceAll(" ", "-")}-select`}
    />
  );
};
export default SelectComponent;
