import React from "react";

import { Panel } from "@commonComponents";
import PropTypes from "prop-types";

import { Container } from "./Generic.style";

const PermissionDenied = ({ noPanel = false }) => {
  const Body = () => (
    <Container>
      <h3 style={{ fontSize: 32 }}>Permission Denied</h3>
      <p>
        You are trying to access a page that you do not have permission for.
        Please contact an administrator if you believe you should be able to
        access this page
      </p>
    </Container>
  );

  if (noPanel) return <Body />;

  return (
    <Panel style={{ marginTop: 15 }}>
      <Body />
    </Panel>
  );
};

PermissionDenied.propTypes = {
  noPanel: PropTypes.bool,
};

export default PermissionDenied;
