import React from "react";

import { styled } from "styled-components";

import { colorTheme, gridSpacing } from "@utils";

type ErrorProps = {
  errorMessage: string;
};

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: ${gridSpacing[4]}px;
  min-height: 147px;
  min-width: 251px;
  flex: 1;
`;

const ErrorComponent = ({ errorMessage }: ErrorProps) => (
  <ErrorWrapper>
    <i
      data-testid="error-icon"
      style={{ fontSize: 42, color: colorTheme("dangerL1") }}
      className="fa-solid fa-triangle-exclamation"
    />
    <p style={{ color: colorTheme("neutralL1"), marginTop: gridSpacing[2] }}>
      {errorMessage}
    </p>
  </ErrorWrapper>
);

export default ErrorComponent;
