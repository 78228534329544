import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import { TableLink } from "..";
import type { MarginInterface } from "../utilities/interfaces";
import { getMargins } from "../utilities/margin";

interface CalloutProps extends MarginInterface {
  type: "info" | "success" | "warning" | "error" | "discovery";
  title?: string;
  style?: React.CSSProperties;
  text?: string | React.ReactNode;
  links?: { url: string; title: string }[];
  toggle?: () => void;
}

type TypeDefs = Record<
  string,
  {
    backgroundColor: string;
    titleColor: string;
    iconColor: string;
    border: string;
    icon: string;
  }
>;

const CalloutContainer = styled.div<{
  $typeDef: {
    backgroundColor: string;
    border: string;
  };
}>`
  border-radius: 8px;
  padding: 16px;
  background-color: ${({ $typeDef }) => $typeDef.backgroundColor};
  border: ${({ $typeDef }) => $typeDef.border};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props: { title?: string }) => (props.title ? "16px" : 0)};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TitleText = styled.b<{
  $title?: string;
  $typeDef: { titleColor: string };
}>`
  color: ${({ $title, $typeDef }) =>
    $title ? $typeDef.titleColor : colorTheme("neutralL1")};
  font-weight: ${({ $title }) => ($title ? 500 : "normal")};
`;

const HeaderIcon = styled.i<{
  $typeDef: { iconColor: string };
}>`
  font-size: 24px;
  margin-right: 12px;
  color: ${({ $typeDef }) => $typeDef.iconColor};
`;

const CloseIcon = styled.i`
  font-size: 16px;
  margin-left: 16px;
  color: ${colorTheme("neutralL1")};

  &:hover {
    cursor: pointer;
    color: ${colorTheme("neutralL2")};
  }
`;

const Content = styled.div<{
  $title?: string;
}>`
  color: ${colorTheme("neutralL1")};
  margin-left: ${({ $title }) => ($title ? "36px" : 0)};
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 36px;
  margin-top: 16px;
`;

const Action = styled.div`
  font-size: 14px;
  margin-right: 24px;
`;

const Callout: React.FC<CalloutProps> = ({
  type,
  title,
  style,
  text,
  links = [],
  toggle,
  m,
  mx,
  my,
  mt,
  mb,
  mr,
  ml,
}) => {
  const typeDefs: TypeDefs = {
    info: {
      backgroundColor: colorTheme("infoL3"),
      titleColor: colorTheme("infoD1"),
      iconColor: colorTheme("info"),
      border: `1px solid ${colorTheme("infoL1")}`,
      icon: "fa-circle-info",
    },
    success: {
      backgroundColor: colorTheme("successL5"),
      titleColor: colorTheme("successD1"),
      iconColor: colorTheme("success"),
      border: `1px solid ${colorTheme("successL2")}`,
      icon: "fa-circle-check",
    },
    warning: {
      backgroundColor: colorTheme("warningL5"),
      titleColor: colorTheme("warningD1"),
      iconColor: colorTheme("warning"),
      border: `1px solid ${colorTheme("warningL2")}`,
      icon: "fa-triangle-exclamation",
    },
    error: {
      backgroundColor: colorTheme("dangerL5"),
      titleColor: colorTheme("dangerD1"),
      iconColor: colorTheme("danger"),
      border: `1px solid ${colorTheme("dangerL2")}`,
      icon: "fa-circle-xmark",
    },
    discovery: {
      backgroundColor: colorTheme("discoveryL5"),
      titleColor: colorTheme("discoveryD1"),
      iconColor: colorTheme("discovery"),
      border: `1px solid ${colorTheme("discoveryL2")}`,
      icon: "fa-circle-question",
    },
  };

  const typeDef = typeDefs[type] || typeDefs.info;

  // Setup default margins
  if (mt === true) {
    mt = 3;
  }

  if (mb === true) {
    mb = 3;
  }

  return (
    <CalloutContainer
      $typeDef={typeDef}
      style={{ ...getMargins({ m, mx, my, mt, mb, mr, ml }), ...style }}
    >
      <Header title={title}>
        <TitleContainer>
          <HeaderIcon
            $typeDef={typeDef}
            className={`fa-solid ${typeDef.icon}`}
          />
          <TitleText $typeDef={typeDef} $title={title}>
            {title ? title : text}
          </TitleText>
        </TitleContainer>
        {toggle && <CloseIcon className="fa-solid fa-xmark" onClick={toggle} />}
      </Header>
      {/* If a title exists render content below title */}
      {title && <Content $title={title}>{text}</Content>}
      {links.length > 0 && (
        <ActionContainer>
          {links.map((linkObj, i) => (
            <Action key={i}>
              <TableLink newTab url={linkObj.url}>
                {linkObj.title}
              </TableLink>
            </Action>
          ))}
        </ActionContainer>
      )}
    </CalloutContainer>
  );
};

export default Callout;
