/**
 * @description
 * Grid spacing values
 * @example
 * 1: 4px
 * 2: 8px
 * 3: 12px
 * 4: 16px
 * 5: 24px
 * 6: 32px
 * 7: 40px
 * 8: 48px
 * 9: 64px
 * 10: 96px
 * 11: 160px
 */
const gridSpacing: Record<number, number> = {
  1: 4,
  2: 8,
  3: 12,
  4: 16,
  5: 24,
  6: 32,
  7: 40,
  8: 48,
  9: 64,
  10: 96,
  11: 160,
};

export default gridSpacing;
