import React from "react";

export const NoPermissions = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    width="250"
    height="250"
    viewBox="0 0 250 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g filter="url(#filter0_i_1852_4697)">
      <rect
        x="25"
        y="25"
        width="200"
        height="200"
        rx="100"
        fill="#B1B5B7"
        fillOpacity="0.2"
      />
    </g>
    <g filter="url(#filter1_d_1852_4697)">
      <g clipPath="url(#clip0_1852_4697)">
        <rect x="23" y="50" width="205" height="150" rx="5.88" fill="white" />
        <g style={{ mixBlendMode: "luminosity" }}>
          <circle cx="33.29" cy="60.29" r="2.94" fill="#005B93" />
          <circle cx="42.1099" cy="60.29" r="2.94" fill="#CCCCCC" />
          <circle cx="50.93" cy="60.29" r="2.94" fill="#23A6F9" />
        </g>
        <line
          x1="23"
          y1="70.2125"
          x2="228.8"
          y2="70.2125"
          stroke="#B2C4D7"
          strokeOpacity="0.3"
          strokeWidth="0.735"
        />
        <g opacity="0.4">
          <rect
            x="115.609"
            y="82.34"
            width="99.96"
            height="7.35"
            rx="3.675"
            fill="#DEDEDE"
          />
          <rect
            x="115.609"
            y="95.57"
            width="99.96"
            height="7.35"
            rx="3.675"
            fill="#DEDEDE"
          />
          <rect
            x="186.17"
            y="148.49"
            width="29.4"
            height="7.35"
            rx="3.675"
            fill="#DEDEDE"
          />
          <rect
            x="186.17"
            y="161.72"
            width="29.4"
            height="7.35"
            rx="3.675"
            fill="#DEDEDE"
          />
          <rect
            x="115.609"
            y="108.8"
            width="29.4"
            height="7.35"
            rx="3.675"
            fill="#DEDEDE"
          />
          <rect
            x="36.2295"
            y="135.26"
            width="70.56"
            height="7.35"
            rx="3.675"
            fill="#DEDEDE"
          />
          <rect
            x="36.2295"
            y="148.49"
            width="70.56"
            height="7.35"
            rx="3.675"
            fill="#DEDEDE"
          />
          <rect
            x="36.2295"
            y="163.19"
            width="70.56"
            height="7.35"
            rx="3.675"
            fill="#DEDEDE"
          />
          <rect
            x="36.2295"
            y="176.42"
            width="29.4"
            height="7.35"
            rx="3.675"
            fill="#DEDEDE"
          />
          <rect
            x="36.2295"
            y="82.34"
            width="70.56"
            height="41.16"
            rx="2.94"
            fill="#F0F1F1"
          />
          <rect
            x="144.275"
            y="183.77"
            width="28.665"
            height="28.665"
            rx="2.94"
            fill="#F3F3F3"
          />
          <g style={{ mixBlendMode: "luminosity" }}>
            <circle cx="71.5096" cy="102.92" r="14.7" fill="#005B93" />
          </g>
          <g style={{ mixBlendMode: "luminosity" }}>
            <path
              // eslint-disable-next-line max-len
              d="M84.1919 110.353C85.5199 108.087 86.2166 105.507 86.2095 102.88C86.2024 100.254 85.4918 97.6774 84.1516 95.4187C82.8114 93.16 80.8905 91.3016 78.5887 90.0368C76.2869 88.772 73.6883 88.147 71.0631 88.2268L71.5096 102.92L84.1919 110.353Z"
              fill="#23A6F9"
            />
          </g>
          <rect
            x="117.08"
            y="130.85"
            width="98.49"
            height="88.935"
            rx="4.41"
            fill="#F0F1F1"
          />
          <g style={{ mixBlendMode: "luminosity" }}>
            <rect
              x="129.575"
              y="173.01"
              width="16.17"
              height="7.35"
              rx="2.94"
              transform="rotate(-90 129.575 173.01)"
              fill="#005B93"
            />
            <rect
              x="169.265"
              y="173.01"
              width="24.99"
              height="7.35"
              rx="2.94"
              transform="rotate(-90 169.265 173.01)"
              fill="#23A6F9"
            />
            <rect
              x="156.035"
              y="173.01"
              width="13.965"
              height="7.35"
              rx="2.94"
              transform="rotate(-90 156.035 173.01)"
              fill="#005B93"
            />
            <rect
              x="182.495"
              y="173.01"
              width="17.64"
              height="7.35"
              rx="2.94"
              transform="rotate(-90 182.495 173.01)"
              fill="#005B93"
            />
            <rect
              x="142.805"
              y="173.01"
              width="20.58"
              height="7.35"
              rx="2.94"
              transform="rotate(-90 142.805 173.01)"
              fill="#23A6F9"
            />
            <rect
              x="195.725"
              y="173.01"
              width="16.17"
              height="7.35"
              rx="2.94"
              transform="rotate(-90 195.725 173.01)"
              fill="#23A6F9"
            />
          </g>
        </g>
        <path
          d="M125.4 154.5C125.4 154.5 150.8 141.8 150.8 122.75V100.525L125.4 91L100 100.525V122.75C100 141.8 125.4 154.5 125.4 154.5Z"
          fill="#FCAC4A"
        />
        <rect
          x="122.225"
          y="106.24"
          width="6.76413"
          height="21.6452"
          fill="white"
        />
        <rect
          x="122.225"
          y="130.591"
          width="6.76413"
          height="6.76413"
          rx="3.38207"
          fill="white"
        />
      </g>
      <rect
        x="23.3675"
        y="50.3675"
        width="204.265"
        height="149.265"
        rx="5.5125"
        stroke="#B2C4D7"
        strokeWidth="0.735"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1852_4697"
        x="25"
        y="25"
        width="200"
        height="200"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1852_4697"
        />
      </filter>
      <filter
        id="filter1_d_1852_4697"
        x="15.65"
        y="48.53"
        width="219.7"
        height="164.7"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.88" />
        <feGaussianBlur stdDeviation="3.675" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1852_4697"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1852_4697"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1852_4697">
        <rect x="23" y="50" width="205" height="150" rx="5.88" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
