import React from "react";

import { Button, ToolTip } from "@commonComponents";
import { css, styled } from "styled-components";

import { colorTheme, gridSpacing } from "@utils";

import type { ButtonType } from "~/src/CommonComponents/Button/Button";
import { favoriteObject } from "~/src/utils/tsUtils";

const StyledButton = styled.i<{ $favorite: boolean; $dark: boolean }>`
  color: ${(props) =>
    props.$favorite
      ? colorTheme("warning")
      : props.$dark
        ? colorTheme("neutralL1")
        : colorTheme("neutralL3")} !important;

  ${({ $dark, $favorite }) =>
    $dark &&
    !$favorite &&
    css`
      &:hover {
        color: ${colorTheme("neutral")} !important;
      }
      &:active {
        color: ${colorTheme("neutralL2")} !important;
      }
    `}
  ${({ $favorite }) =>
    $favorite &&
    css`
      &:hover {
        color: ${colorTheme("warningD2")} !important;
      }

      &:active {
        color: ${colorTheme("warningD1")} !important;
      }
    `}
  font-size: 19px;
  margin-left: ${gridSpacing[2]}px;
  cursor: pointer;
`;

type FavoriteButtonProps = {
  favorite: boolean;
  objectId: string;
  objectType: string;
  refreshFavorites: () => void;
  style?: React.CSSProperties;
  dark?: boolean;
  type?: ButtonType;
};

const FavoriteButton = ({
  favorite,
  objectId,
  objectType,
  refreshFavorites,
  style,
  dark,
  type,
}: FavoriteButtonProps) => {
  const updateFavorite = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    await favoriteObject({ objectType, objectId, remove: favorite });
    refreshFavorites();
  };

  return (
    <ToolTip
      text={favorite ? "Unfavorite" : "Favorite"}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {type ? (
        <Button
          style={{
            color: favorite ? colorTheme("warning") : colorTheme("neutralL1"),
            ...style,
          }}
          type={type}
          icon={favorite ? "fa-solid fa-star" : "fa-regular fa-star"}
          onClick={(e) => updateFavorite(e)}
        />
      ) : (
        <StyledButton
          $dark={!!dark}
          style={style}
          className={`fa-${favorite ? "solid" : "regular"} fa-star`}
          $favorite={favorite}
          onClick={(e) => updateFavorite(e)}
        />
      )}
    </ToolTip>
  );
};

export default FavoriteButton;
