import type { FC } from "react";
import React from "react";

import { Link } from "react-router";
import styled from "styled-components";

import { colorTheme } from "@utils";

interface CrumbProps {
  $isChild?: boolean;
}

const Crumb = styled.span<CrumbProps>`
  color: ${({ $isChild }) =>
    $isChild ? colorTheme("neutralL1") : colorTheme("neutral")};
  font-size: 15px;
`;

const Slash = styled.span`
  color: ${colorTheme("neutralL1")};
  padding: 0 5px;
  font-size: 15px;
`;

const TrailContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 15px;
`;

export interface Route {
  path?: string;
  breadcrumb: React.ReactNode;
  onClick?: () => void;
}

interface BreadCrumbsProps {
  routes: Route[];
  style?: React.CSSProperties;
}

/**
 * BreadCrumbs
 * @param {Route[]} routes
 * @param {object} style
 */
const BreadCrumbs: FC<BreadCrumbsProps> = ({ routes, style }) => {
  const trail: React.ReactNode[] = [];

  routes.forEach((route, i) => {
    const isChild = i !== routes.length - 1;

    if (!route.path) {
      trail.push(
        <Crumb key={i} $isChild={isChild} style={{ marginTop: 1 }}>
          {route.breadcrumb}
        </Crumb>,
      );
    } else {
      trail.push(
        <Link
          key={i}
          to={route.path}
          onClick={() => route.onClick && route.onClick()}
        >
          <Crumb style={{ color: colorTheme("primary") }} $isChild={isChild}>
            {route.breadcrumb}
          </Crumb>
        </Link>,
      );
    }

    if (isChild) {
      trail.push(<Slash key={`${i}_slash`}>/</Slash>);
    }
  });

  return <TrailContainer style={{ ...style }}>{trail}</TrailContainer>;
};

export default BreadCrumbs;
