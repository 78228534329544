import React, { useEffect, useState } from "react";

import { Button, SearchText, Select } from "@commonComponents";
import axios from "axios";
import queryString from "query-string";
import { connect } from "react-redux";
import { styled } from "styled-components";

import { gridSpacing, makeHumanReadable } from "@utils";

import CustomOptionsPopover from "./CustomOptionsPopover";
import FilterButton from "./FilterButton";
import Options from "./Options";
import SaveFilterModal from "./SavedFiltersModal";
import { setAdvancedFilterRefresh } from "../../actions/main";
import type {
  AdvancedFilterFilter as AdvancedFilterFilterAPIProps,
  AdvancedFilterFilterTypeEnum,
  Filter as SearchFilterAPIProps,
  AdvancedFilter as SearchAdvancedFilterAPIProps,
} from "~/src/types";
import { internalPageError } from "~/src/utils/tsUtils";

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${gridSpacing[2]}px;
  align-items: center;
  min-height: 28px; // This is to prevent the table from jolting when filters are loaded in
`;

type DataVizFilterItemProps = {
  key: string;
  label: string;
  name: string;
  options: string[];
  chartTitles?: string[];
};

type DataVizFilterOptionsProps = Record<string, DataVizFilterItemProps[]>;

export type FilterOptionProps = {
  label: string;
  value: string;
  options?: FilterOptionProps[];
  gt_suffix?: string;
  lt_suffix?: string;
  identifier?: string;
  type?: AdvancedFilterFilterTypeEnum;
  customOptions?: React.ReactNode[];
  custom?: boolean;
};

type AdvancedFiltersProps = {
  app?: string;
  urlPattern?: string;
  pageKey?: string;
  query?: string;
  setQuery?: (query: string, queryDotNotation: string) => void;
  setQueryInURL?: boolean;
  hideSavedFilters?: boolean;
  primaryFilters?: string[];
  hiddenFilters?: string[];
  hiddenGroups?: string[];
  constantBaseFilters?: Record<string, string>;
  baseFilters?: Record<string, string>;
  quickFilters?: {
    name: string;
    filters: Record<string, string[]>;
  }[];
  incomingFilters?: FilterOptionProps[];
  dataVizFilters?: DataVizFilterOptionsProps;
  model?: string;
  noFilters?: boolean;
  inlineViewButtons?: boolean;
  refresh?: boolean;
  setAdvancedFilterRefresh?: (refresh: boolean) => void;
  searchTextWidth?: number;
  searchTextHidden?: boolean;
  loadingCallback?: (loading: boolean) => void;
};

// The query is built with dot notation so we know what group the filter is part of - this can be removed though so the
// table using the query can send the correct query to the API which does not use dot notation
export const buildQuery = (
  _filters: Record<string, string[]>,
  removeDotNotation = false,
) => {
  if (!_filters) return "";

  let query = "?";
  Object.keys(_filters).forEach((key: string) => {
    let newKey = key;
    if (removeDotNotation) {
      const splitKey = key.split(".");
      newKey = splitKey[splitKey.length - 1];
    }

    if (_filters[key]?.length > 0) {
      if (Array.isArray(_filters[key])) {
        _filters[key].forEach((filter) => {
          query += `${newKey}=${filter.replaceAll("+", "%2B")}&`;
        });
      } else {
        query += `${newKey}=${_filters[key]}&`;
      }
    }
  });

  return query.substring(0, query.length - 1);
};

const transformDataVizFilterOptions = (
  filterOptions: DataVizFilterOptionsProps,
) => {
  const advFilterOptions: FilterOptionProps[] = [];
  Object.keys(filterOptions).forEach((key) => {
    const currentFilterOptions: FilterOptionProps[] = [];

    filterOptions[key].forEach((groupOption) => {
      currentFilterOptions.push({
        label: makeHumanReadable(groupOption.label),
        value: groupOption.label,
        options: groupOption.options
          .filter((o) => !!o)
          .map((o) => {
            return {
              value: o,
              label: o,
            };
          }),
        type: "MULTI_OPTION",
      });
    });

    advFilterOptions.push({
      label: makeHumanReadable(key),
      value: key,
      options: currentFilterOptions,
    });
  });

  return advFilterOptions;
};

/**
 * Advanced Filters
 *
 * @component
 * @param {string} app - App used for the API for saved filters
 * @param {string} urlPattern - URL used for the API for advanced filters
 * @param {string} pageKey - pageKey used in case of multiple advanced filters using the same app and url pattern so they do not conflict per page
 * @param {string} query - Incoming query string from the URL - this will override the URL query string
 * @param {function} setQuery - The function to set the query string in the component using advanced filters
 * @param {boolean} setQueryInURL - Determines whether to set the query string in the URL or control it from the component
 * @param {boolean} hideSavedFilters - Determines whether to hide the saved filters button
 * @param {string[]} primaryFilters - The filters that will always be displayed wether they have a value or not
 * @param {string[]} hiddenFilters - Filters that will be hidden from the user
 * @param {string[]} hiddenGroups - Groups that will be hidden from the user
 * @param {Record<string, string>} constantBaseFilters - Constant filters that will always be added to the query
 * @param {Record<string, string>} baseFilters - The filters the component will mount with however the user can remove or change these
 * @param {name: string; filters: Record<string, string[]>[]} - Filters that the user can click a button and it will filter by whatever is in the quick filter object
 * @param {FilterOptionProps} incomingFilters - Incoming filters instead of loading them from the API advance search
 * @param {DataVizFilterOptionsProps} dataVizFilters - Filters for data viz
 * @param {string} model - Model to be used along with incomingFilters to save the filters
 * @param {boolean} noFilters - If true the component will not load any filters
 * @param {boolean} inlineViewButtons - If true the view buttons will be inline with the filter buttons (this is originally used for data viz)
 * @param {boolean} refresh - If true the component will refresh the filters
 * @param {function} setAdvancedFilterRefresh - The function to set the refresh state of the filters
 * @param {number} searchTextWidth - The width of the search text
 * @param {boolean} searchTextHidden - Determines weather the search text is hidden (default true)
 * @param {function} loadingCallback - Callback when loading changes when loading filters
 * @returns {JSX.Element} The rendered AdvancedFilters component.
 */
const AdvancedFilters = ({
  app,
  pageKey = "",
  urlPattern,
  query,
  setQuery = () => {},
  setQueryInURL = true,
  hideSavedFilters = false,
  primaryFilters,
  quickFilters,
  hiddenFilters,
  hiddenGroups,
  constantBaseFilters,
  baseFilters,
  incomingFilters,
  dataVizFilters,
  model = "",
  noFilters,
  inlineViewButtons,
  refresh,
  searchTextWidth,
  searchTextHidden,
  setAdvancedFilterRefresh = () => {},
  loadingCallback,
}: AdvancedFiltersProps) => {
  const [currentModel, setCurrentModel] = useState(model);
  const [clearedFilters, setFiltersCleared] = useState(false);
  const [filterData, setFilterData] = useState<FilterOptionProps[]>(
    incomingFilters ?? [],
  );
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [savedFilters, setSavedFilters] = useState<SearchFilterAPIProps[]>([]);
  const [secondaryFilters, setSecondaryFilters] = useState<string[]>([]);
  const [primarySearchFilter, setPrimarySearchFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [filterModalOpened, setFilterModalOpened] = useState<
    "newFilter" | boolean
  >(false);
  const [newFilter, setNewFilter] = useState<FilterOptionProps | null>(null);
  const [usingSavedFilterName, setUsingSavedFilterName] = useState("");
  const [closeMenu, setCloseMenu] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [firstLoad, setFirstLoad] = useState(true);

  const addBaseFilters = (
    queryParams = "",
    _clearedFilters = clearedFilters,
  ) => {
    if (
      !queryParams && // Only add base filters if there are no query params
      !_clearedFilters && // Only add base filters if the filters have not been cleared
      baseFilters
    ) {
      if (!queryParams) {
        queryParams = "?";
      }
      Object.keys(baseFilters).forEach((filterKey: string, i: number) => {
        if (!queryParams.includes(filterKey)) {
          queryParams += `${
            queryParams === "?" && i === 0 ? "" : "&"
          }${filterKey}=${baseFilters[filterKey as keyof typeof baseFilters]}`;
        }
      });
    }

    if (constantBaseFilters) {
      if (!queryParams) {
        queryParams = "?";
      }
      Object.keys(constantBaseFilters).forEach(
        (filterKey: string, i: number) => {
          if (!queryParams.includes(filterKey)) {
            queryParams += `${
              queryParams === "?" && i === 0 ? "" : "&"
            }${filterKey}=${
              constantBaseFilters[filterKey as keyof typeof constantBaseFilters]
            }`;
          }
        },
      );
    }

    return queryParams;
  };

  const removeConstantBaseFilters = (_filters: Record<string, string[]>) => {
    const removedBaseFilters = { ..._filters };
    Object.keys(constantBaseFilters ?? {}).forEach((filterKey: string) => {
      delete removedBaseFilters[filterKey];
    });

    return removedBaseFilters;
  };

  const getQueryParams = (_clearedFilters: boolean = false) => {
    let queryParams = "";

    if (query) {
      queryParams = query.replaceAll("%2B", "+");
    } else if (setQueryInURL) {
      queryParams = window.location.search;
    }
    if (
      (setQueryInURL &&
        (queryParams.startsWith("?version") ||
          queryParams.startsWith("?filter"))) ||
      _clearedFilters
    ) {
      queryParams = "";
    }

    queryParams = addBaseFilters(queryParams, _clearedFilters);

    return queryParams;
  };

  const buildFilters = (url: string): Record<string, string[]> => {
    const _filter: Record<string, string[]> = {};
    url.split("&").map((obj) => {
      const keyValue = obj.split("=");
      const key = keyValue?.[0]?.replace("?", "") ?? "";

      if (key) {
        if (_filter[key]) {
          _filter[key].push(keyValue[1].replaceAll("%20", " "));
        } else {
          _filter[key] = [keyValue[1].replaceAll("%20", " ")];
        }
      }
    });

    return _filter;
  };

  const buildAndSetQueryFilter = (
    _filters: Record<string, string[]>,
    addFilters = false,
  ) => {
    let newFilters = {};

    setUsingSavedFilterName("");

    if (addFilters) {
      newFilters = { ...filters, ..._filters };
    } else {
      newFilters = _filters;
    }

    const query = buildQuery(newFilters);
    const queryToSend = buildQuery(newFilters, true);

    setQuery(queryToSend, query);
    setFilters(newFilters);

    if (query) {
      setQueryInURL &&
        window.history.pushState(null, "", `${query}${window.location.hash}`);
    } else {
      setQueryInURL &&
        window.history.replaceState(
          null,
          "",
          `${window.location.pathname}${window.location.hash}`,
        );
    }
  };

  // Use effect being used here bc when newFilter is set via freeform we need to rebuild the query and the only way to access the latest
  // state is to use the hook
  useEffect(() => {
    // If the filter is a custom filter then we need to just close the popover because it
    // is just 1 value that is being added
    if (
      newFilter?.identifier &&
      newFilter?.type !== "MULTI_OPTION" &&
      newFilter?.type !== "SINGLE_OPTION"
    ) {
      if (
        newFilter?.type === "FREEFORM" ||
        // @ts-expect-error - This may also be a type from the API
        newFilter?.type === "DATE"
      ) {
        setCloseMenu(true);
        setSecondaryFilters((_secondaryFilters) => [
          ..._secondaryFilters,
          newFilter.identifier as string, // casting this as string because it is not undefined
        ]);
        setTimeout(() => setCloseMenu(false), 50);
        setNewFilter(null);
      }

      buildAndSetQueryFilter(
        { [newFilter.identifier]: [newFilter.value] },
        true,
      );
    }
  }, [newFilter]);

  const clearFilters = () => {
    setQueryInURL &&
      window.history.replaceState(
        null,
        "",
        `${window.location.pathname}${window.location.hash}`,
      );
    setFiltersCleared(true);
    const newQuery = getQueryParams(true);
    setQuery(newQuery, newQuery);
    setFilters(buildFilters(newQuery));
    setPrimarySearchFilter("");
    setSecondaryFilters([]);
  };

  const findParentIdentifier = (
    identifier: string,
    filterData: AdvancedFilterFilterAPIProps[],
  ): string => {
    let id = identifier;

    const foundFilter = filterData.find((o) => o.identifier === id);

    if (foundFilter?.group) {
      id = `${foundFilter.group.replaceAll(" ", "_").toLowerCase()}.${id}`;
    } else {
      return id;
    }

    return findParentIdentifier(id, filterData);
  };

  const loadFilters = async () => {
    const useIncomingFilter =
      incomingFilters !== null && incomingFilters !== undefined;
    const useDataVizFilters =
      dataVizFilters !== null && dataVizFilters !== undefined;

    let loadingTimeout: NodeJS.Timeout | null = null;
    let isRequestCompleted = false;

    const startLoading = () => {
      loadingTimeout = setTimeout(() => {
        if (!isRequestCompleted) {
          setLoading(true);
          loadingCallback && loadingCallback(true);
        }
      }, 250);
    };

    const stopLoading = () => {
      if (loadingTimeout) {
        clearTimeout(loadingTimeout);
      }
      setLoading(false);
      loadingCallback && loadingCallback(false);
    };

    if (noFilters) {
      stopLoading();
      return;
    }

    startLoading();
    const params: { excluded_filter?: string[]; excluded_group?: string[] } = {
      excluded_filter: undefined,
      excluded_group: undefined,
    };

    if (hiddenFilters) {
      params.excluded_filter = hiddenFilters;
    }

    if (hiddenGroups) {
      params.excluded_group = hiddenGroups;
    }

    // Load options for advanced filters if not using incoming filters or data viz filters
    let advFilterData: SearchAdvancedFilterAPIProps = {
      app: "",
      model: "",
      search_fields: [],
      filters: [],
    };
    if (!useDataVizFilters && !useIncomingFilter) {
      advFilterData = await axios
        .get(`${app}${urlPattern ? `/${urlPattern}` : ""}/advanced_filters`, {
          params,
          paramsSerializer: (params) =>
            queryString.stringify(params, {
              arrayFormat: "index",
            }),
        })
        .then((res) => res.data.result)
        .catch((err) => {
          internalPageError("Something went wrong loading search");
          setErrors((err) => {
            return { ...err, main: "Error loading search" };
          });
          console.error(err);
        });
    }

    // Load saved filters
    let searchFiltersResult = [];

    if (app) {
      searchFiltersResult = await axios
        .get("search/filters")
        .then((res) => res.data.result)
        .catch((err) => {
          internalPageError("Something went wrong loading saved views");
          setErrors((err) => {
            return { ...err, view: "Error loading saved views" };
          });

          console.error(err);
          return [];
        });
    }

    isRequestCompleted = true;

    const searchFilters = searchFiltersResult.filter(
      (o: { app: string; model: string; key: string }) =>
        o.app === app &&
        (o.model === advFilterData.model || o.model === model) &&
        o.key === pageKey,
    );

    let newFilters: Record<string, string[]> = {
      search: [],
    };

    if (
      window.location.search.includes("filter") ||
      query?.includes("filter")
    ) {
      const slug = window.location.search.split("=")?.[1];

      const foundFilter = searchFilters.find(
        (o: { slug: string }) => o.slug === slug,
      );

      if (foundFilter) {
        newFilters = buildFilters(foundFilter.query);

        if (
          newFilters &&
          "search" in newFilters &&
          (newFilters.search as string[])[0]
        ) {
          setPrimarySearchFilter((newFilters.search as string[])[0]);
        }

        setUsingSavedFilterName(foundFilter.name);

        const sendQuery = buildQuery(newFilters, true);
        const dotQuery = buildQuery(newFilters);
        setQuery(sendQuery, dotQuery);
      }
    } else {
      newFilters = buildFilters(getQueryParams());
    }

    const cleanFilterData = (_filterData: AdvancedFilterFilterAPIProps[]) => {
      const ungroupedFilters: FilterOptionProps[] = [];
      const groupedFilters: Record<string, FilterOptionProps[]> = {};

      const cleanOptions = (
        _options:
          | AdvancedFilterFilterAPIProps
          | AdvancedFilterFilterAPIProps[]
          | undefined,
      ): FilterOptionProps | FilterOptionProps[] | undefined => {
        if (!_options) {
          return undefined;
        } else if (_options && Array.isArray(_options)) {
          return _options.map(cleanOptions) as FilterOptionProps[];
        }

        let customOptions = undefined;
        if (
          _options.type &&
          _options.type !== "MULTI_OPTION" &&
          _options.type !== "SINGLE_OPTION"
        ) {
          const optionProps = {
            identifier: `${_options.identifier}`
              .replaceAll(`${_options.gt_suffix}`, "")
              .replaceAll(`${_options.lt_suffix}`, ""),
            type: _options.type,
            gt_suffix: _options.gt_suffix,
            lt_suffix: _options.lt_suffix,
            filters,
          };

          customOptions = (
            <Options
              {...optionProps}
              useOnBlur
              onChange={(id, e) => {
                // Only do something if the value is not empty
                if (e === "") {
                  return;
                }

                const foundFilter = _filterData.find(
                  (o) => o.identifier === _options.identifier,
                );

                if (foundFilter) {
                  let identifier = findParentIdentifier(
                    _options.identifier,
                    _filterData,
                  );

                  // If id includes a suffix we need to readd it to the identifier
                  if (id.includes(`${foundFilter.gt_suffix}`)) {
                    identifier += foundFilter.gt_suffix;
                  } else if (id.includes(`${foundFilter.lt_suffix}`)) {
                    identifier += foundFilter.lt_suffix;
                  }

                  setNewFilter({
                    ...foundFilter,
                    custom: true,
                    options: undefined,
                    label: foundFilter.name,
                    identifier,
                    value: e,
                  });
                }
              }}
            />
          );
        }

        // Ensure options is an array even if it DNE
        if (
          (_options.type === "MULTI_OPTION" ||
            _options.type === "SINGLE_OPTION") &&
          !_options.options
        ) {
          _options.options = [];
        }

        return {
          ..._options,
          customOptions,
          label: _options.name,
          value: _options.identifier,
          options: cleanOptions(
            _options.options as AdvancedFilterFilterAPIProps[],
          ),
        } as FilterOptionProps;
      };

      _filterData.forEach((o) => {
        const cleanedFilter = cleanOptions(o) as FilterOptionProps;
        if (o.group) {
          if (!groupedFilters[o.group]) {
            groupedFilters[o.group] = [cleanedFilter];
          } else {
            groupedFilters[o.group].push(cleanedFilter);
          }
        } else {
          ungroupedFilters.push(cleanedFilter);
        }
      });

      const _returnData = [...ungroupedFilters];
      Object.keys(groupedFilters).forEach((d) => {
        _returnData.push({
          label: d,
          value: d.replaceAll(" ", "_").toLowerCase(),
          options: groupedFilters[d],
        });
      });

      return _returnData;
    };

    if (newFilters.search?.[0]) {
      setPrimarySearchFilter(newFilters.search[0]);
    }

    if (useDataVizFilters) {
      setFilterData(transformDataVizFilterOptions(dataVizFilters));
    } else if (useIncomingFilter) {
      setFilterData(incomingFilters);
    } else if (advFilterData.filters) {
      setFilterData(cleanFilterData(advFilterData.filters));
    }

    // If the first load and the query string includes a dot (which indicates grouping) then we need to build the query filter
    // and resend the query to the component. However, this is bad UX because the table WILL refresh once the advanced filters are loaded
    if (firstLoad && window.location.search.includes(".")) {
      buildAndSetQueryFilter(newFilters);
      setFirstLoad(false);
    }

    setCurrentModel(model || advFilterData.model);
    setFilters(newFilters);
    setSecondaryFilters(
      Object.keys(newFilters).filter(
        (key) =>
          !primaryFilters?.includes(key) &&
          !hiddenFilters?.includes(key) &&
          !Object.keys(baseFilters || {})?.includes(key) &&
          !Object.keys(constantBaseFilters || {})?.includes(key),
      ),
    );
    setSavedFilters(searchFilters);
    setLoading(false);
    loadingCallback && loadingCallback(false);
  };

  useEffect(() => {
    loadFilters();
    if (setQueryInURL) {
      const currentQuery = getQueryParams();
      setQuery(currentQuery, currentQuery);
    }

    if (refresh) {
      setAdvancedFilterRefresh(false);
    }
  }, [refresh, JSON.stringify(incomingFilters), dataVizFilters]);

  const findFilter = (
    id: string,
    filters: FilterOptionProps[],
  ): FilterOptionProps | undefined => {
    let _id = id;
    let splitId: string[] = [];
    if (id.includes(".")) {
      splitId = id.split(".");
      _id = splitId[0];
    }

    const foundFilter = filters.find(
      (obj) =>
        obj.value === _id ||
        `${obj.value}${obj?.gt_suffix}` === _id ||
        `${obj.value}${obj?.lt_suffix}` === _id,
    );

    if (splitId.length > 1 && foundFilter?.options) {
      splitId.shift();
      return findFilter(splitId.join("."), foundFilter.options);
    }

    return foundFilter;
  };

  const renderFilterSelect = (
    identifier: string,
    useOnBlur: boolean = false,
  ) => {
    const filter = findFilter(identifier, filterData);
    if (!filter) {
      console.error(`Filter with identifier ${identifier} not found`);
      return null;
    }

    if (filter?.customOptions) {
      // Check to see if the custom options has multiple values in secondary filters - if it does only render one!
      if (
        identifier.includes(`${filter.gt_suffix}`) &&
        secondaryFilters.includes(
          identifier.replace(`${filter.gt_suffix}`, `${filter.lt_suffix}`),
        )
      ) {
        return null;
      }

      return (
        <CustomOptionsPopover
          key={identifier}
          useOnBlur={useOnBlur}
          data={{
            ...filter,
            identifier: `${identifier}`
              .replaceAll(`${filter.gt_suffix}`, "")
              .replaceAll(`${filter.lt_suffix}`, ""),
          }}
          filters={filters}
          onChange={(newFilters) => {
            buildAndSetQueryFilter({ ...filters, ...newFilters });
          }}
          menuClosed={() => {
            setSecondaryFilters(
              secondaryFilters.filter((o) => filters[o]?.length > 0),
            );
            setNewFilter(null);
          }}
          clearValues={() => {
            const _newFilters = { ...filters };
            delete _newFilters[`${identifier}`];
            delete _newFilters[`${identifier}${filter?.gt_suffix}`];
            delete _newFilters[`${identifier}${filter?.lt_suffix}`];
            setSecondaryFilters(
              secondaryFilters.filter((o) => o !== identifier),
            );
            buildAndSetQueryFilter(_newFilters);
          }}
        />
      );
    }

    const filterOptions = filter.options;

    return (
      <Select
        multiValues
        checkbox
        selectedValuesAtTop
        key={identifier}
        showSelectAll={filterOptions && filterOptions?.length <= 100}
        options={filterOptions}
        value={filters[identifier] ?? []}
        onChange={(e: string[]) => {
          const _newFilters = { ...filters };
          if (e?.length === 0) {
            delete _newFilters[identifier];
          } else {
            _newFilters[identifier] = e;
          }

          buildAndSetQueryFilter(_newFilters);
        }}
        menuClosed={() => {
          setSecondaryFilters(
            secondaryFilters.filter((o) => filters[o]?.length > 0),
          );
        }}
        customComponent={
          <FilterButton
            label={filter.label}
            values={
              filters[identifier]?.map(
                (o) => filterOptions?.find((f) => f.value === o)?.label || "",
              ) ?? []
            }
            clearValues={() => {
              const _newFilters = { ...filters };
              delete _newFilters[identifier];
              delete _newFilters[`${identifier}${filter?.gt_suffix}`];
              delete _newFilters[`${identifier}${filter?.lt_suffix}`];
              setSecondaryFilters(
                secondaryFilters.filter((o) => o !== identifier),
              );
              buildAndSetQueryFilter(_newFilters);
            }}
          />
        }
      />
    );
  };

  const renderNewFilter = () => {
    if (newFilter?.custom) {
      return (
        <CustomOptionsPopover
          opened
          data={{
            ...newFilter,
            identifier: `${newFilter.identifier}`
              .replaceAll(`${newFilter.gt_suffix}`, "")
              .replaceAll(`${newFilter.lt_suffix}`, ""),
          }}
          filters={filters}
          onChange={(newFilters) => {
            buildAndSetQueryFilter({ ...filters, ...newFilters });
          }}
          menuClosed={() => {
            const _secondaryFilters = secondaryFilters.filter(
              (o) => filters[o]?.length > 0,
            );

            if (
              newFilter?.identifier &&
              filters[newFilter.identifier]?.length > 0
            ) {
              _secondaryFilters.push(newFilter.identifier);
            }

            setSecondaryFilters(_secondaryFilters);
            setNewFilter(null);
          }}
        />
      );
    }

    let filterOptions: FilterOptionProps[] = [];

    if (newFilter) {
      filterOptions = newFilter.options ?? [];
    } else {
      filterOptions = filterData
        .filter((o) => {
          const values = [
            o.value,
            `${o.value}${o?.gt_suffix ?? ""}`,
            `${o.value}${o?.lt_suffix ?? ""}`,
          ];

          return (
            !primaryFilters?.some((f) => values.includes(f)) &&
            !secondaryFilters.some((f) => values.includes(f))
          );
        })
        .map((o) => {
          return {
            ...o,
            options: o.options?.filter((f) => {
              const values = [
                `${o.value}.${f.value}`,
                `${o.value}.${f.value}${f?.gt_suffix ?? ""}`,
                `${o.value}.${f.value}${f?.lt_suffix ?? ""}`,
              ];

              return (
                !primaryFilters?.some((f) => values.includes(f)) &&
                !secondaryFilters.some((f) => values.includes(f))
              );
            }),
          };
        });
    }

    return (
      <Select
        multiValues
        checkbox
        showSelectAll={filterOptions.length <= 100}
        selectedValuesAtTop
        closeMenu={closeMenu}
        label={newFilter ? newFilter.label : "Filters"}
        type={newFilter ? "basic" : "breadcrumbs"}
        options={filterOptions}
        value={filters[`${newFilter?.identifier}`] ?? []}
        onChange={(e: string[], identifier?: string) => {
          if (identifier && !newFilter) {
            const foundFilter = findFilter(identifier, filterData);
            if (foundFilter) {
              setNewFilter({ ...foundFilter, identifier });
            }
          }

          if (!identifier && newFilter) {
            identifier = newFilter.identifier;
          }

          const _newFilters = { ...filters };
          if (identifier) {
            if (e.length === 0) {
              delete _newFilters[identifier];
            } else {
              _newFilters[identifier] = e;
            }
          }

          buildAndSetQueryFilter(_newFilters);
        }}
        menuClosed={() => {
          const _secondaryFilters = secondaryFilters.filter(
            (o) => filters[o]?.length > 0,
          );

          if (
            newFilter?.identifier &&
            filters[newFilter.identifier]?.length > 0
          ) {
            _secondaryFilters.push(newFilter.identifier);
          }

          setNewFilter(null);
          setSecondaryFilters(_secondaryFilters);
        }}
        customComponent={
          <FilterButton
            addButton={!newFilter}
            label={newFilter ? newFilter.label : "Add Filter"}
            values={
              (newFilter &&
                filters[newFilter.identifier ?? ""]?.map(
                  (o) =>
                    newFilter.options?.find((f) => f.value === o)?.label || "",
                )) ??
              []
            }
          />
        }
      />
    );
  };

  const filtersActive = Object.keys(filters).some(
    (key) =>
      filters[key]?.length &&
      !hiddenFilters?.includes(key) &&
      !Object.keys(constantBaseFilters || {})?.includes(key),
  );

  const renderViewButtons = () => {
    if (hideSavedFilters || loading || errors.view) return null;

    if (filtersActive) {
      return (
        <Button
          text={
            usingSavedFilterName ? `View: ${usingSavedFilterName}` : "Save View"
          }
          type="secondary"
          onClick={() => setFilterModalOpened("newFilter")}
          disabled={!!usingSavedFilterName}
          style={{
            marginBottom: gridSpacing[2],
          }}
        />
      );
    }

    return (
      <Button
        text="All Views"
        type="secondary"
        icon="fa-list"
        onClick={() => setFilterModalOpened(true)}
        style={{
          marginBottom: gridSpacing[2],
        }}
        disabled={savedFilters.length === 0}
        tooltip={savedFilters.length === 0 ? "No saved views" : undefined}
      />
    );
  };

  return (
    <>
      <SaveFilterModal
        isOpened={filterModalOpened}
        close={() => setFilterModalOpened(false)}
        app={`${app}`}
        model={currentModel}
        pageKey={pageKey}
        query={buildQuery(removeConstantBaseFilters(filters), false)}
        filters={filters}
        constantFilters={[constantBaseFilters ?? {}]}
        savedFilters={savedFilters}
        openSavedFilter={({ name, query, slug }) => {
          setQueryInURL &&
            window.history.replaceState(
              null,
              "",
              `?filter=${slug}${window.location.hash}`,
            );
          const newFilters = buildFilters(addBaseFilters(query));
          setFilters(newFilters);
          setSecondaryFilters(
            Object.keys(newFilters).filter(
              (key) =>
                !primaryFilters?.includes(key) &&
                !hiddenFilters?.includes(key) &&
                !Object.keys(baseFilters || {})?.includes(key) &&
                !Object.keys(constantBaseFilters || {})?.includes(key),
            ),
          );

          if (newFilters.search?.[0]) {
            setPrimarySearchFilter(newFilters.search[0]);
          }

          const sendQuery = buildQuery(newFilters, true);
          const dotQuery = buildQuery(newFilters);
          setQuery(sendQuery, dotQuery);
          setUsingSavedFilterName(name);
          setFilterModalOpened(false);
        }}
        saveFilter={(data) => {
          setSavedFilters([...savedFilters, data]);
          setFilterModalOpened(false);
          setQueryInURL &&
            window.history.replaceState(
              null,
              "",
              `?filter=${data.slug}${window.location.hash}`,
            );
          setUsingSavedFilterName(data.name);
        }}
        removeFilter={(data) =>
          setSavedFilters(savedFilters.filter((o) => o.slug !== data.slug))
        }
      />
      <div style={{ flex: 1 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap-reverse",
          }}
        >
          {!searchTextHidden && (
            <SearchText
              searchButton
              value={primarySearchFilter}
              onChange={(e) => {
                setPrimarySearchFilter(e.target.value);
                if (!e.target.value) {
                  const _filters = { ...filters };
                  delete _filters.search;
                  buildAndSetQueryFilter(_filters);
                }
              }}
              onEnter={() => {
                const _filters = { ...filters, search: [primarySearchFilter] };
                buildAndSetQueryFilter(_filters);
              }}
              style={{
                marginRight: gridSpacing[2],
                minWidth: searchTextWidth ?? 365,
                marginBottom: gridSpacing[2],
                width: searchTextWidth,
              }}
              placeholder={
                errors.main
                  ? "Error loading search"
                  : loading
                    ? "Loading Filters..."
                    : undefined
              }
              disabled={loading}
            />
          )}
          {!inlineViewButtons && renderViewButtons()}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FiltersContainer>
            {!loading && !errors.main && (
              <>
                {primaryFilters?.map((id) => renderFilterSelect(id, true))}
                {quickFilters?.map((filter) => (
                  <FilterButton
                    key={filter.name}
                    label={filter.name}
                    onClick={() => {
                      const _filters = { ...filters, ...filter.filters };
                      buildAndSetQueryFilter(_filters);
                    }}
                  />
                ))}

                {secondaryFilters?.map((id) => renderFilterSelect(id))}
                {renderNewFilter()}
                {filtersActive && (
                  <Button
                    small
                    style={{ padding: 0, margin: 0, minWidth: 0 }}
                    text="Clear all"
                    type="neutralLink"
                    onClick={clearFilters}
                  />
                )}
              </>
            )}
          </FiltersContainer>
          {inlineViewButtons && renderViewButtons()}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: {
  main: { advancedFilters: { refresh: boolean } };
}) => {
  return {
    refresh: Boolean(state?.main?.advancedFilters?.refresh),
  };
};

export default connect(mapStateToProps, { setAdvancedFilterRefresh })(
  AdvancedFilters,
);
