import React, { useContext, useState } from "react";

import { Table, TableLink, DataViz } from "@commonComponents";
import { PROVIDER_360_URLS } from "@urls";

import { numberWithCommas, gridSpacing } from "@utils";

import { PortalContext } from "../../Portal/Portal";
import { topProviderStatisticsQuery } from "../CapacityAnalysis/Queries";
import Header from "~/src/CommonComponents/DataVisualization/Common/Header";
import { setTableExport } from "~/src/CommonComponents/TableExport/TableExport";
import type { ColumnMapItem } from "~/src/CommonComponents/TableExport/TableExport";
import { parseToFixed } from "~/src/utils/stringUtils";
import type {
  CubeTableColumns,
  CubeTableCSVColumns,
} from "~/src/utils/tsUtils";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

export const topProviderColumns: CubeTableColumns<
  typeof topProviderStatisticsQuery
> = [
  {
    Header: "Provider Name",
    Cell: (props) => (
      <TableLink
        newTab
        url={`${PROVIDER_360_URLS.PROVIDER_DIRECTORY_PROVIDERS}/view/${props.row.original["assigned_drilldown.provider_id"]}`}
      >
        {props.row.original["assigned_drilldown.provider_first_name"]}{" "}
        {props.row.original["assigned_drilldown.provider_last_name"]}
      </TableLink>
    ),
    sticky: "left",
  },
  {
    Header: "Panel Name",
    accessor: "assigned_drilldown.panel_name",
    Cell: (props) => (
      <TableLink
        newTab
        url={`${PROVIDER_360_URLS.ATTRIBUTION_PANELS}/view/${props.row.original["assigned_drilldown.panel_id"]}`}
      >
        {props.row.original["assigned_drilldown.panel_name"]}
      </TableLink>
    ),
  },
  {
    Header: "Total Assigned",
    accessor: "assigned_drilldown.total_assigned",
    Cell: (props) =>
      numberWithCommas(props.row.original["assigned_drilldown.total_assigned"]),
  },
  {
    Header: "Base Capacity",
    accessor: "assigned_drilldown.total_base_capacity",
    Cell: (props) =>
      numberWithCommas(
        props.row.original["assigned_drilldown.total_base_capacity"],
      ),
  },
  {
    Header: "Base Capacity Ratio",
    Cell: (props) =>
      parseToFixed({
        input:
          parseInt(props.row.original["assigned_drilldown.total_assigned"]) /
          parseInt(
            props.row.original["assigned_drilldown.total_base_capacity"],
          ),
        multiplier: 100,
        postFix: "%",
      }),
  },
  {
    Header: "Total Capacity",
    accessor: "assigned_drilldown.total_max_capacity",
    Cell: (props) =>
      numberWithCommas(
        props.row.original["assigned_drilldown.total_max_capacity"],
      ),
  },
  {
    Header: "Total Capacity Ratio",
    Cell: (props) =>
      parseToFixed({
        input:
          parseInt(props.row.original["assigned_drilldown.total_assigned"]) /
          parseInt(props.row.original["assigned_drilldown.total_max_capacity"]),
        multiplier: 100,
        postFix: "%",
      }),
  },
  {
    Header: "Assigned Provider Seen",
    accessor: "assigned_drilldown.total_assigned_540_day_flag",
    Cell: (props) =>
      parseToFixed({
        input:
          parseFloat(
            props.row.original[
              "assigned_drilldown.total_assigned_540_day_flag"
            ],
          ) /
          parseFloat(props.row.original["assigned_drilldown.total_assigned"]),
        postFix: "%",
        multiplier: 100,
      }),
  },
  {
    Header: "Any Provider Seen",
    accessor: "assigned_drilldown.total_any_540_day_flag",
    Cell: (props) =>
      parseToFixed({
        input:
          parseFloat(
            props.row.original["assigned_drilldown.total_any_540_day_flag"],
          ) /
          parseFloat(props.row.original["assigned_drilldown.total_assigned"]),
        postFix: "%",
        multiplier: 100,
      }),
  },
];

const dimensions = [
  "assigned_drilldown.roster_market_segment_current",
  "assigned_drilldown.roster_payer_name_current",
  "assigned_drilldown.panel_name",
  "assigned_drilldown.provider_npi",
  "assigned_drilldown.provider_last_name",
  "assigned_drilldown.provider_first_name",
  "assigned_drilldown.facility_state",
  "assigned_drilldown.facility_zip",
  "assigned_drilldown.facility_county",
  "assigned_drilldown.facility_region",
];

export const csvDownloadColumnMap: CubeTableCSVColumns<
  typeof topProviderStatisticsQuery
> = [
  {
    csvColumn: "provider_first_name",
    jsColumn: "assigned_drilldown.provider_first_name",
  },
  {
    csvColumn: "provider_last_name",
    jsColumn: "assigned_drilldown.provider_last_name",
  },
  {
    csvColumn: "panel_name",
    jsColumn: "assigned_drilldown.panel_name",
  },
  {
    csvColumn: "provider_id",
    jsColumn: "assigned_drilldown.provider_id",
  },
  {
    csvColumn: "total_assigned",
    jsColumn: "assigned_drilldown.total_assigned",
  },
  {
    csvColumn: "base_capacity",
    jsColumn: "assigned_drilldown.total_base_capacity",
  },
  {
    csvColumn: "total_capacity",
    jsColumn: "assigned_drilldown.total_max_capacity",
  },
  {
    csvColumn: "assigned_provider_seen",
    jsColumn: "assigned_drilldown.total_assigned_540_day_flag",
  },
  {
    csvColumn: "any_provider_seen",
    jsColumn: "assigned_drilldown.total_any_540_day_flag",
  },
];

const ProviderAnalysis = () => {
  const { filters, filterOrder } = useContext(PortalContext);
  const [filterValue, setFilterValue] = useState("");

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions,
  });

  const {
    resultSet: topProviderResult,
    isLoading: topProviderLoading,
    error: topProviderError,
  } = useCubeApiQuery(topProviderStatisticsQuery(filters, filterOrder));

  const topProviderData =
    topProviderResult
      ?.rawData()
      .filter(
        (obj) =>
          obj["assigned_drilldown.total_assigned"] &&
          obj["assigned_drilldown.provider_id"],
      ) ?? [];

  let totalBase = 0;
  let totalCapacity = 0;
  let totalAssigned = 0;

  topProviderData.forEach((o) => {
    totalBase += parseInt(o["assigned_drilldown.total_base_capacity"]) || 0;
    totalCapacity += parseInt(o["assigned_drilldown.total_max_capacity"]) || 0;
    totalAssigned += parseInt(o["assigned_drilldown.total_assigned"]) || 0;
  });

  totalBase = parseInt(`${totalBase / topProviderData.length}`);
  totalCapacity = parseInt(`${totalCapacity / topProviderData.length}`);
  totalAssigned = parseInt(`${totalAssigned / topProviderData.length}`);

  const filteredData = topProviderData?.filter((obj) =>
    JSON.stringify(obj).toLowerCase().includes(filterValue.toLowerCase()),
  );

  return (
    <>
      <DataViz.Row>
        <DataViz.Gauge
          loading={topProviderLoading}
          error={topProviderError && "Something went wrong"}
          title="Average Base Capacity Ratio"
          toolTip="Average number of individuals assigned to a provider compared to the average of provider base capacity"
          numeratorTitle="Assigned"
          numerator={totalAssigned}
          denominatorTitle="Base"
          denominator={totalBase}
        />
        <DataViz.Gauge
          loading={topProviderLoading}
          error={topProviderError && "Something went wrong"}
          title="Average Total Capacity Ratio"
          toolTip="Average number of individuals assigned to a provider compared to the average of provider total capacity (base + flex)"
          numeratorTitle="Assigned"
          numerator={totalAssigned}
          denominatorTitle="Capacity"
          denominator={totalCapacity}
        />
      </DataViz.Row>
      <Header
        title="Provider Statistics"
        toolTip="Key top level metrics of providers"
      />
      <Table
        filterValue={filterValue}
        filterOnChange={(e) => setFilterValue(e.target.value)}
        isLoading={topProviderLoading}
        columns={topProviderColumns}
        data={filteredData}
        style={{ marginBottom: gridSpacing[4] }}
        defaultSorted={[
          {
            id: "assigned_drilldown.total_assigned",
          },
        ]}
        exportAction={() =>
          setTableExport({
            data: filteredData,
            exportName: "Provider Analysis",
            columnMap: csvDownloadColumnMap as ColumnMapItem[],
          })
        }
      />
    </>
  );
};

export default ProviderAnalysis;
