// @atlaskit/design-system/ensure-design-token-usage
// styled-no-color-value/no-color-value
// TODO: Figure out box shadow

export default {
  primaryD2: "#032D4F",
  primaryD1: "#043D6B",
  primary: "#005B93",
  primaryL1: "#0A8EDF",
  primaryL2: "#78BBE3",
  primaryL3: "#C7EAFF",
  primaryL4: "#F5FBFF",
  primaryL5: "#FBFDFF",

  infoD2: "#002863",
  infoD1: "#004AB7",
  info: "#186CE9",
  infoL1: "#BAD1F3",
  infoL2: "#E3EEFF",
  infoL3: "#F0F6FF",
  infoL4: "#F8FBFF",
  infoL5: "#FBFDFF",

  neutral: "#212A31",
  neutralL1: "#616161",
  neutralL2: "#8F8F8F",
  neutralL3: "#BCBCBC",
  neutralL4: "#E8E8E8",
  neutralL5: "#F2F2F2",
  neutralL6: "#FAFAFA",
  white: "#fff",
  black: "#000",
  transparent: "transparent",

  successD2: "#103A2B",
  successD1: "#186146",
  success: "#038153",
  successL1: "#039C64",
  successL2: "#3EB78D",
  successL3: "#8FC8B4",
  successL4: "#ABDCCA",
  successL5: "#D9EFE7",

  warningD2: "#874716",
  warningD1: "#AD5918",
  warning: "#ED8F1C",
  warningL1: "#FCFC4A",
  warningL2: "#EDB066",
  warningL3: "#F8CD99",
  warningL4: "#FDDEB8",
  warningL5: "#FFEBD2",

  dangerD1: "#661C22",
  dangerD2: "#8C232C",
  danger: "#CC3340",
  dangerL1: "#DC6A74",
  dangerL2: "#EBA8AE",
  dangerL3: "#F0B7BB",
  dangerL4: "#F4CED0",
  dangerL5: "#FFE7E8",

  discoveryD2: "#241336",
  discoveryD1: "#3D1F5B",
  discovery: "#7039A8",
  discoveryL1: "#8B4ACD",
  discoveryL2: "#A376D1",
  discoveryL3: "#D8BCFD",
  discoveryL4: "#EEE1FE",
  discoveryL5: "#F7F1FF",
};
