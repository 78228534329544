import React from "react";

export const NoModels = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    width="250"
    height="250"
    viewBox="0 0 250 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g filter="url(#filter0_i_1852_5263)">
      <rect
        x="29.1666"
        y="29.1667"
        width="191.667"
        height="191.667"
        rx="95.8333"
        fill="#B1B5B7"
        fillOpacity="0.2"
      />
    </g>
    <g filter="url(#filter1_d_1852_5263)">
      <g clipPath="url(#clip0_1852_5263)">
        <rect
          x="40.1787"
          y="78.75"
          width="169.643"
          height="110.607"
          rx="5.42857"
          fill="white"
        />
        <circle cx="49.6784" cy="88.2502" r="2.71429" fill="#005B93" />
        <circle cx="57.8212" cy="88.2502" r="2.71429" fill="#CCCCCC" />
        <circle cx="65.9642" cy="88.2502" r="2.71429" fill="#23A6F9" />
        <line
          x1="40.1788"
          y1="97.4112"
          x2="230.179"
          y2="97.4112"
          stroke="#B2C4D7"
          strokeOpacity="0.3"
          strokeWidth="0.678571"
        />
      </g>
      <rect
        x="40.518"
        y="79.0893"
        width="168.964"
        height="109.929"
        rx="5.08929"
        stroke="#B2C4D7"
        strokeWidth="0.678571"
      />
    </g>
    <g filter="url(#filter2_d_1852_5263)">
      <g clipPath="url(#clip1_1852_5263)">
        <rect
          x="30"
          y="55"
          width="190"
          height="122.143"
          rx="5.42857"
          fill="white"
        />
        <rect
          x="115.5"
          y="84.856"
          width="92.2857"
          height="6.78571"
          rx="3.39286"
          fill="#DEDEDE"
        />
        <rect
          x="115.5"
          y="97.0706"
          width="92.2857"
          height="6.78571"
          rx="3.39286"
          fill="#DEDEDE"
        />
        <rect
          x="180.643"
          y="145.928"
          width="27.1429"
          height="6.78571"
          rx="3.39286"
          fill="#DEDEDE"
        />
        <rect
          x="180.643"
          y="158.142"
          width="27.1429"
          height="6.78571"
          rx="3.39286"
          fill="#DEDEDE"
        />
        <rect
          x="115.5"
          y="109.285"
          width="27.1429"
          height="6.78571"
          rx="3.39286"
          fill="#DEDEDE"
        />
        <rect
          x="42.2139"
          y="133.713"
          width="111.286"
          height="6.78571"
          rx="3.39286"
          fill="#DEDEDE"
        />
        <rect
          x="42.2139"
          y="145.928"
          width="111.286"
          height="6.78571"
          rx="3.39286"
          fill="#DEDEDE"
        />
        <rect
          x="42.2139"
          y="158.142"
          width="46.1429"
          height="6.78571"
          rx="3.39286"
          fill="#DEDEDE"
        />
        <rect
          x="42.2139"
          y="84.856"
          width="65.1429"
          height="38"
          rx="2.71429"
          fill="#F0F1F1"
        />
        <circle cx="74.7859" cy="103.856" r="13.5714" fill="#005B93" />
        <path
          // eslint-disable-next-line max-len
          d="M86.4945 110.718C87.7206 108.626 88.3638 106.244 88.3573 103.819C88.3507 101.395 87.6947 99.016 86.4574 96.9307C85.22 94.8454 83.4466 93.1297 81.3215 91.962C79.1965 90.7943 76.7973 90.2173 74.3737 90.2909L74.7859 103.856L86.4945 110.718Z"
          fill="#23A6F9"
        />
        <circle cx="39.5002" cy="64.5002" r="2.71429" fill="#005B93" />
        <circle cx="47.643" cy="64.5002" r="2.71429" fill="#CCCCCC" />
        <circle cx="55.7859" cy="64.5002" r="2.71429" fill="#23A6F9" />
        <line
          x1="30"
          y1="73.6612"
          x2="220"
          y2="73.6612"
          stroke="#B2C4D7"
          strokeOpacity="0.3"
          strokeWidth="0.678571"
        />
        <rect
          x="163.678"
          y="129.642"
          width="44.1071"
          height="82.1071"
          rx="4.07143"
          fill="#F0F1F1"
        />
      </g>
      <rect
        x="30.3393"
        y="55.3393"
        width="189.321"
        height="121.464"
        rx="5.08929"
        stroke="#B2C4D7"
        strokeWidth="0.678571"
      />
    </g>
    <rect
      x="185.357"
      y="42.3571"
      width="49.2857"
      height="49.2857"
      rx="6.78571"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M212.57 54.144H207.427V64.4289H197.142V69.5717H207.427V79.8583H212.57V69.5717H222.856V64.4289H212.57V54.144Z"
      fill="#23A6F9"
    />
    <rect
      x="185.357"
      y="42.3571"
      width="49.2857"
      height="49.2857"
      rx="6.78571"
      stroke="#B2C4D7"
      strokeWidth="0.714286"
    />
    <defs>
      <filter
        id="filter0_i_1852_5263"
        x="29.1666"
        y="29.1667"
        width="191.667"
        height="191.667"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="8.33333" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1852_5263"
        />
      </filter>
      <filter
        id="filter1_d_1852_5263"
        x="33.393"
        y="77.3929"
        width="183.214"
        height="124.179"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.42857" />
        <feGaussianBlur stdDeviation="3.39286" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1852_5263"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1852_5263"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_1852_5263"
        x="23.2143"
        y="53.6429"
        width="203.571"
        height="135.714"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.42857" />
        <feGaussianBlur stdDeviation="3.39286" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1852_5263"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1852_5263"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1852_5263">
        <rect
          x="40.1787"
          y="78.75"
          width="169.643"
          height="110.607"
          rx="5.42857"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_1852_5263">
        <rect
          x="30"
          y="55"
          width="190"
          height="122.143"
          rx="5.42857"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
