const capitalizeFirstLetters = (input) => {
  if (input) {
    return input
      .toLowerCase()
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  }
  return "";
};

export default capitalizeFirstLetters;
