import React, { useContext, useState } from "react";

import {
  DataViz,
  Actions,
  PivotTable,
  SearchText,
  TableLink,
  Tabs,
} from "@commonComponents";
import { type TQueryOrderArray } from "@cubejs-client/core";
import { type ColumnDef, type GroupingState } from "@tanstack/react-table";
import { PROVIDER_360_URLS } from "@urls";

import ProviderAvailabilityKPI from "./ProviderAvailabilityKPI";
import {
  scheduleAvailabilityProviderTableQuery,
  scheduleAvailabilityKPIQuery,
  scheduleAvailabilityProviderFacilityTableQuery,
  scheduleAvailabilityProviderByApptTypeTableQuery,
  scheduleAvailabilityProviderFacilityByApptTypeTableQuery,
} from "./Queries";
import {
  getAvailabilityColorSquare,
  getUtilizationColorSquare,
  getUtilizationColor,
  colorLegend,
  nullFallback,
} from "../common";
import { PortalContext } from "~/src/App/LeapInsights/Components/Portal/Portal";
import Header from "~/src/CommonComponents/DataVisualization/Common/Header";
import { setTableExport } from "~/src/CommonComponents/TableExport/TableExport";
import type { ColumnMapItem } from "~/src/CommonComponents/TableExport/TableExport";
import { useCubeJsApi } from "~/src/utils/CubeJsApiContext";
import { parseToFixed } from "~/src/utils/stringUtils";
import type {
  CSVColumns,
  CubePagination,
  CubeQueryResultRawDataType,
} from "~/src/utils/tsUtils";
import {
  cubeFetch,
  internalPageError,
  useCubeApiQuery,
} from "~/src/utils/tsUtils";

type ProviderTableQueryRawData = CubeQueryResultRawDataType<
  typeof scheduleAvailabilityProviderTableQuery
>;
type ProviderFacilityTableQueryRawData = CubeQueryResultRawDataType<
  typeof scheduleAvailabilityProviderFacilityTableQuery
>;
type ProviderByApptTypeTableQueryRawData = CubeQueryResultRawDataType<
  typeof scheduleAvailabilityProviderByApptTypeTableQuery
>;
type ProviderFacilityByApptTypeTableQueryRawData = CubeQueryResultRawDataType<
  typeof scheduleAvailabilityProviderFacilityByApptTypeTableQuery
>;

type ProviderFacilityRow = ProviderTableQueryRawData[number] &
  ProviderFacilityTableQueryRawData[number] &
  ProviderByApptTypeTableQueryRawData[number] &
  ProviderFacilityByApptTypeTableQueryRawData[number];

const providerCSVDownloadColumnMap: CSVColumns<ProviderFacilityRow> = [
  {
    csvColumn: "provider_full_name",
    jsColumn:
      "schedule_availability_provider_summary_appt_type.schedule_provider_full_name",
  },
  {
    csvColumn: "provider_id",
    jsColumn:
      "schedule_availability_provider_summary_appt_type.provider_directory_provider_id",
  },
  {
    csvColumn: "next_available",
    jsColumn:
      "schedule_availability_provider_summary_appt_type.min_next_available",
  },
  {
    csvColumn: "second_next_available",
    jsColumn:
      "schedule_availability_provider_summary_appt_type.min_second_next_available",
  },
  {
    csvColumn: "third_next_available",
    jsColumn:
      "schedule_availability_provider_summary_appt_type.min_third_next_available",
  },
  {
    csvColumn: "fourth_next_available",
    jsColumn:
      "schedule_availability_provider_summary_appt_type.min_fourth_next_available",
  },
  {
    csvColumn: "week_1_availability",
    jsColumn:
      "schedule_availability_provider_summary_appt_type.available_week_1_utilization",
  },
  {
    csvColumn: "week_2_availability",
    jsColumn:
      "schedule_availability_provider_summary_appt_type.available_week_2_utilization",
  },
  {
    csvColumn: "week_3_availability",
    jsColumn:
      "schedule_availability_provider_summary_appt_type.available_week_3_utilization",
  },
  {
    csvColumn: "week_4_availability",
    jsColumn:
      "schedule_availability_provider_summary_appt_type.available_week_4_utilization",
  },
];

const mapProviderDataForExport = (data: ProviderFacilityRow[]) =>
  data.map((row) => {
    return {
      ...row,
      "schedule_availability_provider_summary_appt_type.available_week_1_utilization":
        parseToFixed({
          multiplier: 100,
          input:
            row[
              "schedule_availability_provider_summary_appt_type.available_week_1_utilization"
            ],
          postFix: "%",
          invalidOutput: "-",
        }),
      "schedule_availability_provider_summary_appt_type.available_week_2_utilization":
        parseToFixed({
          multiplier: 100,
          input:
            row[
              "schedule_availability_provider_summary_appt_type.available_week_2_utilization"
            ],
          postFix: "%",
          invalidOutput: "-",
        }),
      "schedule_availability_provider_summary_appt_type.available_week_3_utilization":
        parseToFixed({
          multiplier: 100,
          input:
            row[
              "schedule_availability_provider_summary_appt_type.available_week_3_utilization"
            ],
          postFix: "%",
          invalidOutput: "-",
        }),
      "schedule_availability_provider_summary_appt_type.available_week_4_utilization":
        parseToFixed({
          multiplier: 100,
          input:
            row[
              "schedule_availability_provider_summary_appt_type.available_week_4_utilization"
            ],
          postFix: "%",
          invalidOutput: "-",
        }),
    };
  });

const pageSize = 25;

const ProviderScheduleAnalysis = () => {
  const { filters, filterOrder } = useContext(PortalContext);
  const [grouping, setGrouping] = useState<GroupingState>([
    "schedule_provider_full_name",
  ]);
  const [sorting, setSorting] = useState<TQueryOrderArray>([]);
  const [filterValue, setFilterValue] = React.useState("");
  const [pagination, setPagination] = useState<CubePagination>({
    limit: pageSize,
    offset: 0,
  });
  const [currentTab, setCurrentTab] = useState<"all" | "byType">("all");
  const { cubejsApi } = useCubeJsApi();

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: [
      "schedule_availability_provider_summary_appt_type.schedule_provider_full_name",
      "schedule_availability_provider_summary_appt_type.schedule_provider_specialty",
      "schedule_availability_provider_facility_summary_appt_type.schedule_appointment_type",
    ],
  });

  const {
    resultSet: scheduleAvailabilityKPIResult,
    error: scheduleAvailabilityKPIError,
    isLoading: scheduleAvailabilityKPILoading,
  } = useCubeApiQuery(scheduleAvailabilityKPIQuery(filters, filterOrder));

  const { resultSet: scheduleAvailabilityProviderTableResult } =
    useCubeApiQuery(
      scheduleAvailabilityProviderTableQuery(filters, sorting, filterOrder),
    );

  const { resultSet: scheduleAvailabilityProviderByApptTypeTableResult } =
    useCubeApiQuery(
      scheduleAvailabilityProviderByApptTypeTableQuery(
        filters,
        sorting,
        filterOrder,
      ),
    );

  const week1Utilization = +parseToFixed({
    multiplier: 100,
    input:
      scheduleAvailabilityKPIResult?.rawData()[0]?.[
        "schedule_availability_provider_summary_appt_type.available_week_1_utilization"
      ],
  });
  const week2Utilization = +parseToFixed({
    multiplier: 100,
    input:
      scheduleAvailabilityKPIResult?.rawData()[0]?.[
        "schedule_availability_provider_summary_appt_type.available_week_2_utilization"
      ],
  });
  const week3Utilization = +parseToFixed({
    multiplier: 100,
    input:
      scheduleAvailabilityKPIResult?.rawData()[0]?.[
        "schedule_availability_provider_summary_appt_type.available_week_3_utilization"
      ],
  });
  const week4Utilization = +parseToFixed({
    multiplier: 100,
    input:
      scheduleAvailabilityKPIResult?.rawData()[0]?.[
        "schedule_availability_provider_summary_appt_type.available_week_4_utilization"
      ],
  });

  /* eslint-disable max-len */
  const getColumns = (
    byApptType: boolean = false,
  ): ColumnDef<ProviderFacilityRow>[] => [
    {
      accessorFn: (row) =>
        row[
          "schedule_availability_provider_summary_appt_type.schedule_provider_full_name"
        ],
      getGroupingValue: (row) =>
        row[
          "schedule_availability_provider_summary_appt_type.provider_directory_provider_id"
        ],
      header: "Provider",
      id: "schedule_provider_full_name",
      size: 300,
      cell: (props) => {
        if (
          props.row.original[
            "schedule_availability_provider_summary_appt_type.provider_directory_provider_id"
          ]
        ) {
          return (
            <TableLink
              newTab
              url={`${PROVIDER_360_URLS.PROVIDER_DIRECTORY_PROVIDERS}/view/${props.row.original["schedule_availability_provider_summary_appt_type.provider_directory_provider_id"]}`}
              style={{ display: "inline" }}
            >
              {
                props.row.original[
                  "schedule_availability_provider_summary_appt_type.schedule_provider_full_name"
                ]
              }
            </TableLink>
          );
        } else {
          return (
            <span>
              {
                props.row.original[
                  "schedule_availability_provider_summary_appt_type.schedule_provider_full_name"
                ]
              }
            </span>
          );
        }
      },
    },
    {
      accessorFn: (row) =>
        row[
          "schedule_availability_provider_facility_summary_appt_type.schedule_facility_name"
        ],
      header: "Facility",
      id: "schedule_facility_name",
      size: 200,
      enableSorting: false,
      cell: (props) => {
        if (
          !!props.row.original[
            "schedule_availability_provider_facility_summary_appt_type.provider_directory_facility_id"
          ] &&
          !!props.row.original[
            "schedule_availability_provider_facility_summary_appt_type.schedule_facility_name"
          ]
        ) {
          return (
            <TableLink
              newTab
              url={`${PROVIDER_360_URLS.PROVIDER_DIRECTORY_FACILITIES}/view/${props.row.original["schedule_availability_provider_facility_summary_appt_type.provider_directory_facility_id"]}`}
            >
              {nullFallback(
                props.row.original[
                  "schedule_availability_provider_facility_summary_appt_type.schedule_facility_name"
                ],
              )}
            </TableLink>
          );
        } else {
          return (
            <span>
              {nullFallback(
                props.row.original[
                  "schedule_availability_provider_facility_summary_appt_type.schedule_facility_name"
                ],
              )}
            </span>
          );
        }
      },
    },
    ...(byApptType
      ? [
          {
            enableSorting: false,
            accessorFn: (row: ProviderFacilityRow) =>
              row[
                "schedule_availability_provider_facility_summary_appt_type.schedule_appointment_type"
              ] ?? "",
            header: "Appointment Type",
            id: "schedule_appointment_type",
          },
        ]
      : []),
    {
      accessorFn: (row) =>
        row[
          "schedule_availability_provider_summary_appt_type.min_next_available"
        ] ??
        row[
          "schedule_availability_provider_summary_appt_type.min_next_available"
        ],
      id: "min_next_available",
      header: "Next Available",
      cell: (props) =>
        getAvailabilityColorSquare(
          props.row.original[
            "schedule_availability_provider_summary_appt_type.min_next_available"
          ] === undefined
            ? props.row.original[
                "schedule_availability_provider_facility_summary_appt_type.min_next_available"
              ]
            : props.row.original[
                "schedule_availability_provider_summary_appt_type.min_next_available"
              ],
        ),
    },
    {
      accessorFn: (row) =>
        row[
          "schedule_availability_provider_summary_appt_type.min_second_next_available"
        ] ??
        row[
          "schedule_availability_provider_facility_summary_appt_type.min_second_next_available"
        ],
      id: "min_second_next_available",
      header: "2nd Next Available",
      cell: (props) =>
        getAvailabilityColorSquare(
          props.row.original[
            "schedule_availability_provider_summary_appt_type.min_second_next_available"
          ] === undefined
            ? props.row.original[
                "schedule_availability_provider_facility_summary_appt_type.min_second_next_available"
              ]
            : props.row.original[
                "schedule_availability_provider_summary_appt_type.min_second_next_available"
              ],
        ),
    },
    {
      accessorFn: (row) =>
        row[
          "schedule_availability_provider_summary_appt_type.min_third_next_available"
        ] ??
        row[
          "schedule_availability_provider_facility_summary_appt_type.min_third_next_available"
        ],
      id: "min_third_next_available",
      header: "3rd Next Available",
      cell: (props) =>
        getAvailabilityColorSquare(
          props.row.original[
            "schedule_availability_provider_summary_appt_type.min_third_next_available"
          ] === undefined
            ? props.row.original[
                "schedule_availability_provider_facility_summary_appt_type.min_third_next_available"
              ]
            : props.row.original[
                "schedule_availability_provider_summary_appt_type.min_third_next_available"
              ],
        ),
    },
    {
      accessorFn: (row) =>
        row[
          "schedule_availability_provider_summary_appt_type.min_fourth_next_available"
        ] ??
        row[
          "schedule_availability_provider_facility_summary_appt_type.min_fourth_next_available"
        ],
      id: "min_fourth_next_available",
      header: "4th Next Available",
      cell: (props) =>
        getAvailabilityColorSquare(
          props.row.original[
            "schedule_availability_provider_summary_appt_type.min_fourth_next_available"
          ] === undefined
            ? props.row.original[
                "schedule_availability_provider_facility_summary_appt_type.min_fourth_next_available"
              ]
            : props.row.original[
                "schedule_availability_provider_summary_appt_type.min_fourth_next_available"
              ],
        ),
    },
    {
      id: "available_week_1_utilization",
      header: "Week 1 Availability",
      cell: (props) =>
        getUtilizationColorSquare(
          props.row.original[
            "schedule_availability_provider_summary_appt_type.available_week_1_utilization"
          ] === undefined
            ? props.row.original[
                "schedule_availability_provider_facility_summary_appt_type.available_week_1_utilization"
              ]
            : props.row.original[
                "schedule_availability_provider_summary_appt_type.available_week_1_utilization"
              ],
        ),
    },
    {
      id: "available_week_2_utilization",
      header: "Week 2 Availability",
      cell: (props) =>
        getUtilizationColorSquare(
          props.row.original[
            "schedule_availability_provider_summary_appt_type.available_week_2_utilization"
          ] === undefined
            ? props.row.original[
                "schedule_availability_provider_facility_summary_appt_type.available_week_2_utilization"
              ]
            : props.row.original[
                "schedule_availability_provider_summary_appt_type.available_week_2_utilization"
              ],
        ),
    },
    {
      id: "available_week_3_utilization",
      header: "Week 3 Availability",
      cell: (props) =>
        getUtilizationColorSquare(
          props.row.original[
            "schedule_availability_provider_summary_appt_type.available_week_3_utilization"
          ] === undefined
            ? props.row.original[
                "schedule_availability_provider_facility_summary_appt_type.available_week_3_utilization"
              ]
            : props.row.original[
                "schedule_availability_provider_summary_appt_type.available_week_3_utilization"
              ],
        ),
    },
    {
      id: "available_week_4_utilization",
      header: "Week 4 Availability",
      cell: (props) =>
        getUtilizationColorSquare(
          props.row.original[
            "schedule_availability_provider_summary_appt_type.available_week_4_utilization"
          ] === undefined
            ? props.row.original[
                "schedule_availability_provider_facility_summary_appt_type.available_week_4_utilization"
              ]
            : props.row.original[
                "schedule_availability_provider_summary_appt_type.available_week_4_utilization"
              ],
        ),
    },
  ];
  /* eslint-enable max-len */

  const totalRows =
    scheduleAvailabilityProviderTableResult?.rawData().length ?? 0;

  return (
    <>
      <ProviderAvailabilityKPI filters={filters} filterOrder={filterOrder} />
      <DataViz.Row>
        <DataViz.BarChart
          error={scheduleAvailabilityKPIError && "Something went wrong"}
          loading={scheduleAvailabilityKPILoading}
          title="Average Available Appointment Slot"
          // eslint-disable-next-line max-len
          toolTip="The percentage of weekly available appointment slots out of the total appointment slots across all providers and appointment types. Refer to the schedule analysis table below for details on color coding."
          yAxisLabel="Available Appointment %"
          xAxisLabel="Week"
          maxNumber={100}
          formatter={function () {
            return `${this.series.name} ${this.x}: ${this.y?.toFixed(2)}% (${
              // @ts-expect-error - z is not defined in the highcharts point object
              this.point.z
            } Slots)`;
          }}
          highlightTooltipSuffix="% ({point.z:,.0f})"
          data={[
            {
              showInLegend: false,
              name: "Week",
              data: [
                {
                  y: week1Utilization,
                  z: +(
                    scheduleAvailabilityKPIResult?.rawData()[0]?.[
                      "schedule_availability_provider_summary_appt_type.available_week_1_slot_count_avg"
                    ] ?? "0"
                  ),
                  color: getUtilizationColor(week1Utilization).color,
                  x: 1,
                },
                {
                  y: week2Utilization,
                  z: +(
                    scheduleAvailabilityKPIResult?.rawData()[0]?.[
                      "schedule_availability_provider_summary_appt_type.available_week_2_slot_count_avg"
                    ] ?? "0"
                  ),
                  color: getUtilizationColor(week2Utilization).color,
                  x: 2,
                },
                {
                  y: week3Utilization,
                  z: +(
                    scheduleAvailabilityKPIResult?.rawData()[0]?.[
                      "schedule_availability_provider_summary_appt_type.available_week_3_slot_count_avg"
                    ] ?? "0"
                  ),
                  color: getUtilizationColor(week3Utilization).color,
                  x: 3,
                },
                {
                  y: week4Utilization,
                  z: +(
                    scheduleAvailabilityKPIResult?.rawData()[0]?.[
                      "schedule_availability_provider_summary_appt_type.available_week_4_slot_count_avg"
                    ] ?? "0"
                  ),
                  color: getUtilizationColor(week4Utilization).color,
                  x: 4,
                },
              ],
            },
          ]}
        />
      </DataViz.Row>
      <Tabs currentTab={currentTab} onSelect={setCurrentTab}>
        <Tabs.Content tabKey="all" title="Appointment Summary">
          <Header
            title="Provider Schedule Analysis"
            toolTip="Days for next 4 available appointments, and percentage of weekly availability per provider and provider-facility for all appointment types."
            subtitle={colorLegend}
          />
          <Actions>
            <Actions.Filters>
              <SearchText
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              />
            </Actions.Filters>
          </Actions>
          <PivotTable
            columns={getColumns()}
            grouping={grouping}
            setGrouping={setGrouping}
            pinnedColumns={[
              "schedule_provider_full_name",
              "schedule_facility_name",
            ]}
            pagination={{
              pageSize: pagination.limit,
              rowOffset: pagination.offset,
              totalRows: totalRows ?? 0,
            }}
            onSortingChange={(newSorting) => {
              setSorting(
                newSorting.map((sortObj) => [
                  `schedule_availability_provider_summary_appt_type.${sortObj.id}`,
                  sortObj.desc ? "desc" : "asc",
                ]),
              );
            }}
            data={
              (
                scheduleAvailabilityProviderTableResult
                  ?.rawData()
                  .filter(
                    (row) =>
                      !!row[
                        "schedule_availability_provider_summary_appt_type.schedule_provider_full_name"
                      ],
                  )
                  .filter((row) => {
                    const search = filterValue.toLowerCase();
                    return row[
                      "schedule_availability_provider_summary_appt_type.schedule_provider_full_name"
                    ]
                      .toLowerCase()
                      .includes(search);
                  }) ?? []
              ).slice(
                pagination.offset,
                pagination.offset + pagination.limit,
              ) as ProviderFacilityRow[]
            }
            getSubRows={async (row) => {
              if (row.depth > 0) return [];

              const newFilters = {
                ...filters,
              };
              delete newFilters["?provider.schedule_provider_full_name"];
              delete newFilters["provider.schedule_provider_full_name"];
              delete newFilters["?provider.schedule_provider_specialty"];
              delete newFilters["provider.schedule_provider_specialty"];
              const res = await cubeFetch(
                cubejsApi,
                scheduleAvailabilityProviderFacilityTableQuery(
                  {
                    "schedule_availability_provider_facility_summary_appt_type.provider_directory_provider_id":
                      row.original[
                        "schedule_availability_provider_summary_appt_type.provider_directory_provider_id"
                      ],
                    ...newFilters,
                  },
                  filterOrder,
                ),
              )
                .then((res) => res.rawData())
                .catch(() =>
                  internalPageError(
                    "Something went wrong fetching the facilities for the provider.",
                  ),
                );

              return res as ProviderFacilityRow[];
            }}
            childCountProp="schedule_availability_provider_facility_summary_appt_type.facility_count_distinct"
            onPaginationChange={(newPagination) => {
              setPagination({
                ...pagination,
                offset: newPagination.rowOffset,
              });
            }}
            exportAction={async () => {
              const providerData = await cubeFetch(
                cubejsApi,
                scheduleAvailabilityProviderTableQuery(
                  filters,
                  sorting,
                  filterOrder,
                ),
              )
                .then((res) => res.rawData())
                .catch(() =>
                  internalPageError(
                    "Something went wrong fetching the providers for export.",
                  ),
                );

              if (providerData) {
                setTableExport({
                  data: mapProviderDataForExport(
                    providerData as ProviderFacilityRow[],
                  ),
                  columnMap: providerCSVDownloadColumnMap as ColumnMapItem[],
                  exportName: "Appointment Summary",
                });
              }
            }}
          />
        </Tabs.Content>
        <Tabs.Content tabKey="byType" title="By Appointment Type">
          <Header
            title="Provider Schedule Analysis"
            toolTip="Days for next 4 available appointments, and percentage of weekly availability per provider and provider-facility for selected appointment types."
            subtitle={colorLegend}
          />
          <Actions>
            <Actions.Filters>
              <SearchText
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              />
            </Actions.Filters>
          </Actions>
          <PivotTable
            columns={getColumns(true)}
            grouping={grouping}
            setGrouping={setGrouping}
            pinnedColumns={[
              "schedule_provider_full_name",
              "schedule_facility_name",
              "schedule_appointment_type",
            ]}
            pagination={{
              pageSize: pagination.limit,
              rowOffset: pagination.offset,
              totalRows: totalRows ?? 0,
            }}
            onSortingChange={(newSorting) => {
              setSorting(
                newSorting.map((sortObj) => [
                  `schedule_availability_provider_summary_appt_type.${sortObj.id}`,
                  sortObj.desc ? "desc" : "asc",
                ]),
              );
            }}
            data={
              (
                scheduleAvailabilityProviderByApptTypeTableResult
                  ?.rawData()
                  .filter(
                    (row) =>
                      !!row[
                        "schedule_availability_provider_summary_appt_type.schedule_provider_full_name"
                      ],
                  )
                  .filter((row) => {
                    const search = filterValue.toLowerCase();
                    return row[
                      "schedule_availability_provider_summary_appt_type.schedule_provider_full_name"
                    ]
                      .toLowerCase()
                      .includes(search);
                  }) ?? []
              ).slice(
                pagination.offset,
                pagination.offset + pagination.limit,
              ) as ProviderFacilityRow[]
            }
            getSubRows={async (row) => {
              if (row.depth > 0) return [];

              const newFilters = {
                ...filters,
              };
              delete newFilters["?provider.schedule_provider_full_name"];
              delete newFilters["provider.schedule_provider_full_name"];
              delete newFilters["?provider.schedule_provider_specialty"];
              delete newFilters["provider.schedule_provider_specialty"];

              const res = await cubeFetch(
                cubejsApi,
                scheduleAvailabilityProviderFacilityByApptTypeTableQuery(
                  {
                    "schedule_availability_provider_facility_summary_appt_type.provider_directory_provider_id":
                      row.original[
                        "schedule_availability_provider_summary_appt_type.provider_directory_provider_id"
                      ],
                    ...newFilters,
                  },
                  filterOrder,
                ),
              )
                .then((res) => res.rawData())
                .catch(() =>
                  internalPageError(
                    "Something went wrong fetching the facilities for the provider.",
                  ),
                );

              return res as ProviderFacilityRow[];
            }}
            childCountProp="schedule_availability_provider_facility_summary_appt_type.appointment_type_count_distinct"
            onPaginationChange={(newPagination) => {
              setPagination({
                ...pagination,
                offset: newPagination.rowOffset,
              });
            }}
            exportAction={async () => {
              const providerData = await cubeFetch(
                cubejsApi,
                scheduleAvailabilityProviderTableQuery(
                  filters,
                  sorting,
                  filterOrder,
                ),
              )
                .then((res) => res.rawData())
                .catch(() =>
                  internalPageError(
                    "Something went wrong fetching the providers for export.",
                  ),
                );

              if (providerData) {
                setTableExport({
                  data: mapProviderDataForExport(
                    providerData as ProviderFacilityRow[],
                  ),
                  columnMap: providerCSVDownloadColumnMap as ColumnMapItem[],
                  exportName: "By Appointment Type",
                });
              }
            }}
          />
        </Tabs.Content>
      </Tabs>
    </>
  );
};

export default ProviderScheduleAnalysis;
