import React from "react";

import { Collapse } from "@commonComponents";
import { styled } from "styled-components";

import { colorTheme, gridSpacing } from "@utils";

const SubjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colorTheme("neutralL4")};
  border-top: none;
  border-radius: 0px 0px 6px 6px;
  padding: ${gridSpacing[4]}px ${gridSpacing[5]}px;
`;

const SubjectHeader = styled.div<{ $isCollapsed?: boolean }>`
  height: ${gridSpacing[5]}px;
  padding: ${gridSpacing[2]}px ${gridSpacing[5]}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colorTheme("neutralL5")};
  border-radius: ${({ $isCollapsed }) =>
    $isCollapsed ? "6px" : "6px 6px 0px 0px"};
  border-bottom: 1px solid ${colorTheme("neutralL4")};
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: ${colorTheme("neutralL4")};
  }
`;

const CollapseIcon = styled.i`
  font-size: 14px;
  padding-left: ${gridSpacing[2]}px;
  cursor: pointer;
`;

const Icon = styled.i`
  margin-right: ${gridSpacing[1]}px;
  font-size: 22px;
`;

type GroupedObjectsProps = {
  isCollapsed: boolean;
  toggleGroup: () => void;
  icon: string;
  title: string;
  childrenCount: number;
  description?: string;
  content: React.ReactNode;
  iconStyle?: React.CSSProperties;
  type?: string;
};

const GroupedObjects = ({
  isCollapsed,
  toggleGroup,
  icon,
  title,
  childrenCount,
  description,
  content,
  iconStyle,
  type = "dashboard",
}: GroupedObjectsProps) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginBottom: gridSpacing[3],
    }}
  >
    <SubjectHeader $isCollapsed={isCollapsed} onClick={toggleGroup}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icon className={icon} style={iconStyle} />
        {title}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {`${childrenCount} ${type}${childrenCount === 1 ? "" : "s"}`}
        <CollapseIcon
          style={{ marginLeft: gridSpacing[3] }}
          role="button"
          className={`fa-solid fa-chevron-${!isCollapsed ? "down" : "right"}`}
          onClick={toggleGroup}
        />
      </div>
    </SubjectHeader>
    <Collapse isOpened={!isCollapsed}>
      <SubjectContainer>
        <div
          style={{
            marginBottom: gridSpacing[5],
            fontSize: 14,
            color: colorTheme("neutralL1"),
          }}
        >
          {description}
        </div>
        <div style={{ margin: `0 ${gridSpacing[3]}px` }}>{content}</div>
      </SubjectContainer>
    </Collapse>
  </div>
);

export default GroupedObjects;
