import React, { useState, useEffect } from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import type { MarginInterface } from "../utilities/interfaces";
import { getMargins } from "../utilities/margin";

const FormGroup = styled.div<{ $error: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  input {
    border-color: ${({ $error }) =>
      $error ? colorTheme("danger") : colorTheme("neutralL3")};
  }
`;

const TextInput = styled.input<{ disabled: boolean }>`
  background-color: ${({ disabled }) =>
    disabled ? colorTheme("neutralL4") : colorTheme("white")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "text")};
  display: block;
  padding: 3px 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: ${colorTheme("neutral")};
  background-clip: padding-box;
  border: 1px solid ${colorTheme("neutralL3")};
  border-radius: 5px;
  margin: 0;
  font-family: inherit;
  height: 30px;

  &:active,
  &:focus {
    outline-color: ${colorTheme("primary")};
  }
`;

const HelperText = styled.div`
  width: 100%;
  margin-left: 5px;
  font-size: 14px;
`;

export const Label = styled.label`
  margin-bottom: 0;
`;

const RequiredSpan = styled.span`
  color: ${colorTheme("danger")};
  font-size: 14px;
  margin-left: 1px;
`;

interface TextProps extends MarginInterface {
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  helperStyle?: React.CSSProperties;
  requiredStyle?: React.CSSProperties;
  error?: boolean | string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: string;
  type?: string;
  required?: boolean;
  name?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  reference?: React.RefObject<HTMLInputElement>;
  helperText?: string;
  placeholder?: string;
  min?: number;
  testId?: string;
  autocomplete?: string;
  autofocus?: boolean;
  step?: number;
}

const Text = ({
  style,
  inputStyle,
  labelStyle,
  helperStyle,
  requiredStyle,
  error,
  label = "",
  autofocus = false,
  onChange,
  onEnter,
  value,
  type,
  required,
  name,
  onBlur,
  onKeyPress,
  disabled = false,
  reference,
  helperText,
  placeholder,
  autocomplete,
  min,
  testId,
  step,
  m,
  mx,
  my,
  mt,
  mb,
  mr,
  ml,
}: TextProps) => {
  const [inputPlaceholder, setInputPlaceholder] = useState(placeholder);

  style = { ...getMargins({ m, mx, my, mt, mb, mr, ml }), ...style };

  const setPlaceholder = () => {
    if (!placeholder) {
      setInputPlaceholder(`Enter ${label.toLowerCase()}`);
    } else {
      setInputPlaceholder(placeholder);
    }
  };

  useEffect(() => {
    setPlaceholder();
  }, [placeholder, label]);

  return (
    <FormGroup style={style} $error={!!error}>
      <Label
        style={{
          color: error ? colorTheme("danger") : colorTheme("black"),
          ...labelStyle,
        }}
      >
        {label}
        {required && <RequiredSpan style={requiredStyle}>*</RequiredSpan>}
      </Label>
      <TextInput
        autoFocus={autofocus}
        style={inputStyle}
        onChange={onChange}
        value={value}
        type={type}
        min={min}
        placeholder={inputPlaceholder}
        name={name}
        onBlur={onBlur}
        onKeyDown={(e) => {
          if (onEnter && (e.code === "Enter" || e.code === "NumpadEnter")) {
            onEnter(e);
          } else if (onKeyPress) {
            onKeyPress(e);
          }
        }}
        disabled={disabled}
        ref={reference}
        autoComplete={autocomplete}
        data-testid={testId}
        step={step}
      />
      {error ? (
        <HelperText style={{ color: colorTheme("danger"), ...helperStyle }}>
          {typeof error === "string" ? error : ""}
        </HelperText>
      ) : helperText ? (
        <HelperText style={helperStyle}>{helperText}</HelperText>
      ) : null}
    </FormGroup>
  );
};

export default Text;
