import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import gridSpacing from "../../../utils/gridSpacing";

type SelectAllProps = {
  visible?: boolean;
  selectAll?: () => void;
  deselectAll?: () => void;
  type?: "basic" | "popout" | "accordion" | "breadcrumbs";
  style?: React.CSSProperties;
};

const Container = styled.div<{ $type: string }>`
  display: flex;
  position: sticky;
  top: ${({ $type }) =>
    $type === "accordion" ? gridSpacing[7] : !$type ? gridSpacing[5] : "0"}px;
  background: #fff;
  width: calc(100% - ${gridSpacing[3] * 2}px);
  padding-top: ${gridSpacing[2]}px;
  padding-bottom: ${gridSpacing[2]}px;
  padding-left: ${gridSpacing[3]}px;
  font-weight: 300;
  font-size: 14px;
  z-index: 1;

  span {
    margin: 0 ${gridSpacing[2]}px;
  }
`;

const Text = styled.div`
  color: ${colorTheme("neutral")};
  cursor: pointer;
  font-weight: 400;

  &:hover {
    color: ${colorTheme("primary")};
    text-decoration: underline;
  }
`;

const SelectAll = ({
  visible,
  selectAll,
  deselectAll,
  style,
  type,
}: SelectAllProps) => {
  if (!visible) return null;

  return (
    <Container style={style} $type={type || ""}>
      <Text onClick={selectAll}>Select All</Text>
      <span>|</span>
      <Text onClick={deselectAll}>Deselect All</Text>
    </Container>
  );
};

export default SelectAll;
