import type { ReactNode } from "react";
import React from "react";

import styled from "styled-components";

interface RowProps {
  // The Lucerna way to display information
  // label is used for whatever reason

  /**
   * The title to display in the row.
   */
  title?: string;

  /**
   * The text to display in the row.
   */
  text?: string;

  /**
   * Child elements to include in the row.
   */
  children?: ReactNode;

  /**
   * Whether the row is visible or hidden.
   */
  visible?: boolean;

  /**
   * CSS styles to apply to the row.
   */
  style?: React.CSSProperties;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  b {
    white-space: nowrap;
    margin-right: 5px;
  }
`;

/**
 *
 * @param title {string=} - The title to display in the row.
 * @param text {string=}
 * @param visible {boolean=}
 * @param style {React.CSSProperties=}
 * @param children {ReactNode=}
 */
const Row = ({ title, text, visible, children, style }: RowProps) => {
  let renderRow = text || children;
  if (visible) {
    renderRow = true;
  } else if (typeof visible === "boolean" && visible === false) {
    renderRow = false;
  }

  if (!renderRow) return null;

  return (
    <Container style={style}>
      <b>{title}:</b>
      {text && <p>{text}</p>}
      {children}
    </Container>
  );
};

export default Row;
