import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

const ErrorMessageStyle = styled.div`
  color: ${colorTheme("danger")};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

interface ErrorMessageProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  /** data-testid attribute */
  testId?: string;
}

const ErrorMessage = ({ children, style, testId }: ErrorMessageProps) => (
  <ErrorMessageStyle style={style} data-testid={testId}>
    {children}
  </ErrorMessageStyle>
);

export default ErrorMessage;
