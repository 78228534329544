import React, { useRef, useEffect, useState } from "react";

import { Button, Modal, Select, ErrorMessage } from "@commonComponents";
import axios from "axios";
import moment from "moment";
import Papa from "papaparse";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router";

import { gridSpacing, downloadFile } from "@utils";

import { setImportExport } from "@actions/main";

import store from "~/src/store";

const fileSizeLimit = 2000000000; // 2 GB

const blendingOptions = [
  {
    label: "Replace - remove all existing data, add all data from the file",
    value: "replace",
  },
  {
    label: "Union - keep all existing data, add only new data from the file",
    value: "union",
  },
  {
    label: "Append - keep all existing data, add all data from the file",
    value: "append",
  },
];

const isObject = (obj) => obj !== null && typeof obj === "object";

// Function that will check if two objects contain the same properties with the same values.
// Works recursively to validate objects with properties that contain object values.
const deepEqual = (object1, object2) => {
  if (Object.keys(object1).length !== Object.keys(object2).length) {
    return false;
  }
  for (const [key, val1] of Object.entries(object1)) {
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

const exportAction = (url, accessor, name) => {
  axios
    .get(url)
    .then((res) => {
      let data = res.data.result[accessor];
      store.dispatch(
        setImportExport({ activeAction: "Export", data: data, name: name }),
      );
    })
    .catch((err) => {
      console.error(err);
    });
};

const importAction = (navigate, importLocation, name) => {
  store.dispatch(setImportExport({ activeAction: "Import", name: name }));
  if (importLocation) {
    navigate(importLocation);
  }
};

const ImportExport = ({
  activeAction,
  data,
  setData = null,
  columnMap,
  appName,
  exportName,
  defaultBlending = "replace",
  disabledBlendModes = [],
  setImportExport,
  importLocation = "",
  hasImport,
  hasExport,
  validateAndTransformData = null,
  hiddenColumns,
  importButtonText = "Import",
  exportButtonText = "Export",
  activeActionName,
}) => {
  const uploadFileRef = useRef(null);
  const navigate = useNavigate();

  const [blendMode, setBlendMode] = useState(defaultBlending);
  const [uploadStatus, setUploadStatus] = useState("ready");
  const [file, setFile] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [warning, setWarning] = useState(null);

  useEffect(() => {
    setSuccess(false);
    setUploadStatus("ready");
    setError(null);
    setValidationErrors({});
    setBlendMode(defaultBlending);
  }, [activeAction]);

  useEffect(() => {
    if (blendMode === "replace") {
      setWarning(
        `Warning: This blend mode will remove any existing data in the ${appName}`,
      );
    } else {
      setWarning(null);
    }
  }, [blendMode]);

  // Returns true if uploaded file has proper number of columns and correct headers.
  const validate = (_data) => {
    if (_data.length === 0) {
      setError("No data exists in CSV");
      return false;
    }

    return !_data.some((row) => {
      // Check number of columns
      if (Object.keys(row).length !== columnMap.length) {
        setError(
          `Incorrect number of columns. The uploaded file had ${
            Object.keys(row).length
          } columns and expected ${columnMap.length}`,
        );
        return true;
      }
      // Check headers
      if (columnMap.some((col) => !(col.csvColumn in row))) {
        setError(
          `Incorrect or missing headers, expected: ${columnMap
            .map((col) => col.csvColumn)
            .join(", ")}, found: ${Object.keys(row).join(", ")}`,
        );
        return true;
      }
      return false;
    });
  };

  const buildData = (formattedData) => {
    if (blendMode === "replace") {
      return formattedData;
    } else if (blendMode === "union") {
      let newData = formattedData.filter((newRow) =>
        data.every(
          (row) =>
            !deepEqual(
              Object.keys(newRow)
                .map((row) => {
                  if (!hiddenColumns.includes(row)) {
                    return newRow[row];
                  }
                })
                .filter((obj) => obj !== undefined),
              columnMap.map((col) => row[col.jsColumn]),
            ),
        ),
      );
      return [...data, ...newData];
    } else if (blendMode === "append") {
      return data.concat(formattedData);
    }
  };

  const _import = () => {
    setUploadStatus("retrieving");
    Papa.parse(file, {
      header: true,
      skipEmptyLines: "greedy",
      error: (error) => {
        setError(`Something went wrong parsing the file: ${error}`);
        // TODO: decide on plan for encodings... MVP just utf-8 with plan for expansion? List of encodings to try?
      },
      complete: (results) => {
        if (!validate(results.data)) {
          setFile(null);
          setSuccess(false);
          setUploadStatus("ready");
        } else {
          // Format results
          const formatted_data = results.data.map((row) => {
            const formatted_row = {};
            columnMap.forEach(
              (col) => (formatted_row[col.jsColumn] = row[col.csvColumn]),
            );
            return formatted_row;
          });
          /*
          if (lineByLine) {
            // TODO: https://lucernahealth.atlassian.net/browse/HE2020-3339 This will be added in this PR, blocking it out to ensure this componenet is developed properly
            // Decide where {endpoint} is coming from. prop? can we generate it?
            // Hit {endpoint}
            //    Wait for response
          }
          */
          let errors = {};
          if (validateAndTransformData) {
            errors = validateAndTransformData(formatted_data, blendMode);
          }

          const finalData = buildData(formatted_data);

          if (Object.keys(errors).length === 0) {
            setData(finalData);
            setSuccess(true);
          } else {
            setValidationErrors(errors);
          }

          setUploadStatus("ready");
        }
      },
    });
  };

  useEffect(() => {
    if (file) _import();
  }, [file]);

  const createAndDownloadCSV = (data, filename) => {
    let csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(data);
    downloadFile(csvContent, filename);
  };

  const exportAsCSV = () => {
    let csvString = data.map((row) =>
      columnMap
        .map((col) => {
          if (row[col.jsColumn] && row[col.jsColumn].toString().includes(",")) {
            return JSON.stringify(row[col.jsColumn]);
          }
          return row[col.jsColumn];
        })
        .join(),
    );
    csvString.unshift(columnMap.map((col) => col.csvColumn).join());
    csvString = csvString.join("\n").replace(/\\\\/g, "\\");
    createAndDownloadCSV(
      csvString,
      `${appName.replaceAll(" ", "")}_${exportName}_${moment().format(
        "MM-DD-YYYY",
      )}.csv`,
    );
  };

  const onUpload = () => {
    if (uploadFileRef.current.files.length > 0)
      if (uploadFileRef.current.files[0].size < fileSizeLimit) {
        setFile(uploadFileRef.current.files[0]);
      } else {
        setError(`File too large. Max size is ${fileSizeLimit / 1000000} MB`);
      }
  };

  const renderModalContents = () => {
    if (success) {
      return "Successfully uploaded from file";
    } else if (Object.keys(validationErrors).length > 0) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>File upload failed with the following errors:</div>
          {Object.keys(validationErrors).map((columnName) => (
            <div key={`${columnName}`}>
              <div
                key={`column-${columnName}`}
                style={{ marginLeft: 5, marginTop: 10, fontWeight: "bold" }}
              >
                {columnName}
              </div>
              {validationErrors[columnName].map((error, index) => (
                <ErrorMessage
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginLeft: 10,
                  }}
                  key={index}
                >
                  {error}
                </ErrorMessage>
              ))}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <>
          {activeAction === "Import" && !importLocation && (
            <div>
              <div>
                {`To download this ${appName} as a CSV:`}
                <ol>
                  <li>
                    Choose a blend mode (This will determine how the upload
                    interacts with existing data)
                  </li>
                  <li>Press "Upload File"</li>
                  <li>
                    Navigate to and open the file you wish to import data from
                  </li>
                </ol>
                If you are unsure of the required file format, you can click the
                "Download Template" button in the bottom right to download a
                template with the correct headers.
              </div>
              <Select
                label="Select Blend Mode"
                value={blendMode}
                onChange={(e) => setBlendMode(e)}
                options={blendingOptions.filter(
                  (mode) => !disabledBlendModes.includes(mode.value),
                )}
                style={{ marginTop: 10 }}
              />
              {warning && (
                <ErrorMessage style={{ justifyContent: "flex-start" }}>
                  {warning}
                </ErrorMessage>
              )}
              <input
                hidden
                ref={uploadFileRef}
                accept=".csv"
                type="file"
                onInput={onUpload}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <Button
                  icon="fa-solid fa-file-upload"
                  status={uploadStatus}
                  onClick={() => uploadFileRef.current.click()}
                  text="Upload File"
                  affirmationText="Uploading File"
                />
                <Button
                  text="Download Template"
                  icon="fa-download"
                  type="secondary"
                  onClick={() => {
                    createAndDownloadCSV(
                      columnMap?.map((col) => col.csvColumn).join(),
                      `${appName.replaceAll(" ", "")}_Template.csv`,
                    );
                  }}
                />
              </div>
            </div>
          )}
          {activeAction === "Export" && (
            <>
              <p>
                {`To export the current ${appName}, press "Download File" below`}
                <br />
                {`The downloaded file will have ${data?.length} rows and ${
                  columnMap.length
                } columns: ${columnMap
                  .map((col) => `"${col.csvColumn}"`)
                  .join(", ")}`}
                <br />
                {`To download a template for this ${appName}, you can instead press "Download Template"`}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  style={{ marginTop: 15 }}
                  text="Download File"
                  icon="fa-download"
                  onClick={() => {
                    exportAsCSV();
                    setImportExport({});
                  }}
                />
                <Button
                  style={{ marginTop: 15 }}
                  text="Download Template"
                  icon="fa-download"
                  type="secondary"
                  onClick={() => {
                    createAndDownloadCSV(
                      columnMap?.map((col) => col.csvColumn).join(),
                      `${appName.replaceAll(" ", "")}_Template.csv`,
                    );
                  }}
                />
              </div>
            </>
          )}
          {error && (
            <ErrorMessage style={{ marginTop: 10 }}>{error}</ErrorMessage>
          )}
        </>
      );
    }
  };

  return (
    <>
      <Modal
        isOpened={
          !!activeAction &&
          (activeAction === "Export" || activeActionName === appName)
        }
        title={`${activeAction} ${appName}`}
        toggle={() => setImportExport({})}
        focusTrap={false}
      >
        {renderModalContents()}
        <Modal.Footer>
          {Object.keys(validationErrors).length > 0 && (
            <Button
              type="secondary"
              text="Try Again"
              onClick={() => {
                setValidationErrors({});
              }}
              style={{ marginRight: 10 }}
            />
          )}
          <Button
            type="secondary"
            text="Close"
            onClick={() => {
              setImportExport({});
            }}
          />
        </Modal.Footer>
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: gridSpacing[2],
        }}
      >
        {hasImport && (
          <Button
            icon="fa-solid fa-file-upload"
            text={importButtonText}
            type="secondary"
            onClick={() => {
              setImportExport({ activeAction: "Import", name: appName });
              if (importLocation) {
                navigate(importLocation);
              }
            }}
          />
        )}
        {hasExport && (
          <Button
            style={hasImport && { marginLeft: gridSpacing[2] }}
            icon="fa-download"
            text={exportButtonText}
            onClick={() => {
              setImportExport({ activeAction: "Export", name: appName });
            }}
            type="secondary"
          />
        )}
      </div>
    </>
  );
};

ImportExport.exportAction = exportAction;
ImportExport.importAction = importAction;

ImportExport.propTypes = {
  activeAction: PropTypes.string,
  /** IMPLEMENTATION NOTE:
   * You must supply the data prop unless this modal is on a page where ImportExport.exportAction is a table action
   * This was done to contain all states either in the component or in redux when using the table action functions. */
  data: PropTypes.array,
  setData: PropTypes.func,
  columnMap: PropTypes.array,
  appName: PropTypes.string,
  exportName: PropTypes.string,
  lineByLine: PropTypes.bool,
  defaultBlending: PropTypes.string,
  disabledBlendModes: PropTypes.array,
  setImportExport: PropTypes.func,
  importLocation: PropTypes.string,
  hasImport: PropTypes.bool,
  hasExport: PropTypes.bool,
  /** This function should return an error object of the following structure
  {
    column1: ["error1", "error2"],
    column2: ["error3", "error4"]
  }
  */
  validateAndTransformData: PropTypes.func,
  // Hidden columns that do not need to be checked during the deep copy of a Union. For instance UUIDs in a Custom Table.
  hiddenColumns: PropTypes.array,
  importButtonText: PropTypes.string,
  exportButtonText: PropTypes.string,
  activeActionName: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  let stateProps = {
    activeAction: state?.main?.importExport?.activeAction,
    activeActionName: state?.main?.importExport?.name,
  };
  if (!ownProps.data) {
    stateProps.data = state?.main?.importExport?.data || [];
  }
  if (!ownProps.exportName) {
    stateProps.exportName = state?.main?.importExport?.name || "";
  }
  return stateProps;
};

export default connect(mapStateToProps, { setImportExport })(ImportExport);
