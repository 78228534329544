import React from "react";

import { Button, Modal } from "@commonComponents";
import PropTypes from "prop-types";
import { styled } from "styled-components";

import { colorTheme } from "@utils";

const Divider = styled.hr`
  width: 100%;
  border-top: 1px solid ${colorTheme("neutralL4")};
  border-left: 0px;
  margin: 0;
  margin-top: 25px;
  margin-bottom: 25px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;

  b {
    width: 215px;
  }

  div {
    inline-size: calc(100% - 215px);
    overflow-wrap: break-word;
  }
`;

const ViewModal = ({
  isOpened,
  setIsOpened,
  contents,
  title,
  closeCallback,
}) => {
  const renderContent = () =>
    contents.map((section, sectionIndex) => (
      <div key={sectionIndex}>
        {section.map((row, rowIndex) => {
          if (!row.verticalAligned) {
            return (
              <Row key={rowIndex}>
                <b>{row.title}</b>
                <div>{row.content ? row.content : <i>None</i>}</div>
              </Row>
            );
          } else {
            return (
              <div key={rowIndex}>
                <b>{row.title}</b>
                <div style={{ marginTop: 10, marginLeft: 15 }}>
                  {row.content ? row.content : <i>None</i>}
                </div>
              </div>
            );
          }
        })}
        {sectionIndex < contents.length - 1 && <Divider />}
      </div>
    ));

  return (
    <Modal
      closeShortcuts
      isOpened={isOpened}
      title={title}
      toggle={() => {
        setIsOpened(false);
        closeCallback && closeCallback();
      }}
    >
      <div style={{ padding: 15 }}>{renderContent()}</div>
      <Modal.Footer>
        <Button
          type="secondary"
          text="Close"
          onClick={() => {
            setIsOpened(false);
            closeCallback && closeCallback();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

ViewModal.propTypes = {
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func,
  title: PropTypes.string,
  closeCallback: PropTypes.func,
  /* contents object has the following structure
  [
    [
      {
        title: <string>,
        content: <any>,
        verticalAligned: <bool>
      },...
    ]
  ],...
  */
  contents: PropTypes.array,
};

export default ViewModal;
