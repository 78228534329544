import type { ReactNode } from "react";
import React, { cloneElement, isValidElement, useEffect } from "react";

type ButtonGroupProps = {
  small?: boolean;
  type?: "toggle" | "radio";
  onChange?: (
    value:
      | string
      | number
      | boolean
      | React.SetStateAction<string>
      | React.SetStateAction<number>
      | React.SetStateAction<boolean>,
  ) => void;
  value?: string | number | boolean;
  buttonStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

const ButtonGroup = ({
  small = false,
  type = "toggle",
  onChange,
  value,
  style,
  buttonStyle = {}, // Style for each button
  children,
}: ButtonGroupProps) => {
  let _children: ReactNode[] = [];

  if (!Array.isArray(children)) {
    _children = [children];
  } else {
    _children = children;
  }

  useEffect(() => {
    // Verify that all children are Button components
    const allButtons = _children.every(
      (child) =>
        // @ts-expect-error - displayName should be available
        isValidElement(child) && child?.type?.displayName === "lh-button",
    );

    if (!allButtons) {
      throw new Error(
        "ButtonGroup only accepts Button lucerna health components as children",
      );
    }
  }, []);

  return (
    <div style={{ display: "flex", ...style }}>
      {_children.map((btn, i) => {
        if (isValidElement(btn)) {
          return cloneElement(btn, {
            key: "btn-group-" + i,
            small: small,
            type: type,
            toggled: value === btn.props.value,
            onClick: () => {
              onChange && onChange(btn.props.value);
              if (btn.props.onClick) btn.props.onClick();
            },
            ...btn.props,
            style: {
              borderTopLeftRadius: i === 0 ? "" : 0,
              borderBottomLeftRadius: i === 0 ? "" : 0,
              borderTopRightRadius: i === _children.length - 1 ? "" : 0,
              borderBottomRightRadius: i === _children.length - 1 ? "" : 0,
              borderLeft: i === 0 ? "" : "none",
              minWidth: 0,
              ...buttonStyle,
              ...btn.props.style,
            },
          });
        }
      })}
    </div>
  );
};

export default ButtonGroup;
