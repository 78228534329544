import type { CSSProperties } from "react";
import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

export const StyledDivider = styled.hr`
  width: 100%;
  border-top: 1px solid ${colorTheme("neutralL4")};
  border-left: 0px;
`;

type DividerProps = {
  style?: CSSProperties;
};

const Divider = ({ style }: DividerProps) => <StyledDivider style={style} />;

export default Divider;
