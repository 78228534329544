import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import AdvancedSelect from "./AdvancedSelect/AdvancedSelect";
import SingleSelect from "./SingleSelect";
import type { MarginInterface } from "../utilities/interfaces";
import { getMargins } from "../utilities/margin";

/*
Select new prop names 
    isDisabled -> disabled
    isCreatable -> creatable
    isLoading -> loading
    isMulti -> multiValues
*/

const Label = styled.label`
  margin-bottom: 0;
`;

const HelperText = styled.div`
  width: 100%;
  margin-left: 5px;
  font-size: 14px;
`;

const RequiredSpan = styled.span`
  color: ${colorTheme("danger")};
  font-size: 14px;
  margin-left: 1px;
`;

export type OptionProp = {
  style?: React.CSSProperties;
  label?: string;
  value?: string;
  description?: string;
  disabled?: boolean;
  checkbox?: boolean;
  faIcon?: string;
  showAvatar?: boolean;
  radio?: boolean;
  imgSrc?: string;
  isSelected?: boolean;
  onClick?: () => void;
  options?: OptionProp[];
  customOptions?: React.ReactNode;
  children?: React.ReactNode;
  data?: {
    value?: string;
    disabled?: boolean;
    isSelected?: boolean;
    faIcon?: string;
    imgSrc?: string;
    label?: string;
    description?: string;
    onClick?: () => void;
    options?: OptionProp[];
  };
  innerProps?: {
    disabled?: boolean;
    onClick?: () => void;
  };
};

export type ValueProps = string | string[] | null;

export type SelectStyleProps = {
  helperTextStyle?: React.CSSProperties;
  optionStyle?: React.CSSProperties;
  controlStyle?: React.CSSProperties;
  valueContainerStyle?: React.CSSProperties;
  indicatorsContainerStyle?: React.CSSProperties;
  indicatorSeparatorStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  placeholderStyle?: React.CSSProperties;
  singleValueStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  requiredStyle?: React.CSSProperties;
};

export const getSelectStyleProps = <T extends SelectStyleProps>(props: T) => {
  const {
    helperTextStyle,
    optionStyle,
    controlStyle,
    valueContainerStyle,
    indicatorsContainerStyle,
    indicatorSeparatorStyle,
    inputStyle,
    placeholderStyle,
    singleValueStyle,
    labelStyle,
    requiredStyle,
  } = props;

  return {
    helperTextStyle,
    optionStyle,
    controlStyle,
    valueContainerStyle,
    indicatorsContainerStyle,
    indicatorSeparatorStyle,
    inputStyle,
    placeholderStyle,
    singleValueStyle,
    labelStyle,
    requiredStyle,
  };
};

export type SelectProps<TValue extends ValueProps> = MarginInterface &
  SelectStyleProps & {
    label?: string;
    required?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    multiValues?: boolean;
    type?: "basic" | "popout" | "accordion" | "breadcrumbs";
    creatable?: boolean;
    emptyOption?: boolean;
    loading?: boolean;
    showAvatars?: boolean;
    checkbox?: boolean;
    radio?: boolean;
    noOptionsMessage?: string;
    helperText?: string;
    onBlur?: () => void;
    error?: string | null;
    style?: React.CSSProperties;
    value?: TValue;
    options?: OptionProp[];
    hideSearch?: boolean;
    selectedValuesAtTop?: boolean;
    showSelectAll?: boolean;
    autoFocusSearch?: boolean;
    useLatestSelect?: boolean; // This guarantees that the latest version of the select is used and not react-select
    onChange?: (value: TValue, parentPath?: string) => void;
    placeholder?: string;
    id?: string;
    testId?: string;
    customComponent?: React.ReactNode;
    menuClosed?: () => void;
    menuOpened?: () => void;
    closeMenu?: boolean; // When set to true a useEffect will close the menu
    // Deprecated props
    isDisabled?: boolean;
    isCreatable?: boolean;
    isLoading?: boolean;
    isMulti?: boolean;
  };

const SelectComponent = <TValue extends ValueProps>({
  label,
  required = false,
  style = {},
  labelStyle = {},
  requiredStyle = {},
  helperTextStyle = {},
  helperText,
  m,
  mx,
  my,
  mt,
  mb,
  mr,
  ml,
  ...props
}: SelectProps<TValue>) => {
  // Set deprecated props to new props
  props.disabled = props.isDisabled ?? props.disabled;
  props.creatable = props.isCreatable ?? props.creatable;
  props.loading = props.isLoading ?? props.loading;
  props.multiValues = props.isMulti ?? props.multiValues;

  style = { ...getMargins({ m, mx, my, mt, mb, mr, ml }), ...style };

  const getSelectValue = () => {
    let _selectValue = null;

    const consolidatedOptions: OptionProp[] = [];

    const findOptions = (o: OptionProp[]) => {
      o.forEach((option) => {
        if (option) {
          if (option.options) {
            findOptions(option.options);
          } else {
            consolidatedOptions.push(option);
          }
        }
      });
    };

    if (props.options) {
      findOptions(props.options);
    }

    if (props.options || props.creatable) {
      if (Array.isArray(props.value)) {
        _selectValue = props.value.map((_value) => {
          const find = consolidatedOptions.find(
            (option) => option.value === _value,
          );
          if (find) {
            return { value: `${find.value}`, label: `${find.label}` };
          } else {
            return { label: _value, value: _value };
          }
        });
      } else if (props.options && props.value) {
        const find = consolidatedOptions.find(
          (option) => option.value === props.value,
        );
        if (find) {
          _selectValue = { value: `${find.value}`, label: `${find.label}` };
        } else {
          _selectValue = {
            value: props.value.toString(),
            label: props.value.toString(),
          };
        }
      }

      if (_selectValue === undefined) {
        _selectValue = null;
      }
    }

    return _selectValue;
  };

  // Find Value
  const selectValue = getSelectValue();

  let Component = null;

  const useAdvancedSelect =
    props.useLatestSelect ||
    ["breadcrumbs", "accordion", "popout"].includes(`${props.type}`) ||
    props.customComponent ||
    props.selectedValuesAtTop;

  if (props.creatable && props.useLatestSelect) {
    console.error(
      "Creatable is not supported yet using latest select (not react-select)",
    );
  }

  if (!props.creatable && useAdvancedSelect) {
    Component = AdvancedSelect;
  } else {
    Component = SingleSelect;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", ...style }}>
      {label && !props.customComponent && (
        <Label
          style={{
            color: props.error ? colorTheme("danger") : colorTheme("black"),
            ...labelStyle,
          }}
        >
          {label}
          {required && <RequiredSpan style={requiredStyle}>*</RequiredSpan>}
        </Label>
      )}
      <Component {...props} value={selectValue} label={label} />
      {props.error ? (
        <HelperText style={{ color: colorTheme("danger"), ...helperTextStyle }}>
          {props.error}
        </HelperText>
      ) : helperText ? (
        <HelperText style={helperTextStyle}>{helperText}</HelperText>
      ) : null}
    </div>
  );
};
export default SelectComponent;
