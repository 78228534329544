import { styled } from "styled-components";

import { gridSpacing } from "@utils";

export type FilterProps = {
  type: string;
  search: string;
  analytic_domain: string;
  subdomain: string[];
  managed_by: string;
};

export const ContentWrapper = styled.div`
  height: calc(
    100vh - 295px
  ); // 295px is the height of the top bar + header + tabs
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ObjectsContainer = styled.div`
  margin-bottom: ${gridSpacing[6]}px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${gridSpacing[4]}px;
`;
