import React from "react";

import moment from "moment";
import styled from "styled-components";

export const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

// TODO: Had to recreate subtext component as it was causing circular dependency.
// Will need to refactor/move to use the original component.
// eslint-disable-next-line color-theme/enforce-color-theme
const SubText = styled.div`
  color: #8f8f8f;
  font-size: 12px;
`;

const TableData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const SubTextCell = ({ content, subText }) => (
  <TableData>
    {content}
    <SubText>{subText}</SubText>
  </TableData>
);

export const UserRecordColumns = [
  {
    Header: "Last Modified",
    accessor: "modified",
    width: 70,
    Cell: (props) =>
      props.value ? (
        <SubTextCell
          content={moment(props.value).format("MM/DD/YYYY h:mm a")}
          subText={props.row.original.modified_by_name}
        />
      ) : (
        ""
      ),
  },
];
