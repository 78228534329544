import type { ComponentType } from "react";
import React, { Component } from "react";

import { ErrorMessage } from "@commonComponents";

import BarChart from "./BarChart";
import BulletChart from "./BulletChart";
import { Wrapper } from "./Common/styles";
import * as filterUtils from "./filterUtils";
import FunnelChart from "./FunnelChart";
import Gauge from "./Gauge";
import HeatMap from "./HeatMap";
import LineChart from "./LineChart";
import NumberChart from "./NumberChart";
import PieChart from "./PieChart";
import Row from "./Row";
import ScatterChart from "./ScatterChart";
import WaterfallChart from "./WaterfallChart";

class ChartErrorBoundary extends Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Chart Error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Wrapper
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ErrorMessage style={{ justifyContent: "center" }}>
            Something went wrong rendering this chart
          </ErrorMessage>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

const withErrorBoundary = <P extends object>(
  WrappedComponent: ComponentType<P>,
) =>
  function WithErrorBoundary(props: P) {
    return (
      <ChartErrorBoundary>
        <WrappedComponent {...props} />
      </ChartErrorBoundary>
    );
  };

const wrappedComponents = {
  Row: withErrorBoundary(Row),
  BarChart: withErrorBoundary(BarChart),
  WaterfallChart: withErrorBoundary(WaterfallChart),
  NumberChart: withErrorBoundary(NumberChart),
  Gauge: withErrorBoundary(Gauge),
  LineChart: withErrorBoundary(LineChart),
  PieChart: withErrorBoundary(PieChart),
  FunnelChart: withErrorBoundary(FunnelChart),
  ScatterChart: withErrorBoundary(ScatterChart),
  HeatMap: withErrorBoundary(HeatMap),
  BulletChart: withErrorBoundary(BulletChart),
  filterUtils,
};

type DataViz = typeof wrappedComponents;

const DataViz: DataViz = wrappedComponents;

export default DataViz;
