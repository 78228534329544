import type { CSSProperties } from "react";
import React from "react";

import styled, { keyframes } from "styled-components";

import { colorTheme } from "@utils";

const Rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`;

const Loader = styled.div`
  width: 50px;
  border-radius: 4px 4px 0 0;

  svg {
    animation: ${Rotate360} 2s linear infinite;
    transform-origin: center center;
    margin-right: 1rem;

    circle {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: ${Dash} 1.5s ease-in-out infinite;
      stroke: ${colorTheme("primary")};
      stroke-linecap: round;
    }
  }
`;

type LoaderWrapperProps = {
  center?: boolean;
  padding?: number;
};

const LoaderWrapper = styled.div<LoaderWrapperProps>`
  display: ${(props) => (props.center ? "flex" : "block")};
  flex-direction: ${(props) => (props.center ? "row" : "initial")};
  justify-content: ${(props) => (props.center ? "center" : "initial")};
  padding: ${(props) => props.padding + "px"};
`;

type LoadingSpinnerProps = {
  /** Whether to center the spinner */
  center?: boolean;
  /** Padding around the spinner in pixels */
  padding?: number;
  /** Custom styles to apply */
  style?: CSSProperties;
};

const LoadingSpinner = ({
  center = false,
  padding = 0,
  style = {},
}: LoadingSpinnerProps) => (
  <LoaderWrapper center={center} padding={padding} style={style}>
    <Loader>
      <svg viewBox="25 25 50 50">
        <circle
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
      </svg>
    </Loader>
  </LoaderWrapper>
);

export default LoadingSpinner;
