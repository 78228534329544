import React from "react";

export const NoSearch = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    width="250"
    height="250"
    viewBox="0 0 250 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g clipPath="url(#clip0_1852_4979)">
      <g filter="url(#filter0_i_1852_4979)">
        <rect
          x="29.1666"
          y="29.1667"
          width="191.667"
          height="191.667"
          rx="95.8333"
          fill="#B1B5B7"
          fillOpacity="0.2"
        />
      </g>
      <g filter="url(#filter1_d_1852_4979)">
        <g clipPath="url(#clip1_1852_4979)">
          <rect
            x="27.3093"
            y="57"
            width="196.083"
            height="135.858"
            rx="5.60237"
            fill="white"
          />
          <circle cx="37.1137" cy="66.8039" r="2.80119" fill="#005B93" />
          <circle cx="45.5174" cy="66.8036" r="2.80119" fill="#CCCCCC" />
          <circle cx="53.9207" cy="66.8036" r="2.80119" fill="#23A6F9" />
          <line
            x1="27.3097"
            y1="76.2585"
            x2="223.393"
            y2="76.2585"
            stroke="#B2C4D7"
            strokeOpacity="0.3"
            strokeWidth="0.700297"
          />
        </g>
        <rect
          x="27.6595"
          y="57.3501"
          width="195.383"
          height="135.157"
          rx="5.25223"
          stroke="#B2C4D7"
          strokeWidth="0.700297"
        />
      </g>
      <rect
        x="33.9149"
        y="86.0391"
        width="100.843"
        height="7.00297"
        rx="3.50148"
        fill="#DEDEDE"
      />
      <rect
        x="33.9999"
        y="101"
        width="176"
        height="7"
        rx="3.5"
        fill="#DEDEDE"
      />
      <rect
        x="33.9999"
        y="115"
        width="176"
        height="7"
        rx="3.5"
        fill="#DEDEDE"
      />
      <rect
        x="33.9999"
        y="129"
        width="137"
        height="7"
        rx="3.5"
        fill="#DEDEDE"
      />
      <rect x="33.9999" y="148" width="71" height="7" rx="3.5" fill="#DEDEDE" />
      <rect
        x="33.9999"
        y="163"
        width="159"
        height="7"
        rx="3.5"
        fill="#DEDEDE"
      />
      <rect
        x="33.9999"
        y="175"
        width="186"
        height="7"
        rx="3.5"
        fill="#DEDEDE"
      />
      <g filter="url(#filter2_d_1852_4979)">
        <circle opacity="0.1" cx="175.5" cy="143.5" r="41.5" fill="#23A6F9" />
        <mask id="path-16-inside-1_1852_4979" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M214.454 176.319C221.67 167.521 226 156.266 226 144C226 115.833 203.166 93 175 93C146.833 93 124 115.833 124 144C124 172.167 146.833 195 175 195C187.302 195 198.587 190.644 207.396 183.391C207.441 183.44 207.488 183.488 207.535 183.536L230.163 206.163C232.115 208.116 235.281 208.116 237.234 206.163C239.187 204.21 239.187 201.045 237.234 199.092L214.606 176.464C214.557 176.415 214.506 176.366 214.454 176.319Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M214.454 176.319C221.67 167.521 226 156.266 226 144C226 115.833 203.166 93 175 93C146.833 93 124 115.833 124 144C124 172.167 146.833 195 175 195C187.302 195 198.587 190.644 207.396 183.391C207.441 183.44 207.488 183.488 207.535 183.536L230.163 206.163C232.115 208.116 235.281 208.116 237.234 206.163C239.187 204.21 239.187 201.045 237.234 199.092L214.606 176.464C214.557 176.415 214.506 176.366 214.454 176.319Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          // eslint-disable-next-line max-len
          d="M214.454 176.319L206.722 169.977L200.733 177.28L207.682 183.677L214.454 176.319ZM207.396 183.391L214.741 176.604L208.331 169.667L201.04 175.671L207.396 183.391ZM237.234 199.092L244.305 192.021L237.234 199.092ZM214.606 176.464L207.535 183.536L214.606 176.464ZM216 144C216 153.87 212.525 162.902 206.722 169.977L222.187 182.66C230.814 172.14 236 158.662 236 144H216ZM175 103C197.644 103 216 121.356 216 144H236C236 110.311 208.689 83 175 83V103ZM134 144C134 121.356 152.356 103 175 103V83C141.311 83 114 110.311 114 144H134ZM175 185C152.356 185 134 166.644 134 144H114C114 177.689 141.311 205 175 205V185ZM201.04 175.671C193.955 181.505 184.899 185 175 185V205C189.705 205 203.219 199.784 213.753 191.111L201.04 175.671ZM214.606 176.464C214.652 176.51 214.697 176.557 214.741 176.604L200.051 190.177C200.186 190.322 200.323 190.466 200.464 190.607L214.606 176.464ZM237.234 199.092L214.606 176.464L200.464 190.607L223.092 213.234L237.234 199.092ZM230.163 199.092C232.115 197.139 235.281 197.139 237.234 199.092L223.092 213.234C228.95 219.092 238.447 219.092 244.305 213.234L230.163 199.092ZM230.163 206.163C228.21 204.21 228.21 201.045 230.163 199.092L244.305 213.234C250.163 207.376 250.163 197.879 244.305 192.021L230.163 206.163ZM207.535 183.536L230.163 206.163L244.305 192.021L221.678 169.393L207.535 183.536ZM207.682 183.677C207.632 183.63 207.583 183.583 207.535 183.536L221.678 169.393C221.53 169.246 221.38 169.102 221.226 168.961L207.682 183.677Z"
          fill="#005B93"
          mask="url(#path-16-inside-1_1852_4979)"
        />
        <path
          // eslint-disable-next-line max-len
          d="M171.687 163.429C171.687 164.488 172.108 165.503 172.856 166.251C173.605 167 174.62 167.42 175.678 167.42C176.737 167.42 177.752 167 178.501 166.251C179.249 165.503 179.67 164.488 179.67 163.429C179.67 162.905 179.566 162.386 179.366 161.902C179.165 161.418 178.871 160.978 178.501 160.607C178.13 160.236 177.69 159.942 177.206 159.742C176.722 159.541 176.203 159.438 175.678 159.438C175.154 159.438 174.635 159.541 174.151 159.742C173.667 159.942 173.227 160.236 172.856 160.607C172.486 160.978 172.192 161.418 171.991 161.902C171.79 162.386 171.687 162.905 171.687 163.429ZM182.712 148.363C188.326 145.271 191.178 140.788 191.178 135.041C191.178 125.171 183.394 120.75 175.678 120.75C168.165 120.75 160.178 126.21 160.178 136.331C160.178 137.359 160.587 138.345 161.313 139.071C162.04 139.798 163.026 140.206 164.053 140.206C165.081 140.206 166.067 139.798 166.794 139.071C167.52 138.345 167.928 137.359 167.928 136.331C167.928 130.949 171.947 128.5 175.678 128.5C178.573 128.5 183.428 129.349 183.428 135.041C183.428 137.06 182.898 139.416 178.976 141.578C176.31 143.039 171.803 146.833 171.803 151.75C171.803 152.778 172.212 153.763 172.938 154.49C173.665 155.217 174.651 155.625 175.678 155.625C176.706 155.625 177.692 155.217 178.419 154.49C179.145 153.763 179.553 152.778 179.553 151.75C179.553 151.029 181.084 149.258 182.712 148.363Z"
          fill="#005B93"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_i_1852_4979"
        x="29.1666"
        y="29.1667"
        width="191.667"
        height="191.667"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="8.33333" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1852_4979"
        />
      </filter>
      <filter
        id="filter1_d_1852_4979"
        x="20.3064"
        y="55.5994"
        width="210.089"
        height="149.863"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.60237" />
        <feGaussianBlur stdDeviation="3.50148" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1852_4979"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1852_4979"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_1852_4979"
        x="104"
        y="78.6024"
        width="154.698"
        height="154.627"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.60237" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1852_4979"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1852_4979"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1852_4979">
        <rect width="250" height="250" fill="white" />
      </clipPath>
      <clipPath id="clip1_1852_4979">
        <rect
          x="27.3093"
          y="57"
          width="196.083"
          height="135.858"
          rx="5.60237"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
