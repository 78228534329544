import React, { useContext } from "react";

import { DataViz, Table } from "@commonComponents";

import { gridSpacing } from "@utils";

import { PortalContext } from "../../Portal/Portal";
import {
  patientCountByQuarterQuery,
  patientCountByVisitCategoryQuery,
  summaryFilterDimensions,
} from "../AppointmentTrendSummary/Queries";
import {
  getDataByAppointmentTypeOrCategory,
  getDrilldownAppointmentByQuarter,
  getColumnsForDrillDownTable,
  getDataForDrillDownTable,
} from "../Common/appointmentCommon";
import Header from "~/src/CommonComponents/DataVisualization/Common/Header";
import { setTableExport } from "~/src/CommonComponents/TableExport/TableExport";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

const AppointmentTrendPatientVisitTypeAnalysis = () => {
  const { filters, filterOrder } = useContext(PortalContext);

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: summaryFilterDimensions,
  });

  const {
    resultSet: patientCountByVisitCategoryResult,
    error: patientCountByVisitCategoryError,
    isLoading: patientCountByVisitCategoryLoading,
  } = useCubeApiQuery(patientCountByVisitCategoryQuery(filters, filterOrder));

  const {
    resultSet: patientCountByQuarterResult,
    error: patientCountByQuarterError,
    isLoading: patientCountByQuarterLoading,
  } = useCubeApiQuery(patientCountByQuarterQuery(filters, filterOrder));

  const appointmentCategoryDrilldown = getDrilldownAppointmentByQuarter(
    patientCountByVisitCategoryResult?.rawData().map((o) => {
      return {
        name: o["appointment_completed.appointment_category"],
        count: parseInt(o["appointment_completed.total_person_count"]),
      };
    }),
    patientCountByQuarterResult?.rawData().map((o) => {
      return {
        name: o["appointment_completed.appointment_category"],
        count: o["appointment_completed.total_person_count"],
        quarter: o["appointment_completed.appointment_date.quarter"],
      };
    }),
    false,
  );

  const columns = getColumnsForDrillDownTable(
    "category",
    appointmentCategoryDrilldown,
    false,
  );

  const data = getDataForDrillDownTable(appointmentCategoryDrilldown);

  return (
    <>
      <DataViz.Row>
        <DataViz.BarChart
          title="Patient Count by Visit Category"
          subtitle="Click on a visit category to see its breakdown over time"
          toolTip="Count of patients with completed visits by visit categories (total as of current date)"
          yAxisLabel="Patient Counts"
          data={getDataByAppointmentTypeOrCategory(
            patientCountByVisitCategoryResult?.rawData(),
          ).map((o) => {
            return { ...o, type: "column" };
          })}
          drillDown={
            appointmentCategoryDrilldown as {
              id: string;
              name: string;
              data: { name: string; y: number }[];
            }[]
          }
          loading={
            patientCountByVisitCategoryLoading || patientCountByQuarterLoading
          }
          error={
            (patientCountByVisitCategoryError || patientCountByQuarterError) &&
            "Something went wrong"
          }
        />
      </DataViz.Row>
      <Header
        title="Patient Count by Category"
        toolTip="Count of patients with completed visits over time by visit categories (as of current date)"
        style={{ marginBottom: 0 }}
      />
      <Table
        isLoading={patientCountByQuarterLoading}
        columns={columns}
        data={data}
        style={{ marginBottom: gridSpacing[5] }}
        defaultSorted={[
          {
            id: "lastCol",
            desc: true,
          },
        ]}
        pageSize={10}
        exportAction={() => {
          setTableExport({
            data,
            columnMap: columns.map((column) => {
              return {
                csvColumn: column.Header,
                jsColumn: column.accessor,
              };
            }),
            exportName: "Patient Count by Category",
          });
        }}
      />
    </>
  );
};

export default AppointmentTrendPatientVisitTypeAnalysis;
