/* eslint-disable max-len */
import React from "react";

export const NoData = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    width="110"
    height="110"
    viewBox="0 0 110 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g filter="url(#filter0_d_1430_2477)">
      <g clipPath="url(#clip0_1430_2477)">
        <rect
          x="6.00024"
          y="28.1895"
          width="87.584"
          height="60.6832"
          rx="2.5024"
          fill="white"
        />
        <rect
          x="11.6685"
          y="40.6973"
          width="44.2201"
          height="24.5667"
          rx="1.8768"
          fill="#F0F1F1"
        />
        <rect
          x="59.9089"
          y="59.0098"
          width="28.1401"
          height="24.5667"
          rx="1.8768"
          fill="#F0F1F1"
        />
        <circle cx="74.068" cy="71.3824" r="6.56602" fill="#005B93" />
        <path
          d="M79.7327 74.7025C80.3259 73.6904 80.6371 72.5378 80.634 71.3647C80.6308 70.1916 80.3134 69.0407 79.7148 68.0318C79.1161 67.023 78.2581 66.1929 77.23 65.6279C76.2018 65.063 75.0411 64.7838 73.8685 64.8194L74.068 71.3824L79.7327 74.7025Z"
          fill="#23A6F9"
        />
        <rect
          x="17.0286"
          y="59.1133"
          width="6.05979"
          height="2.75445"
          rx="1.2512"
          transform="rotate(-90 17.0286 59.1133)"
          fill="#005B93"
        />
        <rect
          x="35.2078"
          y="59.1133"
          width="9.36513"
          height="2.75445"
          rx="1.2512"
          transform="rotate(-90 35.2078 59.1133)"
          fill="#23A6F9"
        />
        <rect
          x="29.1482"
          y="59.1133"
          width="5.23346"
          height="2.75445"
          rx="1.2512"
          transform="rotate(-90 29.1482 59.1133)"
          fill="#005B93"
        />
        <rect
          x="41.2676"
          y="59.1133"
          width="6.61068"
          height="2.75445"
          rx="1.2512"
          transform="rotate(-90 41.2676 59.1133)"
          fill="#005B93"
        />
        <rect
          x="23.0884"
          y="59.1133"
          width="7.71246"
          height="2.75445"
          rx="1.2512"
          transform="rotate(-90 23.0884 59.1133)"
          fill="#23A6F9"
        />
        <rect
          x="47.3274"
          y="59.1133"
          width="6.05979"
          height="2.75445"
          rx="1.2512"
          transform="rotate(-90 47.3274 59.1133)"
          fill="#23A6F9"
        />
        <circle cx="10.3794" cy="32.5696" r="1.2512" fill="#005B93" />
        <circle cx="14.133" cy="32.5696" r="1.2512" fill="#CCCCCC" />
        <circle cx="17.8865" cy="32.5696" r="1.2512" fill="#23A6F9" />
        <line
          x1="6.00024"
          y1="36.7928"
          x2="93.5842"
          y2="36.7928"
          stroke="#B2C4D7"
          strokeOpacity="0.3"
          strokeWidth="0.3128"
        />
        <rect
          x="59.8018"
          y="40.6973"
          width="12.1992"
          height="14.3888"
          rx="1.2512"
          fill="#F3F3F3"
        />
        <rect
          x="75.5742"
          y="40.6973"
          width="12.1992"
          height="14.3888"
          rx="1.2512"
          fill="#F3F3F3"
        />
        <rect
          x="11.6685"
          y="68.8379"
          width="44.2201"
          height="3.12667"
          rx="1.56334"
          fill="#DEDEDE"
        />
        <rect
          x="11.6685"
          y="74.6445"
          width="44.2201"
          height="3.12667"
          rx="1.56334"
          fill="#DEDEDE"
        />
        <rect
          x="11.6306"
          y="80.1152"
          width="18.768"
          height="3.128"
          rx="1.564"
          fill="#DEDEDE"
        />
      </g>
      <rect
        x="6.15664"
        y="28.3459"
        width="87.2712"
        height="60.3704"
        rx="2.346"
        stroke="#B2C4D7"
        strokeWidth="0.3128"
      />
    </g>
    <rect
      x="80.9022"
      y="22.17"
      width="23.46"
      height="23.46"
      rx="3.23"
      fill="white"
    />
    <rect
      x="91.2041"
      y="26.2852"
      width="3.21973"
      height="10.3031"
      rx="0.476"
      fill="#0A8EDF"
    />
    <rect
      x="91.2041"
      y="37.875"
      width="3.21973"
      height="3.21973"
      rx="1.60986"
      fill="#0A8EDF"
    />
    <rect
      x="80.9022"
      y="22.17"
      width="23.46"
      height="23.46"
      rx="3.23"
      stroke="#B2C4D7"
      strokeWidth="0.34"
    />
    <defs>
      <filter
        id="filter0_d_1430_2477"
        x="2.87224"
        y="27.5639"
        width="93.84"
        height="66.9396"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.5024" />
        <feGaussianBlur stdDeviation="1.564" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1430_2477"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1430_2477"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1430_2477">
        <rect
          x="6.00024"
          y="28.1895"
          width="87.584"
          height="60.6832"
          rx="2.5024"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
