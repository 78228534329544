import React, { useState } from "react";

import { useNavigate } from "react-router";
import { styled } from "styled-components";

import { colorTheme, gridSpacing } from "@utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - ${gridSpacing[4] * 2}px);
  min-width: 385px;
  height: 185px;
  border: 1px solid ${colorTheme("neutralL5")};
  border-radius: 8px;
  padding: ${gridSpacing[4]}px;
  background: ${colorTheme("neutralL6")};
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);

  p {
    color: ${colorTheme("neutralL1")};
  }

  &:hover {
    cursor: pointer;
    background: ${colorTheme("neutralL5")};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${gridSpacing[2]}px;

  div {
    display: flex;
    align-items: baseline;
  }
`;

export interface DetailedLinkCardProps {
  header: string;
  description: string;
  href: string;
  color?: string;
}

const DetailLinkCard = ({
  header,
  description,
  href,
  color,
}: DetailedLinkCardProps) => {
  const navigate = useNavigate();

  const [, setHover] = useState(false);

  return (
    <Container
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(e) => {
        if (e.target) {
          navigate(`${href}`);
        }
      }}
    >
      <div>
        <Header>
          <div>
            <i
              className="fa-solid fa-signal-bars"
              style={{
                marginRight: gridSpacing[2],
                color: color ?? colorTheme("neutralL3"),
              }}
            />
            <b>{header}</b>
          </div>
        </Header>
        <p>{description || ""}</p>
      </div>
    </Container>
  );
};

export default DetailLinkCard;
