import type { CSSProperties, ReactNode } from "react";
import React from "react";

import { Link } from "react-router";
import styled from "styled-components";

import { colorTheme } from "@utils";

const Name = styled.div<{
  $hasPermission: boolean;
  $noUnderline: boolean;
}>`
  color: ${({ $hasPermission }) => $hasPermission && colorTheme("primary")};

  &:hover {
    cursor: ${({ $hasPermission }) => $hasPermission && "pointer"};
    text-decoration: ${({ $noUnderline, $hasPermission }) =>
      $noUnderline || !$hasPermission ? null : "underline"};
  }
`;

interface TableLinkProps {
  url?: string;
  onClick?: () => void;
  hasPermission?: boolean;
  noUnderline?: boolean;
  children?: ReactNode;
  newTab?: boolean;
  testId?: string;
  style?: CSSProperties;
}

const TableLink = ({
  url,
  onClick,
  hasPermission = true,
  noUnderline = false,
  children,
  newTab,
  testId,
  style,
  ...props
}: TableLinkProps) => {
  const DisplayLink = () => (
    <Name
      {...props}
      style={style}
      $hasPermission={!!hasPermission}
      onClick={hasPermission ? onClick : undefined}
      $noUnderline={!!noUnderline}
      data-testid={testId}
    >
      {children}
    </Name>
  );

  if (url && hasPermission) {
    if (newTab) {
      return (
        <a
          target="_blank"
          rel="noreferrer"
          href={url}
          style={{ textDecoration: "none" }}
        >
          <DisplayLink />
        </a>
      );
    }

    return (
      <Link style={{ textDecoration: "none" }} to={url}>
        <DisplayLink />
      </Link>
    );
  }

  return <DisplayLink />;
};

export default TableLink;
