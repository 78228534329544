import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import type { OptionProp } from "./Select";
import gridSpacing from "../../utils/gridSpacing";

const Container = styled.div<{ $hoverEffect?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  position: sticky;
  top: 0;

  ${({ $hoverEffect }) =>
    $hoverEffect &&
    `
    padding: ${gridSpacing[2]}px ${gridSpacing[3]}px;

    &:hover {
      cursor: pointer;
      background: ${colorTheme("neutralL4")};
    }
  `}
`;

const Badge = styled.span<{ hasValue: boolean }>`
  background-color: ${({ hasValue }) =>
    hasValue ? colorTheme("info") : colorTheme("neutralL5")};
  border-radius: 8px;
  color: ${({ hasValue }) =>
    hasValue ? colorTheme("white") : colorTheme("neutralL2")};
  font-size: 11px;
  font-weight: normal;
  line-height: 1;
  min-width: 1;
  padding: 2px 8px;
  text-align: center;
`;

type GroupLabelProps = {
  accordion?: boolean;
  popup?: boolean;
  label?: string;
  opened?: boolean;
  options?: OptionProp[];
  valueCount?: number;
  onClick?: () => void;
};

const GroupLabel = (data: GroupLabelProps) => (
  <Container $hoverEffect={data.accordion || data.popup} onClick={data.onClick}>
    <span>{data.label}</span>
    <div style={{ display: "flex", alignItems: "center" }}>
      {data.options && (
        <Badge hasValue={(data.valueCount ?? 0) > 0}>
          {data.valueCount && data.valueCount > 0
            ? `${data.valueCount} / `
            : ""}
          {data.options.length}
        </Badge>
      )}
      {data.accordion && (
        <i
          className={`fa fa-chevron-${data.opened ? "up" : "down"}`}
          style={{
            color: colorTheme("neutralL2"),
            marginLeft: 8,
            fontSize: 13,
            fontWeight: "bold",
          }}
        />
      )}
    </div>
  </Container>
);

export default GroupLabel;
