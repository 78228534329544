import React from "react";

import { Modal } from "@commonComponents";

import SaveFilter from "./SaveFilter";
import ViewFilters from "./ViewFilters";
import type { Filter } from "~/src/types";

export type SaveFilterModalProps = {
  isOpened: "newFilter" | boolean;
  close: () => void;
  app: string;
  model: string;
  pageKey?: string;
  query: string;
  filters: Record<string, string[]>;
  savedFilters: Filter[];
  openSavedFilter: (filter: Filter) => void;
  saveFilter: (data: Filter) => void;
  removeFilter: (data: Filter) => void;
  constantFilters?: Record<string, string>[];
};

const SaveFilterModal = (props: SaveFilterModalProps) => {
  const { isOpened, close } = props;

  let ModalBody = ViewFilters;

  if (isOpened === "newFilter") {
    ModalBody = SaveFilter;
  }

  return (
    <Modal
      isOpened={!!isOpened}
      title={isOpened === "newFilter" ? "Save as View" : "Saved Views"}
      toggle={close}
      focusTrap={false}
    >
      <ModalBody {...props} />
    </Modal>
  );
};

export default SaveFilterModal;
