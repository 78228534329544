const initialState = {
  general: {
    isFetching: true,
    error: false,
    result: [],
  },
  users: {
    isFetching: true,
    error: false,
    result: [],
  },
  groups: {
    isFetching: true,
    error: false,
    result: [],
  },
  tenant: {
    isFetching: true,
    error: false,
    result: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "MANAGEMENT_SET_GENERAL": {
      return Object.assign({}, state, {
        general: {
          isFetching: action.payload.isFetching,
          error: action.payload.error,
          result: action.payload.result,
        },
      });
    }

    case "MANAGEMENT_SET_USERS": {
      return Object.assign({}, state, {
        users: { ...action.payload, result: action.payload.result.results },
      });
    }

    case "MANAGEMENT_USER_DEACTIVATE": {
      return Object.assign({}, state, {
        users: {
          ...state.users,
          result: state.users.result.map((user) => {
            return {
              ...user,
              status:
                user.uuid === action.payload.uuid ? "deactivated" : user.status,
            };
          }),
        },
      });
    }

    case "MANAGEMENT_SET_GROUPS": {
      return Object.assign({}, state, {
        groups: action.payload,
      });
    }

    case "MANAGEMENT_SET_TENANT": {
      return Object.assign({}, state, {
        tenant: action.payload,
      });
    }

    case "MANAGEMENT_ADD_GROUP": {
      return Object.assign({}, state, {
        groups: {
          ...state.groups,
          result: [...state.groups.result, action.payload.newGroup],
        },
      });
    }

    case "MANAGEMENT_UPDATE_GROUP": {
      return Object.assign({}, state, {
        groups: {
          ...state.groups,
          result: state.groups.result.map((group) => {
            if (group.uuid === action.payload.groupData.uuid) {
              return action.payload.groupData;
            }
            return group;
          }),
        },
      });
    }

    case "MANAGEMENT_ADD_USER": {
      return Object.assign({}, state, {
        users: {
          ...state.users,
          result: [...state.users.result, action.payload.newUser],
        },
      });
    }
    case "MANAGEMENT_UPDATE_USER": {
      return Object.assign({}, state, {
        users: {
          ...state.users,
          result: state.users.result.map((user) => {
            if (user.uuid === action.payload.userData.uuid) {
              return action.payload.userData;
            }
            return user;
          }),
        },
      });
    }

    default:
      return state;
  }
}
