import type { ReactNode } from "react";
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { colorTheme } from "@utils";

import { Divider, Button } from "..";
import gridSpacing from "../../utils/gridSpacing";

const Container = styled.div`
  background: ${colorTheme("white")};
  min-height: 100vh;
  top: 0;
  right: 0;
  position: fixed;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  transition: width 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 105;
`;

const DarkBackground = styled.div<{
  $visible: boolean;
}>`
  transition: ${({ $visible }) =>
    $visible ? "opacity .2s linear" : "visibility 0s .2s, opacity .2s linear"};
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
  opacity: ${({ $visible }) => ($visible ? ".2" : "0")};
  position: fixed;
  top: 0;
  right: 600px;
  bottom: 0;
  left: 0;
  outline: 0;
  z-index: 104;
  width: 100%;
  height: 100%;
  background: ${colorTheme("black")};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  padding: ${gridSpacing[4]}px ${gridSpacing[4]}px ${gridSpacing[4]}px
    ${gridSpacing[6]}px;
  white-space: nowrap;
  height: 40px;
  z-index: 106;
`;

const Body = styled.div<{ $footerText: ReactNode; $footerContent: ReactNode }>`
  height: calc(
    100vh
      ${({ $footerText, $footerContent }) =>
        $footerText ? " - 158px" : $footerContent ? " - 173px" : " - 92px"}
  );
  padding: ${gridSpacing[4]}px;
  overflow-y: auto;
`;

const Footer = styled.div<{
  $footerContent?: ReactNode;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-top: 1px solid ${colorTheme("neutralL4")};
  height: 50px;
  padding: ${({ $footerContent }) => ($footerContent ? "8px" : "0px")};
`;

const CloseIcon = styled.div`
  font-size: 18px;
  color: ${colorTheme("neutralL1")};
  &:hover {
    cursor: pointer;
    color: ${colorTheme("neutralL3")};
  }
`;

const FloatingCloseIcon = styled.div<{
  $panelSize: number;
}>`
  font-size: 20px;
  position: absolute;
  left: ${({ $panelSize }) =>
    $panelSize === 5 ? "-57px" : `-${gridSpacing[4] + 50}px`};
  top: ${gridSpacing[4]}px;
  color: ${colorTheme("white")};
  z-index: 10000;
  background: ${colorTheme("neutralL1")};
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: ${colorTheme("neutralL3")};
  }
`;

type PanelStylesProps = Record<
  number,
  {
    width: string | number;
  }
>;
const panelStyles: PanelStylesProps = {
  1: {
    width: 426,
  },
  2: {
    width: "50%",
  },
  3: {
    width: "65%",
  },
  4: {
    width: "75%",
  },
  5: {
    width: "calc(100% - 62px)",
  },
};

interface SlideOutPanelProps {
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
  title?: string;
  subTitle?: string;
  footerText?: ReactNode;
  footerLink?: string;
  children?: ReactNode;
  size?: 1 | 2 | 3 | 4 | 5;
  closeCallback?: () => void;
  noContent?: boolean;
  footerContent?: ReactNode;
  bodyStyle?: object;
  includeFloatingCloseButton?: boolean;
  headerButtonProps?: object;
  buttonsContainer?: ReactNode;
}

const SlideOutPanel = ({
  isOpened,
  setIsOpened,
  title,
  subTitle,
  footerText,
  footerLink,
  children,
  size = 1,
  closeCallback,
  noContent,
  footerContent,
  bodyStyle,
  includeFloatingCloseButton = false,
  headerButtonProps,
  buttonsContainer,
}: SlideOutPanelProps) => {
  const [width, setWidth] = useState<number | string>(0);
  const [_isOpened, _setIsOpened] = useState(false);

  const panelStyle = panelStyles[size];

  useEffect(() => {
    if (isOpened) {
      _setIsOpened(true);
      setWidth(0);
      setTimeout(() => setWidth(panelStyle.width), 1);
    } else {
      setWidth(0);
      setTimeout(() => _setIsOpened(false), 250);
    }
  }, [isOpened]);

  const close = () => {
    closeCallback && closeCallback();
    setIsOpened(false);
  };

  const renderFooter = () => {
    if (footerText) {
      return (
        <Footer>
          <div style={{ cursor: "pointer" }}>
            <a href={footerLink} target="_blank" rel="noreferrer">
              {footerText}
              <i
                className="fa-regular fa-external-link-alt"
                style={{ marginLeft: 10 }}
              />
            </a>
          </div>
        </Footer>
      );
    } else if (footerContent) {
      return <Footer $footerContent={footerContent}>{footerContent}</Footer>;
    }

    return null;
  };

  const _SlideOutPanel = (
    <>
      <DarkBackground $visible={_isOpened} onClick={close} />
      <Container
        style={{
          width,
          whiteSpace: isOpened ? "break-spaces" : "nowrap",
          right: isOpened ? 0 : -70,
        }}
      >
        {includeFloatingCloseButton && (
          <FloatingCloseIcon onClick={close} $panelSize={size}>
            <i className="fa-regular fa-times" />
          </FloatingCloseIcon>
        )}

        {noContent ? (
          children
        ) : (
          <>
            <Header>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h3>{title}</h3>
                <div style={{ fontSize: 12, color: colorTheme("neutralL1") }}>
                  {subTitle}
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {headerButtonProps && <Button mr={4} {...headerButtonProps} />}
                {buttonsContainer}
                <CloseIcon onClick={close} className="fa-regular fa-times" />
              </div>
            </Header>
            <Divider style={{ margin: 0 }} />
            <Body
              style={bodyStyle}
              $footerText={footerText}
              $footerContent={footerContent}
            >
              {children}
            </Body>
            {renderFooter()}
          </>
        )}
      </Container>
    </>
  );

  return ReactDOM.createPortal(
    _SlideOutPanel,
    document.querySelector("body") as Element,
  );
};

SlideOutPanel.propTypes = {
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.object,
  size: PropTypes.oneOf([1, 2, 3]),
  closeCallback: PropTypes.func,
  noContent: PropTypes.bool,
  footerContent: PropTypes.any,
  bodyStyle: PropTypes.object,
  includeFloatingCloseButton: PropTypes.bool,
};

export default SlideOutPanel;
