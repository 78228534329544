import React from "react";

import { GraphicCallout, Loading, Table } from "@commonComponents";
import { connect } from "react-redux";

import Header from "./Header";
import type { FilterProps } from "./helpers";
import { ContentWrapper, ObjectsContainer } from "./helpers";
import DashboardObject from "../DashboardObject";
import { dashboardColumns } from "../tableColumns";
import type {
  ListAnalyticDomain,
  ListInsightsDashboard,
  UserFavorites,
} from "~/src/types";
import { includesIgnoreCase } from "~/src/utils/tsUtils";

interface RecentsTabProps {
  filters: FilterProps;
  setFilters: (filters: FilterProps) => void;
  tableView: boolean;
  setTableView: (value: boolean) => void;
  recentDashboards: ListInsightsDashboard[];
  setSelectedDashboard: (dashboard: ListInsightsDashboard) => void;
  insightsDashboardsLoading: boolean;
  recentDashboardsLoading: boolean;
  recentDashboardError: string;
  favoritesData: UserFavorites[];
  getFavorites: () => void;
  subjects: ListAnalyticDomain[];
  customerName: string;
}

const RecentsTab = ({
  filters,
  setFilters,
  tableView,
  setTableView,
  recentDashboards,
  setSelectedDashboard,
  insightsDashboardsLoading,
  recentDashboardsLoading,
  recentDashboardError,
  favoritesData,
  getFavorites,
  subjects,
  customerName,
}: RecentsTabProps) => {
  const filteredRecentDashboards = recentDashboards.filter(
    (d) =>
      includesIgnoreCase(d.name, filters.search) &&
      (!filters.type || filters.type === d.type) &&
      (!filters.managed_by || filters.managed_by === d.managed_by) &&
      (!filters.analytic_domain ||
        d.subdomain.analytic_domain.name === filters.analytic_domain) &&
      (filters.subdomain?.length === 0 ||
        (d.subdomain.name && filters.subdomain?.includes(d.subdomain.name))),
  );

  const renderDashboardView = () => {
    if (recentDashboards.length === 0) {
      return (
        <GraphicCallout empty="groups" header="No dashboards viewed yet" />
      );
    }
    if (filteredRecentDashboards.length === 0) {
      return <GraphicCallout noSubheader empty="search" />;
    }
    if (tableView) {
      return (
        <Table
          columns={dashboardColumns(
            setSelectedDashboard,
            favoritesData,
            getFavorites,
            customerName,
          )}
          data={filteredRecentDashboards}
        />
      );
    }

    return (
      <ObjectsContainer>
        {recentDashboards.length > 0 ? (
          filteredRecentDashboards.map((_dashboard: ListInsightsDashboard) => (
            <DashboardObject
              key={_dashboard.uuid}
              setSelectedDashboard={setSelectedDashboard}
              favorites={favoritesData}
              getFavorites={getFavorites}
              dashboard={_dashboard}
            />
          ))
        ) : (
          <p>No dashboards viewed yet</p>
        )}
      </ObjectsContainer>
    );
  };

  if (recentDashboardError) {
    return <GraphicCallout error="issue" header={recentDashboardError} />;
  }
  return (
    <>
      <Header
        filters={filters}
        setFilters={setFilters}
        tableView={tableView}
        setTableView={setTableView}
        subjects={subjects}
      />
      <ContentWrapper>
        {recentDashboardsLoading || insightsDashboardsLoading ? (
          <Loading center />
        ) : (
          renderDashboardView()
        )}
      </ContentWrapper>
    </>
  );
};

const mapStateToProps = (state: {
  SystemManagement: {
    management: {
      general: {
        result: {
          name: string;
        };
      };
    };
  };
}) => {
  return {
    customerName:
      state.SystemManagement?.management?.general?.result?.name || "Customer",
  };
};

export default connect(mapStateToProps)(RecentsTab);
