import React, { useState, useEffect } from "react";

import { Button, Callout, Modal } from "@commonComponents";
import axios from "axios";
import { styled } from "styled-components";

import { colorTheme, gridSpacing } from "@utils";

import type { ListInsightsDashboard } from "~/src/types";
import { internalPageError } from "~/src/utils/tsUtils";

const ColContainer = styled.div`
  max-height: 525px;
  min-height: 525px;

  overflow-y: auto;
  overflow-x: hidden;

  padding: 0 ${gridSpacing[4]}px;
  margin-top: ${gridSpacing[4]}px;
`;
const ColRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  border: 1px solid ${colorTheme("neutralL4")};
  padding: ${gridSpacing[1]}px ${gridSpacing[3]}px ${gridSpacing[1]}px
    ${gridSpacing[3]}px;

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:not(:last-child) {
    border-bottom: none;
  }
`;

const Icons = styled.div`
  font-size: 23px;

  i:hover {
    cursor: pointer;
    color: ${colorTheme("primaryD2")};
  }

  i:first-child {
    margin-right: ${gridSpacing[3]}px;
  }
`;

const RankIcon = styled.i<{ disabled?: boolean }>`
  color: ${colorTheme("primary")};
  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed !important;
      color: ${colorTheme("neutralL1")} !important;
    `};
`;

type ReorderModalProps = {
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
  dashboards: ListInsightsDashboard[];
  selectedDashboard: ListInsightsDashboard | null;
  setSelectedDashboard: (dashboard: ListInsightsDashboard | null) => void;
  fetchManagedDashboards: () => void;
};

type DashboardRanks = Record<string, number>;

const ReorderModal = ({
  isOpened,
  setIsOpened,
  dashboards,
  selectedDashboard,
  setSelectedDashboard,
  fetchManagedDashboards,
}: ReorderModalProps) => {
  const [dashboardRanks, setDashboardRanks] = useState<DashboardRanks>({});
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isOpened) {
      const _dashboardRanks = dashboards.reduce<DashboardRanks>(
        (acc, dashboard) => {
          if (dashboard.uuid) {
            acc[dashboard.uuid] = dashboard.rank;
          }
          return acc;
        },
        {},
      );
      setDashboardRanks(_dashboardRanks);
    }
  }, [isOpened]);

  const close = () => {
    setIsOpened(false);
    setSelectedDashboard(null);
    setError(null);
  };

  const handleReorder = ({
    direction,
    dashboard,
  }: {
    direction: string;
    dashboard: ListInsightsDashboard;
  }) => {
    const newRank =
      direction === "up"
        ? dashboardRanks[dashboard.uuid] - 1
        : dashboardRanks[dashboard.uuid] + 1;

    const otherDashboard = dashboards.find(
      (d) => dashboardRanks[d.uuid] === newRank,
    );
    if (!otherDashboard) return;

    const otherNewRank =
      direction === "up"
        ? dashboardRanks[otherDashboard.uuid] + 1
        : dashboardRanks[otherDashboard.uuid] - 1;

    axios
      .patch(
        `insights/dashboards/${dashboard.uuid}/${
          direction === "up" ? "increase" : "decrease"
        }_rank`,
      )
      .then(() => {
        setDashboardRanks({
          ...dashboardRanks,
          [dashboard.uuid]: newRank,
          [otherDashboard.uuid]: otherNewRank,
        });
      })
      .catch(() => {
        internalPageError("Failed to reorder dashboards");
      });
  };

  return (
    <Modal
      isOpened={isOpened}
      toggle={close}
      title="Reorder Hierarchy"
      focusTrap={false}
    >
      {selectedDashboard && (
        <h4
          style={{ marginTop: gridSpacing[4] }}
        >{`${selectedDashboard.subdomain.analytic_domain.name} / ${selectedDashboard.subdomain.name}`}</h4>
      )}

      <ColContainer>
        {dashboards
          .sort((a, b) => dashboardRanks[a.uuid] - dashboardRanks[b.uuid])
          .map((dashboard, index) => (
            <ColRow key={dashboard.uuid}>
              <Icons>
                <RankIcon
                  className="fas fa-arrow-square-up"
                  onClick={() => {
                    if (index === 0) return;
                    handleReorder({ direction: "up", dashboard });
                  }}
                  disabled={index === 0}
                />
                <RankIcon
                  className="fas fa-arrow-square-down"
                  onClick={() => {
                    if (index === dashboards.length - 1) return;
                    handleReorder({ direction: "down", dashboard });
                  }}
                  disabled={index === dashboards.length - 1}
                />
              </Icons>
              <div style={{ marginLeft: gridSpacing[3] }}>{dashboard.name}</div>
            </ColRow>
          ))}
      </ColContainer>
      <Modal.Footer>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {error && <Callout mb type="error" text={error} />}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              mr
              type="secondary"
              text="Close"
              onClick={() => {
                setIsOpened(false);
                setSelectedDashboard(null);
                fetchManagedDashboards();
              }}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReorderModal;
