import type { ReactNode } from "react";
import React from "react";

import styled from "styled-components";

import { colorTheme } from "@utils";

import { GeneralError } from "./Callouts/GeneralError";
import { NoDashboards } from "./Callouts/NoDashboards";
import { NoData } from "./Callouts/NoData";
import { NoGroups } from "./Callouts/NoGroups";
import { NoModels } from "./Callouts/NoModels";
import { NoPermissions } from "./Callouts/NoPermissions";
import { NoSearch } from "./Callouts/NoSearch";
import gridSpacing from "../../utils/gridSpacing";
import Button from "../Button/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

interface GraphicCalloutProps {
  empty?: "dashboards" | "models" | "groups" | "search";
  error?: "issue" | "permissions" | "data";
  header?: string | ReactNode;
  subheader?: string | ReactNode;
  buttonText?: string;
  pageName?: string;
  inSlideOut?: boolean;
  style?: React.CSSProperties;
  onClick?: null | (() => void);
  testId?: string;
  noSubheader?: boolean;
  imgStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  subheaderStyle?: React.CSSProperties;
}

/**
 * GraphicCallout
 *
 * This component is used to display a graphic and text to the user when there is no data to display or an error has occurred.
 * Only one of the following props should be passed at a time: empty, error.
 *
 * @param empty - "dashboards" | "models" | "groups" | "search"
 * @param error - "issue" | "permissions" | "data"
 * @param header - string | ReactNode
 * @param subheader - string | ReactNode
 * @param buttonText - string
 * @param pageName - string
 * @param inSlideOut - boolean
 * @param style - React.CSSProperties
 * @param imgStyle - React.CSSProperties
 * @param headerStyle - React.CSSProperties
 * @param subheaderStyle - React.CSSProperties
 * @param onClick - () => void | null - dashboards and models button
 * @param testId - string
 * @param noSubheader - boolean - if true, subheader will not be displayed
 * @constructor
 */
const GraphicCallout = ({
  empty,
  error,
  header,
  subheader,
  buttonText,
  pageName = "page",
  inSlideOut,
  style,
  imgStyle,
  headerStyle,
  subheaderStyle,
  onClick,
  testId = "graphic-callout",
  noSubheader = false,
}: GraphicCalloutProps) => {
  let SVG = NoDashboards;
  let _header: string | ReactNode = "Welcome to system operations";
  let _subheader: string | ReactNode =
    'Looks like there are no dashboards to display yet. Click "Create Dashboard" to get started.';
  let _buttonText: string | null = "Create dashboard";

  const subheaderMaxWidth = 675;

  if (empty) {
    if (empty === "models") {
      SVG = NoModels;
      _header = "Target models";
      _subheader =
        'Currently, no models exist. To add a new model, click "Add Model" and fill out the info.';
      _buttonText = "Add model";
    } else if (empty === "groups") {
      SVG = NoGroups;
      _header = "No groups exist";
      _subheader =
        "Once you have a group you can start tracking customer behavior and making data-driven decisions to improve your business.";
      _buttonText = null;
    } else if (empty === "search") {
      SVG = NoSearch;
      _header = "No results found";
      _subheader =
        "Try adjusting your search or filter options to find what you're looking for.";
      _buttonText = null;
    }
  } else if (error) {
    if (error === "issue") {
      SVG = GeneralError;
      _header = "Oops, Something went wrong";
      _subheader = `Something went wrong loading the ${pageName}.`;
      _buttonText = null;
    } else if (error === "permissions") {
      SVG = NoPermissions;
      _header = "Permission Denied";
      if (inSlideOut) {
        _subheader = (
          <>
            <p>
              You are trying to access a page that you do not have permission
              for.
            </p>
            <p>To get access, contact your Administrator.</p>
          </>
        );
      } else {
        _subheader =
          "You are trying to access a page that you do not have permission for. Please contact an administrator if you believe you should be able to access this page.";
      }
      _buttonText = null;
    } else if (error === "data") {
      SVG = NoData;
      _header = "";
      _subheader =
        "Data currently unavailable. Refresh the page or contact support if the issue persists.";
      _buttonText = null;
    }
  }

  _header = header || _header;
  _subheader = subheader || _subheader;
  _buttonText = buttonText || _buttonText;

  return (
    <Container data-testid={testId} style={style}>
      <SVG style={imgStyle} />
      {_header && (
        <div
          style={{
            marginTop: gridSpacing[7],
            fontSize: 28,
            fontWeight: 500,
            color: colorTheme("neutral"),
            ...headerStyle,
          }}
        >
          {_header}
        </div>
      )}
      {!noSubheader && (
        <div
          style={{
            color: colorTheme("neutralL1"),
            marginTop: gridSpacing[2],
            marginBottom: gridSpacing[4],
            maxWidth: subheaderMaxWidth,
            textAlign: "center",
            ...subheaderStyle,
          }}
        >
          {_subheader}
        </div>
      )}
      {_buttonText && onClick && (
        <Button type="primary" text={_buttonText} onClick={onClick} />
      )}
    </Container>
  );
};

export default GraphicCallout;
