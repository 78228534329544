import type { Dispatch, SetStateAction } from "react";
import React, { useEffect, useState } from "react";

import { updateUserMeta } from "@actions";
import {
  Button,
  ButtonGroup,
  GraphicCallout,
  Loading,
  Table,
  ToolTip,
  AdvancedFiltersV2,
} from "@commonComponents";
import { LEAP_INSIGHTS_URLS } from "@urls";
import axios, { type AxiosResponse } from "axios";
import queryString from "query-string";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router";
import { styled } from "styled-components";

import {
  gridSpacing,
  capitalizeFirstLetters,
  checkPermissions,
  makeHumanReadable,
  colorTheme,
} from "@utils";

import DashboardDetailSlideOut from "./DashboardDetailSlideOut";
import DashboardObject from "./DashboardObject";
import DashboardSettings from "./DashboardSettings/DashboardSettings";
import FavoriteButton from "./FavoriteButton";
import GroupObject from "./GroupObject";
import type {
  InsightsDashboardsProps,
  QuicksightDashboardProps,
  QuicksightProps,
  SubjectsProps,
} from "./helpers";
import { checkGroupPermission, Icon, useFavorites } from "./helpers";
import PortalHome from "./PortalHome/PortalHome";
import QuicksightDashboard from "./QuicksightDashboard";
import SideBar from "./SideBar/SideBar";
import { dashboardColumns, groupColumns } from "./tableColumns";
import TopBar from "./TopBar";
import { DashboardComponents } from "../Dashboards/DashboardComponents";
import type { FilterOptionProps } from "~/src/CommonComponents/AdvancedFiltersV2/AdvancedFilters";
import type {
  InsightsAnalyticDomainsList200Response,
  InsightsDashboardsList200Response,
  ListAnalyticDomain,
  ListInsightsDashboard,
} from "~/src/types";
import type { AdvFilterProps } from "~/src/utils/tsUtils";
import { trackPageView, internalPageError } from "~/src/utils/tsUtils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -40px; // Negative margin to account for global padding on container
  overflow-y: hidden;
  height: 100vh;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  height: calc(100vh - 76px); // 76px is the height of the top bar
  overflow-y: hidden;
  width: 100%;
`;

const ContentContainer = styled.div`
  padding: ${gridSpacing[5]}px ${gridSpacing[7]}px;
  padding-top: 0;
  height: calc(100vh - 180px); // 180px is the height of the top bar + header
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${gridSpacing[5]}px ${gridSpacing[7]}px;
  padding-bottom: ${gridSpacing[2]}px;
  min-height: 42px; // Match height when icons are present
`;

const ObjectsContainer = styled.div`
  margin-bottom: ${gridSpacing[6]}px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${gridSpacing[4]}px;
`;

const HeaderDescription = styled.div`
  margin-top: ${gridSpacing[2]}px;
  color: ${colorTheme("neutralL1")};
`;

type PortalProps = {
  userTableView: boolean;
  updateUserMeta: (key: string, value: boolean) => void;
  customerName: string;
};

export type DashboardAdvancedFilterProps = {
  label: string;
  cubeKey?: string;
  id?: string;
  parentId?: string;
  isParent?: boolean;
};

type FilterOptionsProps = Record<
  string,
  {
    label: string;
    name: string;
    options: string[];
  }[]
>;

type PortalContextProps = {
  filters: AdvFilterProps;
  setFilters: Dispatch<SetStateAction<object>>;
  filterOptions: object;
  setFilterOptions: Dispatch<SetStateAction<FilterOptionsProps>>;
  filterOrder?: DashboardAdvancedFilterProps[];
};

export const PortalContext = React.createContext<PortalContextProps>({
  filters: {},
  setFilters: () => {},
  filterOptions: {},
  setFilterOptions: () => {},
  filterOrder: undefined,
});

const Portal = ({
  userTableView,
  updateUserMeta,
  customerName,
}: PortalProps) => {
  const [tableView, setTableView] = useState(userTableView);
  const [selectedDashboard, setSelectedDashboard] =
    useState<ListInsightsDashboard | null>(null);
  const [filters, setFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>({});
  const [filterOrder, setFilterOrder] = useState<
    DashboardAdvancedFilterProps[]
  >([]);
  const [insightsDashboards, setInsightsDashboards] =
    useState<InsightsDashboardsProps>({
      loading: true,
      error: false,
      data: null,
    });
  const [subjects, setSubjects] = useState<SubjectsProps>({
    loading: true,
    error: false,
    data: null,
  });
  const [selectedObject, setSelectedObject] = useState<string>("");
  const {
    loading: favoritesLoading,
    data: favoritesData,
    getFavorites,
  } = useFavorites([
    "leap_insights_subdomain",
    "leap_insights_customer",
    "leap_insights_lucerna",
  ]);

  const [allQuicksightDashboards, setAllQuicksightDashboards] =
    useState<QuicksightDashboardProps>({
      loading: true,
      error: false,
      data: [],
    });
  const [currentTab, setCurrentTab] = useState("all-published-dashboards");

  const isLoading =
    insightsDashboards.loading ||
    subjects.loading ||
    favoritesLoading ||
    allQuicksightDashboards.loading;

  const { subjectPath, groupPath, dashboardPath } = useParams<{
    subjectPath: string;
    groupPath: string;
    dashboardPath: string;
  }>();

  const location = useLocation();

  const subject = subjects.data?.find((o) => o.path === subjectPath);
  const group = subject?.subdomains?.find((o) => o.path === groupPath);
  const dashboard: ListInsightsDashboard | undefined =
    insightsDashboards.data?.find(
      (o) => o.identifier === dashboardPath && o.subdomain.uuid === group?.uuid,
    );

  let foundQuicksightDashboard: QuicksightProps | undefined = undefined;
  if (subjectPath === "all-quicksight-dashboards" && groupPath) {
    foundQuicksightDashboard = allQuicksightDashboards.data.find(
      (o) => o.uuid === groupPath,
    );
  }

  const dashboardDefinition = dashboard?.uuid
    ? DashboardComponents[dashboard.uuid]
    : null;

  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.replace("#", "");
      setCurrentTab(hash);
    } else {
      setCurrentTab("recents");
    }
  }, [window.location.hash]);

  const getQuicksightDashboards = () => {
    axios
      .get("quicksight/dashboards")
      .then((quicksightRes) => {
        quicksightRes.data.result.forEach((dashboard: QuicksightProps) => {
          dashboard.path = `all-quicksight-dashboards/${dashboard.uuid}`;
        });
        setAllQuicksightDashboards({
          loading: false,
          error: false,
          data: quicksightRes.data.result,
        });
      })
      .catch((err) => {
        console.error(err);
        const errorMessage = err.response.data.result?.detail;

        if (err.response.status !== 403) {
          internalPageError(
            `Error fetching Quicksight dashboards${
              errorMessage && `: ${errorMessage}`
            }`,
          );
        }

        setAllQuicksightDashboards({
          loading: false,
          error: true,
          data: [],
        });
      });
  };

  const getInsightsDashboards = () => {
    axios
      .get("insights/dashboards?page_size=0")
      .then((insightsRes: AxiosResponse<InsightsDashboardsList200Response>) => {
        setInsightsDashboards({
          loading: false,
          error: false,
          data: insightsRes.data.result?.results ?? [],
        });
      })
      .catch((err) => {
        console.error(err);
        setInsightsDashboards({
          loading: false,
          error: true,
          data: null,
        });
      });
  };

  const getSubjects = () => {
    axios
      .get("insights/analytic_domains?page_size=0")
      .then((res: AxiosResponse<InsightsAnalyticDomainsList200Response>) => {
        // Filter out subdomains that the user does not have permission to view
        const _filteredResults: ListAnalyticDomain[] = [];

        res.data.result?.results.forEach((subject: ListAnalyticDomain) => {
          subject.subdomains = subject.subdomains.filter((subdomain) =>
            checkGroupPermission(subject.path, subdomain.path),
          );
          if (subject.subdomains.length > 0) {
            _filteredResults.push(subject);
          }
        });

        setSubjects({
          loading: false,
          error: false,
          data: _filteredResults,
        });
      })
      .catch((err) => {
        console.error(err);
        setSubjects({
          loading: false,
          error: true,
          data: null,
        });
      });
  };

  useEffect(() => {
    getQuicksightDashboards();
    getInsightsDashboards();
    getSubjects();
  }, []);

  useEffect(() => {
    setFilters({});
    setFilterOptions({});

    let pageName, objectPK, objectType;
    const l1App = "Leap Insights";
    if (dashboard) {
      objectPK = dashboard.uuid;
      pageName = dashboard.name;
      objectType = `Leap Dashboard (${capitalizeFirstLetters(
        dashboard.dashboard_type,
      )})`;
      trackPageView({
        pageName,
        objectPK,
        objectType,
        l1App,
      });

      if (dashboardDefinition?.advancedFilters) {
        setFilterOrder(dashboardDefinition.advancedFilters);
      }
    } else if (foundQuicksightDashboard) {
      pageName = foundQuicksightDashboard.name;
      objectType = "Leap Dashboard (Quicksight)";
      trackPageView({
        pageName,
        objectType,
        l1App,
      });
    }
  }, [dashboard, foundQuicksightDashboard]);

  const renderHeader = (
    text: string,
    showToggle: boolean,
    icon?: string,
    shortDescription?: string,
    groupId?: string,
  ) => (
    <Header>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon && (
            <i
              className={icon}
              style={{
                marginRight: gridSpacing[2],
                fontSize: 24,
                color: colorTheme("neutralL1"),
              }}
            />
          )}
          <h3>{text}</h3>
          {groupId && (
            <FavoriteButton
              favorite={favoritesData.some((fav) => fav.object_id === groupId)}
              objectType="leap_insights_subdomain"
              objectId={groupId}
              refreshFavorites={getFavorites}
            />
          )}
        </div>
        {showToggle && (
          <ButtonGroup
            onChange={(e) => {
              setTableView(Boolean(e));
              updateUserMeta("leapInsightsTableView", Boolean(e));
            }}
            value={tableView}
          >
            <Button icon="fa-grid-2" value={false} />
            <Button icon="fa-list" value />
          </ButtonGroup>
        )}
      </div>
      <HeaderDescription>{shortDescription}</HeaderDescription>
    </Header>
  );

  const renderCustomerDashboards = () => {
    const foundDashboard = dashboard || foundQuicksightDashboard;

    if (
      foundQuicksightDashboard &&
      !checkPermissions("insights.view_all_quicksight_dashboards")
    ) {
      return (
        <GraphicCallout
          noSubheader
          error="permissions"
          header="You do not have permission to view Quicksight dashboards"
        />
      );
    }

    if (insightsDashboards.loading || allQuicksightDashboards.loading) {
      return <Loading center />;
    }

    return (
      <ContentWrapper key={`${foundDashboard?.name}-customer-dashboard`}>
        <Header>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3>
              <i
                className="fa-regular fa-table-layout"
                style={{
                  color: colorTheme(
                    foundQuicksightDashboard ? "discovery" : "neutralL1",
                  ),
                  marginRight: gridSpacing[2],
                }}
              />
              {foundDashboard?.name}
            </h3>
            {dashboard && (
              <>
                <ToolTip
                  text="View details"
                  style={{
                    marginLeft: gridSpacing[3],
                    marginTop: 2, // Give it a little more space to line up to center of text
                  }}
                >
                  <Icon
                    className="fa-regular fa-info-circle"
                    onClick={() => setSelectedDashboard(dashboard)}
                  />
                </ToolTip>
                <FavoriteButton
                  favorite={favoritesData.some(
                    (fav) => fav.object_id === dashboard?.uuid,
                  )}
                  objectType="leap_insights_customer"
                  objectId={dashboard.uuid}
                  refreshFavorites={getFavorites}
                />
              </>
            )}
          </div>
        </Header>

        <ContentContainer>
          {foundDashboard && <QuicksightDashboard dashboard={foundDashboard} />}
        </ContentContainer>
      </ContentWrapper>
    );
  };

  const renderDashboard = () => {
    const findCubeOptions = (cubeKey?: string) => {
      if (cubeKey && cubeKey.includes(".")) {
        const [parentKey, childKey] = cubeKey.split(".");
        const foundOptions = filterOptions[parentKey];

        const cubeOptions = foundOptions
          ?.find((o) => o.name === childKey)
          ?.options.filter((o) => !!o)
          .map((o) => {
            return {
              value: o,
              label: makeHumanReadable(o),
            };
          });

        if (cubeOptions) {
          return cubeOptions;
        }
      }

      return [];
    };

    const getOptions = (parentId?: string): FilterOptionProps[] => {
      if (!parentId || !dashboardDefinition?.advancedFilters) {
        return [];
      }

      const foundOptions = dashboardDefinition.advancedFilters.filter(
        (o) => o.parentId === parentId,
      );

      if (!foundOptions) {
        return [];
      }

      return foundOptions.map((option) => {
        if (option.isParent) {
          return {
            label: option.label,
            value: option.id || "",
            options: getOptions(option.id),
          };
        }

        return {
          type: "MULTI_OPTION",
          label: option.label,
          value: option.cubeKey?.split(".")[1] || "",
          options: findCubeOptions(option.cubeKey),
        };
      });
    };

    let advFilterOptions: FilterOptionProps[] = [];

    if (dashboardDefinition?.advancedFilters) {
      advFilterOptions = dashboardDefinition.advancedFilters
        .filter(
          (o) => (o.isParent && !o.parentId) || (!o.isParent && !o.parentId),
        )
        .map((o) => {
          return {
            type: "MULTI_OPTION",
            label: o.label,
            value: o.id || o.cubeKey?.split(".")[1] || "",
            options:
              o.isParent && o.id
                ? getOptions(o.id)
                : findCubeOptions(o.cubeKey),
          };
        });
    } else {
      Object.keys(filterOptions).forEach((key) => {
        const currentFilterOptions: FilterOptionProps[] = [];

        filterOptions[key].forEach((groupOption) => {
          currentFilterOptions.push({
            label: makeHumanReadable(groupOption.label),
            value: groupOption.label,
            options: groupOption.options
              .filter((o) => !!o)
              .map((o) => {
                return {
                  value: o,
                  label: o,
                };
              }),
            type: "MULTI_OPTION",
          });
        });

        advFilterOptions.push({
          label: makeHumanReadable(key),
          value: key,
          options: currentFilterOptions,
        });
      });
    }

    return (
      <ContentWrapper key={`${dashboard?.name}-dashboard`}>
        <Header>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h3>
                <i
                  className="fa-regular fa-table-layout"
                  style={{
                    color: colorTheme("info"),
                    marginRight: gridSpacing[2],
                  }}
                />
                {dashboard?.name}
              </h3>
              {dashboard?.description && (
                <ToolTip
                  text="View details"
                  style={{
                    marginLeft: gridSpacing[3],
                    marginTop: 2,
                  }}
                >
                  <Icon
                    className="fa-regular fa-info-circle"
                    onClick={() => setSelectedDashboard(dashboard)}
                  />
                </ToolTip>
              )}
              {dashboard?.uuid && (
                <FavoriteButton
                  favorite={favoritesData.some(
                    (fav) => fav.object_id === dashboard.uuid,
                  )}
                  objectType="leap_insights_lucerna"
                  objectId={dashboard.uuid}
                  refreshFavorites={getFavorites}
                />
              )}
            </div>
          </div>
          {!dashboardDefinition?.hideAdvancedFilters && group && dashboard && (
            <AdvancedFiltersV2
              searchTextHidden
              inlineViewButtons
              app={`insights-portal-${group.path}`}
              model={dashboard.path}
              incomingFilters={advFilterOptions}
              setQuery={(e, dotNotation) => {
                if (dotNotation) {
                  const parsedQuery = queryString.parse(dotNotation);
                  setFilters(parsedQuery);
                } else {
                  setFilters({});
                }
              }}
            />
          )}
        </Header>
        <ContentContainer>
          {dashboardDefinition?.component &&
            React.createElement(dashboardDefinition.component, {
              filterOrder: dashboardDefinition?.advancedFilters,
            } as React.Attributes)}
        </ContentContainer>
      </ContentWrapper>
    );
  };
  const renderGroup = () => {
    const hasPermission = checkGroupPermission(
      subjectPath ?? "",
      groupPath ?? "",
    );

    const dashboards =
      insightsDashboards.data?.filter(
        (dashboard) => dashboard.subdomain.uuid === group?.uuid,
      ) ?? [];

    return (
      <ContentWrapper key={`${group?.name}-group`}>
        {renderHeader(
          group?.name ?? "",
          dashboards.length > 0 && hasPermission,
          "fa-regular fa-folder",
          group?.short_description,
          group?.uuid,
        )}
        {!hasPermission ? (
          <GraphicCallout
            noSubheader
            error="permissions"
            header="You do not have permission to view this group"
          />
        ) : dashboards.length === 0 ? (
          <GraphicCallout
            noSubheader
            header="No Published Dashboards"
            onClick={null}
          />
        ) : (
          <ContentContainer>
            {tableView ? (
              <Table
                columns={dashboardColumns(
                  setSelectedDashboard,
                  favoritesData,
                  getFavorites,
                  customerName,
                )}
                data={dashboards}
              />
            ) : (
              <ObjectsContainer>
                {dashboards.map((_dashboard) => (
                  <DashboardObject
                    key={_dashboard.path}
                    dashboard={_dashboard}
                    setSelectedDashboard={setSelectedDashboard}
                    favorites={favoritesData}
                    getFavorites={getFavorites}
                  />
                ))}
              </ObjectsContainer>
            )}
          </ContentContainer>
        )}
      </ContentWrapper>
    );
  };

  const renderSubject = () => {
    const subdomains = subject?.subdomains.map((subdomain) => {
      return {
        ...subdomain,
        path: `${LEAP_INSIGHTS_URLS.baseUrl}/${subject?.path}/${subdomain.path}`,
      };
    });

    return (
      <ContentWrapper key={`${subject?.name}-subject`}>
        {renderHeader(
          subject?.name ?? "",
          true,
          subject?.icon,
          subject?.short_description,
        )}
        <ContentContainer>
          {tableView ? (
            <Table
              columns={groupColumns(favoritesData, getFavorites)}
              data={subdomains ?? []}
            />
          ) : (
            <ObjectsContainer>
              {subdomains?.map((subdomain) => (
                <GroupObject
                  key={subdomain.uuid}
                  subdomain={{
                    ...subdomain,
                    created: subdomain.created ?? "",
                  }}
                  insightsDashboards={insightsDashboards.data ?? []}
                  favorites={favoritesData}
                  getFavorites={getFavorites}
                />
              ))}
            </ObjectsContainer>
          )}
        </ContentContainer>
      </ContentWrapper>
    );
  };

  const renderPage = () => {
    if (insightsDashboards.error || subjects.error) {
      return (
        <GraphicCallout
          noSubheader
          error="issue"
          header="Something went wrong loading the page"
        />
      );
    } else if (isLoading) {
      return (
        <ContentWrapper>
          <ContentContainer
            style={{
              marginTop: gridSpacing[6],
            }}
          >
            <Loading center />
          </ContentContainer>
        </ContentWrapper>
      );
    }
    // dashboard-settings is not caught with the subjectPath so it needs to be handled independently
    else if (location.pathname.includes("dashboard-settings")) {
      return (
        <DashboardSettings
          insightsDashboards={insightsDashboards.data ?? []}
          getInsightsDashboards={getInsightsDashboards}
          subjects={subjects.data ?? []}
        />
      );
    } else if (
      (dashboard && dashboard.type === "custom") ||
      foundQuicksightDashboard
    ) {
      return renderCustomerDashboards();
    } else if (dashboardPath) {
      return renderDashboard();
    } else if (groupPath) {
      return renderGroup();
    } else if (subjectPath) {
      return renderSubject();
    }
    return (
      <PortalHome
        insightsDashboards={insightsDashboards.data || []}
        setSelectedDashboard={setSelectedDashboard}
        insightsDashboardsLoading={insightsDashboards.loading}
        allQuicksightDashboards={allQuicksightDashboards.data}
        allQuicksightDashboardsLoading={allQuicksightDashboards.loading}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        favoritesData={favoritesData}
        getFavorites={getFavorites}
        subjects={subjects.data || []}
      />
    );
  };

  return (
    <PortalContext.Provider
      value={{
        filters,
        setFilters,
        filterOptions,
        setFilterOptions,
        filterOrder,
      }}
    >
      <DashboardDetailSlideOut
        selectedDashboard={selectedDashboard}
        setSelectedDashboard={setSelectedDashboard}
        favorites={favoritesData}
        getFavorites={getFavorites}
        insightsDashboards={insightsDashboards.data || []}
      />
      <Wrapper>
        <TopBar
          insightsDashboards={insightsDashboards.data || []}
          subjects={subjects.data || []}
          setSelectedObject={setSelectedObject}
        />
        <Container>
          <SideBar
            subjects={subjects.data || []}
            insightsDashboards={insightsDashboards.data || []}
            isLoading={isLoading}
            favoritesData={favoritesData}
            selectedObject={selectedObject}
            setSelectedObject={setSelectedObject}
          />
          {renderPage()}
        </Container>
      </Wrapper>
    </PortalContext.Provider>
  );
};

const mapStateToProps = (state: {
  main: {
    user: {
      metadata: {
        leapInsightsTableView: boolean;
      };
    };
  };
  SystemManagement: {
    management: {
      general: {
        result: {
          name: string;
        };
      };
    };
  };
}) => {
  return {
    userTableView: state.main.user.metadata?.leapInsightsTableView,
    customerName:
      state.SystemManagement?.management?.general?.result?.name || "Customer",
  };
};

export default connect(mapStateToProps, {
  updateUserMeta,
})(Portal);
