import React from "react";

import { ToolTip } from "@commonComponents";
import PropTypes from "prop-types";
import { styled } from "styled-components";

import { colorTheme } from "@utils";

import { clamp } from "~/src/utils/tsUtils";

const Container = styled.div`
  position: relative;
  width: ${(props) => props.$width}px;
  z-index: 102;
`;

const ProgressBackground = styled.div`
  width: 100%;
  border-radius: 99px;
  height: 8px;
  background-color: ${(props) =>
    props.$getColor
      ? props.$getColor(props.$progress, false)
      : colorTheme("neutralL4")};
  z-index: 101;
`;

const Progress = styled.div`
  position: absolute;
  border-radius: 99px;
  height: 8px;
  background-color: ${(props) =>
    props.$getColor
      ? props.$getColor(props.$progress, true)
      : colorTheme("primary")};
  width: ${(props) => props.$width}px;
  z-index: 100;
`;

const ProgressBar = ({
  width,
  progress,
  getColor,
  tooltipSuffix = "complete",
}) => {
  const progressFloat = clamp(parseFloat(progress).toFixed(2), 0, 1);
  return (
    <div style={{ width: width }}>
      <ToolTip text={`${progressFloat * 100}% ${tooltipSuffix}`}>
        <Container $width={width}>
          <Progress
            $width={progressFloat * width}
            $progress={progressFloat}
            $getColor={getColor}
          />
          <ProgressBackground $progress={progressFloat} $getColor={getColor} />
        </Container>
      </ToolTip>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.string,
  width: PropTypes.number,
  // for when we convert to TS: (progress: number, isForeground: boolean) => string
  getColor: PropTypes.func,
  tooltipSuffix: PropTypes.string,
};

export default ProgressBar;
